import React from "react"
import { createBrowserRouter, RouteObject, RouterProvider } from "react-router-dom"
import { FinancesPage } from "src/pages/finances"
import NotFoundPage from "src/pages/notFound"
import { PlanPage } from "src/pages/plans/[planId]"

import HomePage from "./home"
import ErrorPage from "./error"
import PartnersPage from "./partners"
import { PartnerPage } from "./partners/[partnerId]"
import PlansPage from "./plans"
import UsersPage from "./users"
import { UserPage } from "./users/[userId]"
import WorkspacesPage from "./workspaces"
import { WorkspacePage } from "./workspaces/[workspaceId]"
import SettingsPage from "./settings"

const routes: RouteObject[] = [
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/partners",
    children: [
      {
        path: "",
        element: <PartnersPage />,
      },
      {
        path: "new",
        element: <PartnerPage />
      },
      {
        path: ":partnerId",
        element: <PartnerPage editing />
      },
    ]
  },
  {
    path: "/plans",
    children: [
      {
        path: "",
        element: <PlansPage />,
      },
      {
        path: "new",
        element: <PlanPage />
      },
      {
        path: ":planId",
        element: <PlanPage editing />
      },
      {
        path: "duplicate/:planId",
        element: <PlanPage duplicate />
      },
    ]
  },
  {
    path: "/users",
    children: [
      {
        path: "",
        element: <UsersPage />,
      },
      {
        path: "new",
        element: <UserPage />,
      },
      {
        path: ":userId",
        element: <UserPage editing />,
      },
    ]
  },
  {
    path: "/workspaces",
    children: [
      {
        path: "",
        element: <WorkspacesPage />,
      },
      {
        path: "new",
        element: <WorkspacePage />,
      },
      {
        path: ":workspaceId",
        element: <WorkspacePage editing />,
      },
    ]
  },
  {
    path: "/finances",
    element: <FinancesPage />,
  },
  {
    path: "/settings",
    element: <SettingsPage />,
  },
  {
    path: "*",
    element: <NotFoundPage />
  },
]

const router = createBrowserRouter(routes.map(route => ({
  ...route,
  errorElement: <ErrorPage />
})))

const Routes: React.FC = () => <RouterProvider router={router} />

export default Routes