import { useHookstate } from '@hookstate/core'
import { Alert, Col, Form, Input, Radio, Row, Space } from 'antd'
import { i18n_v2 } from 'src/lib/i18n'

import { useControllers } from './controllers'
import { i18nLocal } from './i18n'
import { LocalState } from './state'
import { PropsType } from './types'

export const BasicTab = (props: PropsType) => {
  const state = useHookstate(LocalState)
  const controllers = useControllers()

  return (
    <Form layout={"vertical"}>
      {
        !props.editing
          ? <Row gutter={20}>
            <Col flex={"1"}>
              <Form.Item>
                <Alert
                  message={
                    <Row gutter={20} justify={`space-between`} align={`middle`}>
                      <Col>
                        <Space direction={`vertical`}>
                          {i18nLocal.basicInfoTab(`header.createWorkspaceTip.message`)}
                          {i18nLocal.basicInfoTab(`header.createWorkspaceTip.description`)}
                        </Space>
                      </Col>
                      <Col>
                        <Radio.Group
                          value={state.partner.createWorkspaceToo.get()}
                          onChange={({ target: { value } }) => state.partner.createWorkspaceToo.set(value)}
                          buttonStyle={"solid"}
                        >
                          <Radio.Button value={true}>
                            {i18n_v2.Commons(`yes`)}
                          </Radio.Button>
                          <Radio.Button value={false}>
                            {i18n_v2.Commons(`no`)}
                          </Radio.Button>
                        </Radio.Group>
                      </Col>
                    </Row>
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          : undefined
      }
      <Row gutter={20}>
        <Col flex={"1"}>
          <Form.Item
            label={i18nLocal.basicInfoTab(`form.name.label`)}
            required
            help={state.errors.nested(`displayName`).get()}
            validateStatus={state.errors.nested(`displayName`).get() ? `error` : ``}
          >
            <Input
              inputMode={"text"}
              placeholder={i18n_v2.Commons(`required`)}
              onChange={({ target: { value } }) => state.partner.displayName.set(value)}
              value={state.partner.displayName.get()}
            />
          </Form.Item>
        </Col>
        <Col flex={"1"}>
          <Form.Item
            label={"E-mail"}
            required
            help={state.errors.nested(`email`).get()}
            validateStatus={state.errors.nested(`email`).get() ? `error` : ``}
          >
            <Input
              inputMode={"email"}
              placeholder={i18n_v2.Commons(`required`)}
              onChange={({ target: { value } }) => state.partner.email.set(value)}
              value={state.partner.email.get()}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col flex={"1"}>
          <Form.Item
            label={i18nLocal.basicInfoTab(`form.legalName.label`)}
            required
            help={state.errors.nested(`legalName`).get()}
            validateStatus={state.errors.nested(`legalName`).get() ? `error` : ``}
          >
            <Input
              inputMode={"text"}
              placeholder={i18n_v2.Commons(`required`)}
              onChange={({ target: { value } }) => state.partner.legalName.set(value)}
              value={state.partner.legalName.get()}
            />
          </Form.Item>
        </Col>
        <Col flex={"1"}>
          <Form.Item
            label={i18nLocal.basicInfoTab(`form.federalDocument.label`)}
            required
            help={state.errors.nested(`federalDocument`).get()}
            validateStatus={state.errors.nested(`federalDocument`).get() ? `error` : ``}
          >
            <Input
              inputMode={"numeric"}
              placeholder={i18n_v2.Commons(`required`)}
              onChange={({ target: { value } }) => state.partner.federalDocument.set(value)}
              maxLength={18}
              value={controllers.getFormattedFederalDocument()}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}