import { useHookstate } from '@hookstate/core'
import { Button, Col, Divider, Row, Tabs } from 'antd'
import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from 'src/components/layout'

import { AddressTab } from './address.tab'
import { BasicTab } from './basic.tab'
import { useControllers } from './controllers'
import { i18nLocal } from './i18nLocal'
import styles from './index.module.scss'
import { CurrentPlanTab } from './planAndSubscription.tab'
import { LocalState } from './state'
import { PropsType } from './types'
import { UsersTab } from './users.tab'

export const WorkspacePage: React.FC<PropsType> = props => {

  const state = useHookstate(LocalState)
  const controllers = useControllers(props)

  const pageTitle = (
    props.editing
      ? i18nLocal.index(`page.title.updating`)
      : i18nLocal.index(`page.title.creating`)
  )

  return (
    <>
      <Helmet>
        <title>{`${pageTitle} - Roberty Automation`}</title>
      </Helmet>
      <Layout currentMenu="workspaces">
        <div className={styles.plan}>
          <Row gutter={20}>
            <Col flex={1}>
              <h1 className={styles.title}>
                {pageTitle}
              </h1>
            </Col>
            <Col >
              <Button
                type={"primary"}
                disabled={!controllers.index.canSave()}
                onClick={controllers.index.handleSave()}
              >
                {
                  props.editing
                    ? i18nLocal.index(`headers.saveButton.update.label`)
                    : i18nLocal.index(`headers.saveButton.new.label`)
                }
              </Button>
            </Col>
          </Row>
          <Divider />
          <Tabs
            defaultActiveKey={`basic`}
            items={[
              {
                key: "basic",
                label: i18nLocal.index(`tabs.basicInfo.label`),
                children: <BasicTab {...props} />,
              },
              {
                key: "address",
                label: i18nLocal.index(`tabs.address.label`),
                children: <AddressTab />
              },
              {
                key: "users",
                label: i18nLocal.index(`tabs.users.label`, controllers.index.getUsersCount()),
                children: <UsersTab {...props} />
              },
              (
                props.editing
                  ? {
                    key: "planAndSubscriptions",
                    label: i18nLocal.index(`tabs.plan.label`),
                    children: <CurrentPlanTab />
                  }
                  : undefined
              )
            ]}
          />
        </div>
      </Layout>
    </>
  )
}