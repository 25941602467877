import { i18n_v2 } from 'src/lib/i18n'

export const i18nLocal = i18n_v2.Collection({
	"partners.label": {
		"en-US": `Partners`,
		"pt-BR": `Parceiros`
	},
	"plans.label": {
		"en-US": `Plans`,
		"pt-BR": `Planos`
	},
	"users.label": {
		"en-US": `Users`,
		"pt-BR": `Usuários`
	},
	"invoicing.label": {
		"en-US": `Invoicing`,
		"pt-BR": `Faturamento`
	},
	"settings.label": {
		"en-US": `Settings`,
		"pt-BR": `Configurações`
	},
})