import { none, useHookstate } from '@hookstate/core'
import { Enums } from '@roberty/models'
import { Button, Empty, Select, Space, Tooltip } from 'antd'
import { i18n_v2 } from 'src/lib/i18n'

import { i18nLocal } from './i18nLocal'
import { LocalState } from './state'

const WorkspaceRoles: {
  label: string,
  value: Enums.CustomerUserAuthorizationRoles
}[] = [
    {
      label: i18n_v2.Single({
        "en-US": `Administrator`,
        "pt-BR": `Administrador`
      })(),
      value: Enums.CustomerUserAuthorizationRoles["ADMINISTRATOR"]
    },
    {
      label: i18n_v2.Single({
        "en-US": `Developer`,
        "pt-BR": `Desenvolvedor`
      })(),
      value: Enums.CustomerUserAuthorizationRoles["DEVELOPER"]
    },
    {
      label: i18n_v2.Single({
        "en-US": `Default`,
        "pt-BR": `Padrão`
      })(),
      value: Enums.CustomerUserAuthorizationRoles["DEFAULT"]
    },
  ]

export const WorkspacesTab = () => {
  const state = useHookstate(LocalState)

  return (
    <>
      <Button
        type={"primary"}
        block
        onClick={() => state.user.workspaces.set(a => {
          a.unshift({ workspace: undefined, role: undefined })
          return a
        })}
      >
        {i18nLocal.workspacesTab(`addNew.button.label`)}
      </Button>
      <br />
      <br />
      {
        state.user.workspaces.map(({ workspace, role }, index) => (
          <>
            <Space.Compact
              key={workspace.get() ? workspace.get().toString() : ""}
              style={{
                display: "flex",
                flexDirection: "row"
              }}
            >
              <Select
                notFoundContent={<Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={
                    state.screenFields.workspaces.keyword.get().trim().length > 0
                      ? (
                        state.screenFields.workspaces.loading.get()
                          ? i18nLocal.workspacesTab(`form.workspace.loading`)
                          : i18nLocal.workspacesTab(`form.workspace.noData`)
                      )
                      : i18nLocal.workspacesTab(`form.workspace.tip`)
                  }
                />}
                showSearch
                loading={state.screenFields.workspaces.loading.get()}
                value={workspace.get()}
                onSelect={workspace.set}
                filterOption={false}
                style={{ flex: 2, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                onSearch={value => state.screenFields.workspaces.keyword.set(value)}
                options={state.screenFields.workspaces.found.get().map(w => ({ value: w._id, label: w.displayName }))}
                placeholder={"Workspace"}
              />
              <Select
                options={WorkspaceRoles}
                showSearch
                value={role.get()}
                onChange={role.set}
                placeholder={i18nLocal.workspacesTab(`form.role.placeholder`)}
                style={{ flex: 1 }}
              />
              <Tooltip
                title={i18nLocal.workspacesTab(`form.removeButton.tip`)}
              >
                <Button
                  danger
                  onClick={() => state.user.workspaces.merge({ [index]: none })}
                >
                  x
                </Button>
              </Tooltip>
            </Space.Compact>
            <br />
          </>
        ))
      }
    </>
  )
}