import LocaleService from 'src/services/locale'

type Langs =
  | `pt-BR`
  | `en-US`

type SingleType = { [key in Langs]: string | ((...args: any[]) => string) }

type CollectionType<T extends string> = { [key in T]: SingleType }

const GetCurrentLanguage = () => LocaleService.get() as Langs

const Single = (single: SingleType) => (...args: any) => typeof single[GetCurrentLanguage()] === `string` ? single[GetCurrentLanguage()] : (single[GetCurrentLanguage()] as Function)(...args)

const Collection = <T extends string>(collection: CollectionType<T>) => (item: T, ...args: any) => typeof collection[item][GetCurrentLanguage()] === `string` ? collection[item][GetCurrentLanguage()] : (collection[item][GetCurrentLanguage()] as Function)(...args)

const Commons = Collection({
  [`yes`]: {
    "en-US": `Yes`,
    "pt-BR": `Sim`
  },
  [`no`]: {
    "en-US": `No`,
    "pt-BR": `Não`
  },
  [`left`]: {
    "en-US": `Left`,
    "pt-BR": `Esquerdo`
  },
  [`right`]: {
    "en-US": `Right`,
    "pt-BR": `Direito`
  },
  [`middle`]: {
    "en-US": `Middle`,
    "pt-BR": `Meio`
  },
  [`position`]: {
    "en-US": `Position`,
    "pt-BR": `Posição`
  },
  [`text`]: {
    "en-US": `Text`,
    "pt-BR": `Texto`
  },
  [`required`]: {
    "en-US": `Required`,
    "pt-BR": `Obrigatório`
  },
  [`perPage`]: {
    "en-US": `per page`,
    "pt-BR": `por página`
  },
  [`dateFormat`]: {
    "en-US": `YYYY[-]MM[-]DD`,
    "pt-BR": `DD[/]MM[/]YYYY`
  },
  [`active`]: {
    "en-US": `Active`,
    "pt-BR": `Ativo`
  },
  [`inactive`]: {
    "en-US": `Inactive`,
    "pt-BR": `Inativo`
  },
  [`tryAgain`]: {
    "en-US": `Try again!`,
    "pt-BR": `Tente novamente!`
  },
  [`cancel`]: {
    "en-US": `Cancel`,
    "pt-BR": `Cancelar`
  },
})

export type i18n_v2Types = {
  Single: SingleType,
  Collection: CollectionType<string>,
  Langs: Langs,
}

export const i18n_v2 = {
  GetCurrentLanguage,
  Single,
  Collection,
  Commons,
}