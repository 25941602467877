import I18nMessages from "./messages"
import enUS from "./languages/enUS"
import ptBR from "./languages/ptBR"
import LocaleService from "../services/locale"

function getMessages(): I18nMessages {
  switch (LocaleService.get()) {
  case "pt-BR":
    return ptBR
  case "en-US":
  default:
    return enUS
  }
}

const i18n = getMessages()
export default i18n