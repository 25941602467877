import { message, Modal, notification } from 'antd'
import { i18n_v2 } from 'src/lib/i18n'
import RestClient from 'src/services/rest'

import { i18nLocal } from './i18nLocal'

type RecordType = Awaited<ReturnType<typeof RestClient.Users.get>>["list"][0]
type Props = {
  setTableForceReload: (payload: number) => void
}

export const useControllers = (props: Props) => {
  const handleRemoveUser = (user: RecordType, confirmed?: boolean) => async () => {
    if (!confirmed) {
      Modal.confirm({
        content: i18nLocal.controllers(`removeModal.question`, user.name || user.email),
        cancelText: i18n_v2.Commons(`no`),
        cancelButtonProps: { type: "dashed" },
        okText: i18n_v2.Commons(`yes`),
        onOk: handleRemoveUser(user, true)
      })
      return
    }
    const destroy = message.loading({
      content: i18nLocal.controllers(`removeModal.removing`),
      duration: 0
    })
    try {
      await RestClient.Users["-user-"].delete({
        userId: user._id.toString()
      })
      notification.success({ message: i18nLocal.controllers(`removeModal.success`) })
      props.setTableForceReload(Date.now())
    } catch (e) {
      console.error(e)
      notification.error({
        message: i18nLocal.controllers(`removeModal.error`),
        description: i18n_v2.Commons(`tryAgain`)
      })
    } finally {
      destroy()
    }
  }

  return {
    handleRemoveUser,
  }
}