import { useHookstate } from "@hookstate/core"
import { notification } from "antd"
import { useEffect } from "react"
import { useDebounce } from "use-debounce"
import { z } from "zod"

import { LocalState } from "./state"
import { SelectUsersProps, UserResponse } from "./types"

export const useControllers = (props: SelectUsersProps) => {
  const state = useHookstate(LocalState)
  const [debouncedKeyword] = useDebounce(state.keyword.get(), 200)

  const getFakeUser = (): UserResponse => {
    if (!z.string().email().safeParse(debouncedKeyword).success) return undefined

    return {
      _id: Date.now().toString(),
      email: debouncedKeyword,
      name: debouncedKeyword
    }
  }

  const getUserToSuggest = () => {
    let list = state.users.get({ noproxy: true }) as UserResponse[] || []

    const fakeUser = getFakeUser()
    if (fakeUser && list.findIndex(({ email }) => email === debouncedKeyword) === -1) list.unshift(fakeUser)

    list = list.filter(({ email }) => !props.emailsToHide.includes(email))

    return list
  }

  const handleFetchUsers = async () => {
    try {
      state.loading.set(true)
      const users = await props.fetchUsers(debouncedKeyword)
      state.users.set(users)
    } catch (e) {
      console.error(e)
      notification.error({
        message: "Error when loading users...",
        description: "Try again!"
      })
    } finally {
      state.loading.set(false)
    }
  }

  useEffect(() => { handleFetchUsers() }, [debouncedKeyword])

  return {
    getUserToSuggest,
  }
}