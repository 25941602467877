import { hookstate } from '@hookstate/core'
import { StateHelpers } from 'src/lib/stateHelpers'

import { StateType } from './types'

export const LocalState = hookstate<StateType>({
  errors: {},
  screenFields: {
    loadingWorkspaces: false,
    workspacesFound: [],
    workspaceSearchKeyword: "",
  },
  plan: {
    name: "",
    listed: true,
    order: undefined,
    internalTags: [],
    planPeriodicity: undefined,
    planPeriodicityQuantity: undefined,
    unavailableActions: [],
    customer: undefined,
    consumableFeatures: [
      {
        amount: undefined,
        name: undefined,
        periodicity: undefined
      }
    ],
    currencyBasedPrices: {},
  }
})

const InitialState = {
  [`plan.name`]: ``,
  [`plan.listed`]: true,
  [`plan.order`]: undefined,
  [`plan.internalTags`]: [],
  [`plan.planPeriodicity`]: undefined,
  [`plan.planPeriodicityQuantity`]: undefined,
  [`plan.unavailableActions`]: [],
  [`plan.customer`]: ``,
  [`plan.consumableFeatures`]: [
    {
      amount: undefined,
      name: undefined,
      periodicity: undefined
    }
  ],
  [`plan.currencyBasedPrices`]: {},
}

export const ClearState = () => StateHelpers.reset(InitialState, LocalState)