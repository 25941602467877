import * as cnpj from '@fnando/cnpj'
import * as cpf from '@fnando/cpf'
import { none, useHookstate } from '@hookstate/core'
import { Enums, Schemas } from '@roberty/models'
import { message, Modal, notification } from 'antd'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { i18n_v2 } from 'src/lib/i18n'
import { fetchZipCode } from 'src/lib/viaCep'
import RestClient from 'src/services/rest'
import { useDebounce } from 'use-debounce'
import { z } from 'zod'
import { generateErrorMessage } from 'zod-error'

import { FeatureOptions } from '../../plans/[planId]/utils'
import { i18nLocal } from './i18nLocal'
import { ClearState, LocalState } from './state'
import { ParamsType, PropsType } from './types'

const PlanSaveValidation = Schemas.SubscriptionPlanSchema
  .pick({
    name: true,
    planPeriodicity: true,
    planPeriodicityQuantity: true,
    internalTags: true,
    unavailableActions: true,
    consumableFeatures: true,
    currencyBasedPrices: true
  })
  .extend({
    currencyBasedPrices: z.record(z.union([z.number(), z.string().transform(v => parseFloat(v))])).optional()
  })

const WorkspaceSaveValidation = Schemas.CustomerSchema._def.schema
  .pick({
    active: true,
    type: true,
    displayName: true,
    email: true,
    legalName: true,
    federalDocument: true,
    partner_: true,
    address: true,
  })
  .extend({
    admins: Schemas.UserSchema.shape.email.array(),
    developers: Schemas.UserSchema.shape.email.array(),
    otherUsers: Schemas.UserSchema.shape.email.array(),
    address: Schemas.CustomerSchema._def.schema.shape.address.unwrap()
      .omit({ country: true })
      .optional(),
  })
  .refine(({ type, federalDocument }) => {
    switch (type) {
      case Enums.CustomerType["BUSINESS"]: {
        return cnpj.isValid(federalDocument)
      }
      case Enums.CustomerType["PERSON"]: {
        return cpf.isValid(federalDocument)
      }
    }
  }, "invalid \"federalDocument\" due the \"type\"")

export const useControllers = (props?: PropsType) => {
  const state = useHookstate(LocalState)
  const params = useParams<ParamsType>()
  const navigate = useNavigate()
  const [debouncedPartnerSearchKeyword] = useDebounce(state.basicTab.partners.keyword.get(), 100)

  const planSettings = {
    planFinderModal: {
      handleCancel: () => {
        state.planAndSubscriptionTab.planFinderModal.plansFound.set([])
        state.planAndSubscriptionTab.planFinderModal.selectedPlan.set(undefined)
        state.planAndSubscriptionTab.planFinderModal.open.set(false)
      },
      debouncedKeyword: useDebounce(state.planAndSubscriptionTab.planFinderModal.keyword.get(), 100)[0],
      handleLoadPlanToCopy: async () => {
        const destroy = message.loading({
          content: i18nLocal.controllers(`copyPlan.loading`),
          duration: 0
        })
        try {
          state.planAndSubscriptionTab.planFinderModal.loadingSourcePlan.set(true)
          const { plan } = await RestClient.Plans['-plan-'].get({
            planId: state.planAndSubscriptionTab.planFinderModal.selectedPlan.get() as any
          })
          state.planAndSubscriptionTab.plan.set(plan as any)
          planSettings.planFinderModal.handleCancel()
        } catch (e) {
          notification.error({
            message: i18nLocal.controllers(`copyPlan.loading.error`),
            description: i18n_v2.Commons(`tryAgain`)
          })
        } finally {
          state.planAndSubscriptionTab.planFinderModal.loadingSourcePlan.set(false)
          destroy()
        }
      },
      loadPlansByKeyword: async () => {
        try {
          state.planAndSubscriptionTab.planFinderModal.loadingPlanSuggestions.set(true)
          const { plans } = await RestClient.Plans.byKeyword.post({
            keyword: planSettings.planFinderModal.debouncedKeyword
          })
          state.planAndSubscriptionTab.planFinderModal.plansFound.set(plans as any)
        } catch (e) {
          console.log(e)
          notification.error({
            message: i18nLocal.controllers(`plans.loading.error`),
            description: i18n_v2.Commons(`tryAgain`)
          })
        } finally {
          state.planAndSubscriptionTab.planFinderModal.loadingPlanSuggestions.set(false)
        }
      },
      getPlansFoundList: () => state.planAndSubscriptionTab.planFinderModal.plansFound.get().map(p => ({
        label: p.name,
        value: p._id
      }))
    },
    handleAddNewFeature: () => state.planAndSubscriptionTab.plan.consumableFeatures.merge([{
      name: undefined,
      amount: undefined,
      periodicity: undefined
    }]),
    getMissingConsumableFeatures: () => {
      const addedFeatures = state.planAndSubscriptionTab.plan.consumableFeatures.map(f => f.name.get())
      const unavailableFeatures = state.planAndSubscriptionTab.plan.unavailableActions.get()
      return FeatureOptions.map(f => ({
        ...f,
        disabled: (
          addedFeatures.includes(f.value as any)
          || unavailableFeatures.includes(f.value as any)
        )
      }))
    },
    handleRemoveFeatureByIndex: (index: number, confirmed?: boolean) => () => {
      if (!confirmed) {
        Modal.confirm({
          content: i18nLocal.controllers(`consumableFeature.remove.prompt`),
          cancelText: i18n_v2.Commons(`no`),
          okText: i18n_v2.Commons(`yes`),
          cancelButtonProps: { type: "dashed" },
          onOk: planSettings.handleRemoveFeatureByIndex(index, true)
        })
        return
      }
      state.planAndSubscriptionTab.plan.consumableFeatures.merge({ [index]: none })
    },
    canAddMoreConsumableFeatures: () => {
      const addedFeatures = state.planAndSubscriptionTab.plan.consumableFeatures.map(f => f.name.get())
      if (addedFeatures.some(i => i === undefined)) return false
      const unavailableFeatures = state.planAndSubscriptionTab.plan.unavailableActions.get()
      return FeatureOptions.filter(f => (
        !addedFeatures.includes(f.value as any)
        && !unavailableFeatures.includes(f.value as any)
      )).length > 0
    }
  }
  useEffect(() => { planSettings.planFinderModal.loadPlansByKeyword() }, [planSettings.planFinderModal.debouncedKeyword])

  const basicInfo = {
    getFormattedFederalDocument: () => {
      switch (state.workspace.type.get()) {
        case Enums.CustomerType["BUSINESS"]: {
          const { groups } = state.workspace.federalDocument.get()
            .replace(/[^0-9]/gi, "")
            .match(/(?<p1>[0-9]{0,2})(?<p2>[0-9]{0,3})(?<p3>[0-9]{0,3})(?<p4>[0-9]{0,4})(?<p5>[0-9]{0,2}).*/i)

          let output = `${groups["p1"]}`

          if (groups["p2"].length > 0) output += `.${groups["p2"]}`
          if (groups["p3"].length > 0) output += `.${groups["p3"]}`
          if (groups["p4"].length > 0) output += `/${groups["p4"]}`
          if (groups["p5"].length > 0) output += `-${groups["p5"]}`

          return output
        }
        case Enums.CustomerType["PERSON"]: {
          const { groups } = state.workspace.federalDocument.get()
            .replace(/[^0-9]/gi, "")
            .match(/(?<p1>[0-9]{0,3})(?<p2>[0-9]{0,3})(?<p3>[0-9]{0,3})(?<p4>[0-9]{0,2}).*/i)

          let output = `${groups["p1"]}`

          if (groups["p2"].length > 0) output += `.${groups["p2"]}`
          if (groups["p3"].length > 0) output += `.${groups["p3"]}`
          if (groups["p4"].length > 0) output += `-${groups["p4"]}`

          return output
        }
      }
    },
    handleSearchPartners: async () => {
      if (debouncedPartnerSearchKeyword.trim().length === 0) return
      try {
        state.basicTab.partners.loading.set(true)
        const { partners } = await RestClient.Partners.byKeyword.post({
          keyword: debouncedPartnerSearchKeyword
        })
        state.basicTab.partners.found.set(partners as any)
      } catch (e) {
        console.error(e)
        notification.error({
          message: i18nLocal.controllers(`partners.loading.error`),
          description: i18n_v2.Commons(`tryAgain`)
        })
      } finally {
        state.basicTab.partners.loading.set(false)
      }
    }
  }
  useEffect(() => { basicInfo.handleSearchPartners() }, [debouncedPartnerSearchKeyword])

  const address = {
    getFormattedZipCode: () => {
      if (!state.workspace.address.zipCode.get()) return ""

      const { groups } = state.workspace.address.zipCode.get()
        .replace(/[^0-9]/gi, "")
        .match(/(?<p1>[0-9]{0,5})(?<p2>[0-9]{0,3}).*/i)

      let output = `${groups["p1"]}`

      if (groups["p2"].length > 0) output += `-${groups["p2"]}`

      return output
    },
    handleZipCodeLookup: async () => {
      if (state.workspace.address?.zipCode?.get().length !== 9) return

      const destroy = message.loading({
        content: i18nLocal.controllers(`zipCode.fetching`),
        duration: 0
      })
      try {
        state.addressTab.zipCode.loading.set(true)
        const zipCodeFetched = await fetchZipCode(state.workspace.address.zipCode.get())

        if (zipCodeFetched.state) state.workspace.address.state.set(zipCodeFetched.state)
        if (zipCodeFetched.city) state.workspace.address.city.set(zipCodeFetched.city)
        if (zipCodeFetched.address) state.workspace.address.address.set(zipCodeFetched.address)
        if (zipCodeFetched.district) state.workspace.address.district.set(zipCodeFetched.district)
      } catch (e) {
        console.error(e)
        notification.error({
          message: i18nLocal.controllers(`zipCode.fetching.error`),
          description: i18n_v2.Commons(`tryAgain`)
        })
      } finally {
        state.addressTab.zipCode.loading.set(false)
        destroy()
      }
    }
  }
  useEffect(() => { address.handleZipCodeLookup() }, [state.workspace.address.zipCode])

  const users = {
    handleSearchUsers: async (keyword: string) => {
      if (keyword.trim().length === 0) return
      const { users } = await RestClient.Users.byKeyword.post({ keyword })
      return users
    }
  }

  const index = {
    getUsersCount: () => (
      state.workspace.admins.length
      + state.workspace.developers.length
      + state.workspace.otherUsers.length
    ),
    canSave: () => {
      state.errors.set({})
      const issues = []

      const workspaceResult = WorkspaceSaveValidation.safeParse(state.workspace.get())
      if ((workspaceResult as any)?.error?.issues) issues.push(...(workspaceResult as any).error.issues)

      const planResult = PlanSaveValidation.safeParse(state.planAndSubscriptionTab?.plan?.get())
      if ((planResult as any)?.error?.issues) issues.push(...(planResult as any).error.issues)

      state.errors.set(Object.fromEntries(generateErrorMessage(
        issues,
        {
          delimiter: { component: ` - ` },
          code: { enabled: false },
          path: { label: ``, enabled: true, type: `objectNotation` },
          message: { label: ``, enabled: true }
        }
      ).split(` | `).map(b => b.split(` - `))))

      return (
        workspaceResult.success
        && (
          !props.editing
          || planResult.success
        )
      )
    },
    handleSave: (confirmed?: boolean) => async () => {
      if (!confirmed) {
        Modal.confirm({
          content: i18nLocal.controllers(`workspace.save.prompt`),
          cancelText: i18n_v2.Commons(`no`),
          cancelButtonProps: { type: "dashed" },
          okText: i18n_v2.Commons(`yes`),
          onOk: index.handleSave(true)
        })
        return
      }
      const destroy = message.loading({
        content: i18nLocal.controllers(`workspace.saving`),
        duration: 0
      })
      try {
        const workspace = WorkspaceSaveValidation.parse(state.workspace.get())
        if (props?.editing) {
          const currentPlan = PlanSaveValidation.parse(state.planAndSubscriptionTab.plan.get())
          await RestClient.Workspaces["-workspace-"].put({
            workspaceId: params.workspaceId,
            workspace: workspace as any,
            currentPlan: currentPlan as any,
            subscription: state.planAndSubscriptionTab.subscription.get()
          })
          notification.success({
            message: i18nLocal.controllers(`workspace.save.success`)
          })
        } else {
          await RestClient.Workspaces.post({ workspace: workspace as any })
          notification.success({
            message: i18nLocal.controllers(`workspace.save.success`)
          })
        }
        navigate("..")
      } catch (e) {
        console.error(e)
        notification.error({
          message: i18nLocal.controllers(`workspace.save.error`),
          description: i18n_v2.Commons(`tryAgain`)
        })
      } finally {
        destroy()
      }
    }
  }

  const loadWorkspaceToEdit = async () => {
    if (!props?.editing) return

    if (!params.workspaceId) {
      navigate("..")
      return
    }

    const destroy = message.loading({
      content: i18nLocal.controllers(`workspace.loading`),
      duration: 0
    })
    try {
      const { workspace, partnerDoc, currentPlan, subscription } = await RestClient.Workspaces["-workspace-"].get({
        workspaceId: params.workspaceId
      })
      if (partnerDoc) state.basicTab.partners.found.set([partnerDoc as any])
      if (currentPlan) state.planAndSubscriptionTab.plan.set(currentPlan as any)
      if (subscription) state.planAndSubscriptionTab.subscription.set(subscription as any)
      state.workspace.set(workspace as any)
    } catch (e) {
      console.error(e)
      notification.error({
        message: i18nLocal.controllers(`workspace.loading.error`),
        description: i18n_v2.Commons(`tryAgain`)
      })
      navigate("..")
    } finally {
      destroy()
    }
  }
  useEffect(() => { loadWorkspaceToEdit() }, [])

  useEffect(() => { return () => { ClearState() } }, [])

  return {
    index,
    basicInfo,
    address,
    users,
    planSettings,
  }
}