import { useHookstate } from '@hookstate/core'
import { Col, Form, Input, Row } from 'antd'
import { i18n_v2 } from 'src/lib/i18n'

import { useControllers } from './controllers'
import { i18nLocal } from './i18nLocal'
import { LocalState } from './state'

export const BasicTab = () => {
  const state = useHookstate(LocalState)

  const controllers = useControllers()

  return (
    <Form layout={"vertical"}>
      <Row gutter={20}>
        <Col flex={"2"}>
          <Form.Item
            label={i18nLocal.basicTab(`form.name.label`)}
            required
            help={state.errors.nested(`name`).get()}
            validateStatus={state.errors.nested(`name`).get() ? `error` : ``}
          >
            <Input
              placeholder={i18n_v2.Commons(`required`)}
              inputMode={"text"}
              value={state.user.name.get()}
              onChange={({ target: { value } }) => state.user.name.set(value)}
            />
          </Form.Item>
        </Col>
        <Col flex={"2"}>
          <Form.Item
            label={"E-mail"}
            required
            help={state.errors.nested(`email`).get()}
            validateStatus={state.errors.nested(`email`).get() ? `error` : ``}
          >
            <Input
              inputMode={"email"}
              placeholder={i18n_v2.Commons(`required`)}
              value={state.user.email.get()}
              onChange={({ target: { value } }) => state.user.email.set(value)}
            />
          </Form.Item>
        </Col>
        <Col flex={"2"}>
          <Form.Item
            label={i18nLocal.basicTab(`form.phone.label`)}
          >
            <Input
              inputMode={"tel"}
              value={controllers.getFormattedPhone()}
              onChange={({ target: { value } }) => state.user.phone.set(value)}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}