import { Button, Col, Row, Tooltip } from 'antd'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import UserAvatar from 'src/components/avatar/user'
import duplicateIcon from 'src/components/icons/duplicate.svg'
import trashIcon from 'src/components/icons/trash.svg'
import Layout from 'src/components/layout'
import PaginatedTable from 'src/components/paginatedTable'
import StatisticsCard from 'src/components/statisticsCard'
import RestClient from 'src/services/rest'

import { SVGIcon } from '../../components/svgIcon'
import { useControllers } from './controllers'
import { i18nLocal } from './i18nLocal'
import styles from './index.module.scss'

const PlansPage: React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [total, setTotal] = useState(0)
  const [tableForceReload, setTableForceReload] = useState(Date.now())

  const controllers = useControllers({ setTableForceReload })

  return (
    <>
      <Helmet>
        <title>{`${i18nLocal.index(`header.title`)} - Roberty Automation`}</title>
      </Helmet>
      <Layout currentMenu="plans">
        <div className={styles.plans}>
          <section>
            <Row justify="space-between">
              <Col>
                <h1 className={styles.title}>{i18nLocal.index(`header.title`)}</h1>
              </Col>
              <Col>
                <Link to={"./new"}>
                  <Button
                    type="primary"
                    size="large"
                  >
                    {i18nLocal.index(`header.newButton.label`)}
                  </Button>
                </Link>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} lg={8}>
                <StatisticsCard
                  title={i18nLocal.index(`header.cards.count.title`)}
                  data={total}
                  loading={loading}
                />
              </Col>
            </Row>
          </section>
          <section>
            <PaginatedTable
              forceReload={tableForceReload}
              rowKey={"_id"}
              locale={{
                noData: i18nLocal.index(`table.noData`),
                searchPlaceholder: i18nLocal.index(`table.search.label`)
              }}
              request={RestClient.Plans.get}
              columns={[
                {
                  title: i18nLocal.index(`table.rows.name.label`),
                  dataIndex: ["name"],
                  render: (name, { _id }) => (
                    <Row
                      gutter={16}
                      align="middle"
                    >
                      <Col>
                        <UserAvatar
                          name={name}
                        />
                      </Col>
                      <Col>
                        <Link to={`./${_id}`}>
                          <b>{name}</b>
                        </Link>
                      </Col>
                    </Row>
                  )
                },
                {
                  title: i18nLocal.index(`table.rows.usage.label`),
                  dataIndex: ["usage"],
                  render: usage => i18nLocal.index(`table.rows.usage.data`, usage)
                },
                {
                  title: "",
                  render: (_, plan) => (
                    <div className={styles.tableActions}>
                      <Tooltip title={i18nLocal.index(`table.rows.actions.duplicateButton.tip`)}>
                        <Link to={`./duplicate/${plan._id}`}>
                          <Button
                            type="link"
                            size="small"
                          >
                            <SVGIcon
                              style={{ fill: "var(--blue)" }}
                              src={duplicateIcon}
                            />
                          </Button>
                        </Link>
                      </Tooltip>
                      <Tooltip title={i18nLocal.index(`table.rows.actions.removeButton.tip`)}>
                        <Button
                          type="link"
                          size="small"
                          onClick={controllers.handleRemovePlan(plan)}
                        >
                          <SVGIcon
                            style={{ fill: "var(--pink)" }}
                            src={trashIcon}
                          />
                        </Button>
                      </Tooltip>
                    </div>
                  )
                },
              ]}
              onLoad={response => {
                setTotal(response.total)
                setLoading(false)
              }}
            />
          </section>
        </div>
      </Layout>
    </>
  )
}

export default PlansPage