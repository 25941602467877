import { State, useHookstate } from '@hookstate/core'
import { Schemas } from '@roberty/models'
import { message, Modal, notification } from 'antd'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { i18n_v2 } from 'src/lib/i18n'
import RestClient from 'src/services/rest'
import { useDebounce } from 'use-debounce'
import { z } from 'zod'
import { generateErrorMessage } from 'zod-error'

import { i18nLocal } from './i18nLocal'
import { ClearState, LocalState } from './state'
import { ParamsType, PropsType } from './types'

const SaveValidation = Schemas.UserSchema
  .pick({
    name: true,
    email: true,
    phone: true,
  })
  .extend({
    workspaces: z.object({
      workspace: z.string().min(1),
      role: Schemas.CustomerUserAuthorizationSchema.shape.role,
    }).array(),
    partners: z.object({
      partner: z.string().min(1),
      role: Schemas.PartnerUserAuthorizationSchema.shape.role,
    }).array(),
  })

export const useControllers = (props?: PropsType) => {
  const state = useHookstate(LocalState)
  const params = useParams<ParamsType>()
  const navigate = useNavigate()
  const [debouncedWorkspaceSearchKeyword] = useDebounce(state.screenFields.workspaces.keyword.get(), 100)
  const [debouncedPartnerSearchKeyword] = useDebounce(state.screenFields.partners.keyword.get(), 100)

  const handleSearchPartners = async () => {
    if (debouncedPartnerSearchKeyword.trim().length === 0) return
    try {
      state.screenFields.partners.loading.set(true)
      const { partners } = await RestClient.Partners.byKeyword.post({
        keyword: debouncedPartnerSearchKeyword
      })
      state.screenFields.partners.found.set(partners as any)
    } catch (e) {
      console.error(e)
      notification.error({
        message: i18nLocal.controllers(`partners.loading.error`),
        description: i18n_v2.Commons(`tryAgain`)
      })
    } finally {
      state.screenFields.partners.loading.set(false)
    }
  }
  useEffect(() => { handleSearchPartners() }, [debouncedPartnerSearchKeyword])

  const handleSearchWorkspaces = async () => {
    if (debouncedWorkspaceSearchKeyword.trim().length === 0) return
    try {
      state.screenFields.workspaces.loading.set(true)
      const { workspaces } = await RestClient.Workspaces.byKeyword.post({
        keyword: debouncedWorkspaceSearchKeyword
      })
      state.screenFields.workspaces.found.set(workspaces as any)
    } catch (e) {
      console.error(e)
      notification.error({
        message: i18nLocal.controllers(`workspaces.loading.error`),
        description: i18n_v2.Commons(`tryAgain`)
      })
    } finally {
      state.screenFields.workspaces.loading.set(false)
    }
  }
  useEffect(() => { handleSearchWorkspaces() }, [debouncedWorkspaceSearchKeyword])

  const getFormattedPhone = () => {
    const { groups } = (state.user.phone.get() || ``)
      .replace(/[^0-9]/gi, "")
      .match(/(?<ddd>[0-9]{0,2})(?<p1>[0-9]{0,5})(?<p2>[0-9]{0,4}).*/i)

    let output = ""

    if (groups["ddd"].length > 0) output += `(${groups["ddd"]}`
    if (groups["p1"].length > 0) output += `) ${groups["p1"]}`
    if (groups["p2"].length > 0) output += `-${groups["p2"]}`

    return output
  }

  const loadUserToEdit = async () => {
    if (!props?.editing) return

    if (!params.userId) {
      navigate("..")
      return
    }

    const destroy = message.loading({
      content: i18nLocal.controllers(`users.loading`),
      duration: 0
    })
    try {
      const { user, partnersDoc, workspacesDoc } = await RestClient.Users["-user-"].get({
        userId: params.userId
      })
      state.user.set(user as any)
      if (workspacesDoc) state.screenFields.workspaces.found.set(workspacesDoc as any)
      if (partnersDoc) state.screenFields.partners.found.set(partnersDoc as any)
    } catch (e) {
      console.error(e)
      notification.error({
        message: i18nLocal.controllers(`users.loading.error`),
        description: i18n_v2.Commons(`tryAgain`)
      })
      navigate("..")
    } finally {
      destroy()
    }
  }
  useEffect(() => { loadUserToEdit() }, [])

  const handleSave = (confirmed?: boolean) => async () => {
    if (!confirmed) {
      Modal.confirm({
        content: i18nLocal.controllers(`saveModal.question`),
        cancelText: i18n_v2.Commons(`no`),
        cancelButtonProps: { type: "dashed" },
        okText: i18n_v2.Commons(`yes`),
        onOk: handleSave(true)
      })
      return
    }
    const destroy = message.loading({
      content: i18nLocal.controllers(`saveModal.saving`),
      duration: 0
    })
    try {
      const user = SaveValidation.parse(state.user.get())
      if (props?.editing) {
        await RestClient.Users["-user-"].put({ userId: params.userId, user: user as any })
      } else {
        await RestClient.Users.post({ user: user as any })
      }
      notification.success({
        message: i18nLocal.controllers(`saveModal.success`)
      })
      navigate("..")
    } catch (e) {
      console.error(e)
      notification.error({
        message: i18nLocal.controllers(`saveModal.error`),
        description: i18n_v2.Commons(`tryAgain`)
      })
    } finally {
      destroy()
    }
  }

  const canSave = () => {
    const result = SaveValidation.safeParse(state.user.get())
    state.errors.set({})
    if ((result as any)?.error?.issues) state.errors.set(Object.fromEntries(generateErrorMessage(
      (result as any).error.issues,
      {
        delimiter: { component: ` - ` },
        code: { enabled: false },
        path: { label: ``, enabled: true, type: `objectNotation` },
        message: { label: ``, enabled: true }
      }
    ).split(` | `).map(b => b.split(` - `))))
    return result.success
  }

  useEffect(() => { return () => { ClearState() } }, [])

  return {
    canSave,
    handleSave,
    getFormattedPhone,
  }
}