import { i18n_v2 } from 'src/lib/i18n'

export const i18nLocal = {
	controllers: i18n_v2.Collection({
		"consumableFeature.remove": {
			"en-US": `Remove consumable feature?`,
			"pt-BR": `Remover recurso consumível?`
		},
		"plans.loading.error": {
			"en-US": `Error when loading plans...`,
			"pt-BR": `Erro ao carregar planos...`
		},
		"partner.save.prompt": {
			"en-US": `Save changes?`,
			"pt-BR": `Salvar alterações?`
		},
		"partner.saving": {
			"en-US": `Saving partner...`,
			"pt-BR": `Salvando parceiro...`
		},
		"partner.loading": {
			"en-US": `Loading partner...`,
			"pt-BR": `Carregando parceiro...`
		},
		"zipCode.fetching.loading": {
			"en-US": `Fetching zip code...`,
			"pt-BR": `Buscando CEP...`
		},
		"zipCode.fetching.error": {
			"en-US": `Error when fetching zip code...`,
			"pt-BR": `Erro ao buscar CEP...`
		},
		"partner.loading.error": {
			"en-US": `Error when loading partner...`,
			"pt-BR": `Erro ao carregar parceiro...`
		},
		"partner.save.error": {
			"en-US": `Error during partner saving...`,
			"pt-BR": `Erro ao salvar parceiro...`
		},
		"partner.save.success": {
			"en-US": `Partner saved with success!`,
			"pt-BR": `Parceiro salvo com sucesso!`
		},
	}),
	usersTab: i18n_v2.Collection({
		"header.autoAdmin.tip": {
			"en-US": `You will be automatically added as a admin user.`,
			"pt-BR": `Você será automaticamente adicionado como um administrador.`
		},
		"form.adminUsers.label": {
			"en-US": v => `Admin users (${v})`,
			"pt-BR": v => `Administradores (${v})`
		},
		"form.developerUsers.label": {
			"en-US": v => `Developer users (${v})`,
			"pt-BR": v => `Desenvolvedores (${v})`
		},
		"form.billingUsers.normal.label": {
			"en-US": v => `Billing users (${v})`,
			"pt-BR": v => `Financeiro (${v})`
		},
		"form.billingUsers.withWorkspace.label": {
			"en-US": v => `Partner billing users (${v})`,
			"pt-BR": v => `Financeiro (para o parceiro) (${v})`
		},
		"form.workspaceOtherUsers.label": {
			"en-US": v => `Workspace other users (${v})`,
			"pt-BR": v => `Outros (para o workspace) (${v})`
		},
	}),
	billingOptionsTab: i18n_v2.Collection({
		"form.billingType.label": {
			"en-US": `Billing type`,
			"pt-BR": `Tipo de cobrança`
		},
		"form.minimalCost.label": {
			"en-US": `Minimal cost`,
			"pt-BR": `Cobrança mínima`
		},
		"form.fixedAmount.label": {
			"en-US": `Fixed amount`,
			"pt-BR": `Valor fixo`
		},
		"form.workspacesPlan.label": {
			"en-US": `Workspaces plan`,
			"pt-BR": `Plano dos workspaces`
		},
		"form.pickPlan.label": {
			"en-US": `Select an existing plan`,
			"pt-BR": `Selecione um plano existente`
		},
		"form.pickPlan.placeholder": {
			"en-US": `Type here to search`,
			"pt-BR": `Digite aqui para pesquisar`
		},
		"form.pickPlan.startTyping": {
			"en-US": `Starts typing to search...`,
			"pt-BR": `Comece a digita para pesquisar...`
		},
		"form.pickPlan.loading": {
			"en-US": `Loading plans...`,
			"pt-BR": `Carregando planos...`
		},
		"form.pickPlan.notFound": {
			"en-US": `No plans found`,
			"pt-BR": `Nenhum plano encontrado`
		},
		"form.workspacesPlan.options.select.label": {
			"en-US": `Select an existing`,
			"pt-BR": `Selecionar um existente`
		},
		"form.workspacesPlan.options.new.label": {
			"en-US": `Create a new one`,
			"pt-BR": `Cria um novo`
		},
		"newPlan.header.title": {
			"en-US": `New plan`,
			"pt-BR": `Novo plano`
		},
		"newPlan.form.periodicity.label": {
			"en-US": `Renew period`,
			"pt-BR": `Período de renovação`
		},
		"newPlan.form.periodicity.renewEvery.label": {
			"en-US": `Renew every`,
			"pt-BR": `Renovar a cada`
		},
		"newPlan.form.periodicity.period.placeholder": {
			"en-US": `period`,
			"pt-BR": `período`
		},
		"newPlan.form.internalTags.label": {
			"en-US": `Internal tags`,
			"pt-BR": `Tags internas`
		},
		"newPlan.form.internalTags.placeholder": {
			"en-US": `Type and hit "enter" to add`,
			"pt-BR": `Digite e pressione "enter"`
		},
		"newPlan.form.unavailableActions.label": {
			"en-US": `Unavailable actions`,
			"pt-BR": `Ações indisponíveis`
		},
		"newPlan.form.unavailableActions.placeholder": {
			"en-US": `Click to select`,
			"pt-BR": `Clique para selecionar`
		},
		"newPlan.form.consumableFeatures.label": {
			"en-US": `Consumable features`,
			"pt-BR": `Recursos consumíveis`
		},
		"newPlan.form.consumableFeatures.feature": {
			"en-US": `Feature`,
			"pt-BR": `Recurso`
		},
		"newPlan.form.consumableFeatures.infinityTip": {
			"en-US": `0 = infinity`,
			"pt-BR": `0 = infinito`
		},
		"newPlan.form.consumableFeatures.periodicity": {
			"en-US": `periodicity`,
			"pt-BR": `periodicidade`
		},
		"newPlan.form.consumableFeatures.addButton.label": {
			"en-US": `Add more feature`,
			"pt-BR": `Adicionar mais recurso`
		},
		"newPlan.form.consumableFeatures.removeButton.label": {
			"en-US": `Remove this feature`,
			"pt-BR": `Remover este recurso`
		},
	}),
	addressTab: i18n_v2.Collection({
		"form.zipCode.label": {
			"en-US": `Zip code`,
			"pt-BR": `CEP`
		},
		"form.address.label": {
			"en-US": `Address`,
			"pt-BR": `Endereço`
		},
		"form.number.label": {
			"en-US": `Number`,
			"pt-BR": `Número`
		},
		"form.complement.label": {
			"en-US": `Complement`,
			"pt-BR": `Complemento`
		},
		"form.district.label": {
			"en-US": `District`,
			"pt-BR": `Bairro`
		},
		"form.city.label": {
			"en-US": `City`,
			"pt-BR": `Cidade`
		},
		"form.state.label": {
			"en-US": `State`,
			"pt-BR": `Estado`
		},
	}),
	basicInfoTab: i18n_v2.Collection({
		"header.createWorkspaceTip.message": {
			"en-US": `If you want to create a workspace to this partner, click on "Yes".`,
			"pt-BR": `Se você deseja criar um novo workspace para este parceiro, clique em "Sim".`
		},
		"header.createWorkspaceTip.description": {
			"en-US": `The "Basic", "Address" and "Users" tabs data will be copied.`,
			"pt-BR": `As informações das abas "Inf. básicas", "Endereço" e "Usuários" serão copiadas.`
		},
		"form.name.label": {
			"en-US": `Name`,
			"pt-BR": `Nome`
		},
		"form.legalName.label": {
			"en-US": `Legal name`,
			"pt-BR": `Razão social`
		},
		"form.federalDocument.label": {
			"en-US": `Federal document`,
			"pt-BR": `CNPJ`
		},
	}),
	indexPage: i18n_v2.Collection({
		"page.title.editing": {
			"en-US": `Editing a partner`,
			"pt-BR": `Editando um parceiro`
		},
		"page.title.creating": {
			"en-US": `Creating a partner`,
			"pt-BR": `Criando um parceiro`
		},
		"page.title.creatingWithWorkspace": {
			"en-US": `Creating a partner and workspace`,
			"pt-BR": `Criando um parceiro e um workspace`
		},
		"header.saveButton.label.editing": {
			"en-US": `Update partner`,
			"pt-BR": `Atualizar parceiro`
		},
		"header.saveButton.label.creating": {
			"en-US": `Save new partner`,
			"pt-BR": `Salvar novo parceiro`
		},
		"header.saveButton.label.creatingWithWorkspace": {
			"en-US": `Save new partner and workspace`,
			"pt-BR": `Salvar novos parceiro e workspace`
		},
		"tabs.basicInfo.title": {
			"en-US": `Basic info`,
			"pt-BR": `Informações básicas`
		},
		"tabs.address.title": {
			"en-US": `Address`,
			"pt-BR": `Endereço`
		},
		"tabs.billingOptions.title": {
			"en-US": `Billing options`,
			"pt-BR": `Opções de cobrança`
		},
		"tabs.users.title": {
			"en-US": v => `Users (${v})`,
			"pt-BR": v => `Usuários (${v})`
		},
	})
}