import { State as _State, useHookstate } from '@hookstate/core'
import { Avatar, Button, Col, Input, Modal, Row, Space, Table, Tag, Tooltip, Typography } from 'antd'
import React from 'react'
import UserAvatar from 'src/components/avatar/user'
import { DatePicker } from 'src/components/datePickerWithMoment'
import { i18n_v2 } from 'src/lib/i18n'

import { getFormattedPrice, setUnformattedPrice } from '../../../lib/currencyInputFormatter'
import { useControllers } from './controllers'
import { i18nLocal } from './i18nLocal'
import { LocalState } from './state'
import { PropsType, TableRowType } from './types'

export const GenerateInvoicesModal: React.FC<PropsType> = (props) => {

  const state = useHookstate(LocalState)
  const controllers = useControllers(props)

  return (
    <Modal
      centered
      footer={null}
      title={
        <Typography.Title level={2}>
          {i18nLocal.index(`header.title`)}
        </Typography.Title>
      }
      closable={false}
      width={"90%"}
      open={props.open.get()}
    >
      <Row justify={"space-between"} align={"middle"}>
        <Col />
        <Col>
          <Space.Compact>
            <Input
              value={i18nLocal.index(`header.referenceMonth`)}
              disabled
              style={{ width: 150, fontWeight: `normal`, textAlign: `right` }}
            />
            <DatePicker
              picker={"month"}
              format={i18nLocal.index(`header.referenceMonth.format`)}
              value={state.filters.month.get()}
              onChange={state.filters.month.set}
            />
          </Space.Compact>
        </Col>
      </Row>
      <br />
      <div style={{ position: "relative" }}>
        <Table
          rowSelection={{
            onChange: keys => state.table.selectedPartners.set(keys),
            selectedRowKeys: state.table.selectedPartners.get() as React.Key[],
            getCheckboxProps: controllers.getTableRowSelectProps
          }}
          rowKey={row => row.partner?.get()._id}
          dataSource={state.table.data}
          loading={state.table.loading.get()}
          pagination={false}
          expandable={{ showExpandColumn: false }}
          columns={[
            {
              title: i18nLocal.index(`table.rows.partner`),
              render: (row: TableRowType) => (
                <Row
                  gutter={16}
                  align="middle"
                >
                  <Col>
                    <UserAvatar
                      name={row.partner?.displayName.get()}
                    />
                  </Col>
                  <Col>
                    {row.partner?.displayName.get()}
                  </Col>
                  <Col>
                    {
                      row.partner?.hasPendingInvoice.get()
                        ? (
                          <Tag color={"info"}>
                            {i18nLocal.index(`table.rows.partner.alreadyGenerated`)}
                          </Tag>
                        ) : (
                          !row.partner?.dueDate.get()
                            ? (
                              <Tag color={"error"}>
                                {i18nLocal.index(`table.rows.partner.missingDueDate`)}
                              </Tag>
                            ) : (
                              parseFloat(row.partner?.amount.get()) < 10
                                ? (
                                  <Tag color={"error"}>
                                    {i18nLocal.index(`table.rows.partner.lessThanMinimum`)}
                                  </Tag>
                                ) : (
                                  <Tag color={"success"}>
                                    {i18nLocal.index(`table.rows.partner.availableToGenerate`)}
                                  </Tag>
                                )
                            )
                        )
                    }
                  </Col>
                </Row>
              )
            },
            {
              title: "Workspaces",
              align: "center",
              render: (row: TableRowType) => (
                <Avatar.Group
                  maxCount={5}
                >
                  {
                    row.workspaces?.map(workspace => (
                      <Tooltip title={workspace.displayName.get()}>
                        <UserAvatar name={workspace.displayName.get()} />
                      </Tooltip>
                    ))
                  }
                </Avatar.Group>
              )
            },
            {
              title: i18nLocal.index(`table.rows.dueDate`),
              width: 200,
              render: (row: TableRowType) => (
                <DatePicker
                  placeholder={i18nLocal.index(`table.rows.dueDate.placeholder`)}
                  format={i18n_v2.Commons(`dateFormat`)}
                  style={{ width: "100%" }}
                  value={row.partner?.dueDate.get()}
                  onChange={row.partner?.dueDate.set}
                  disabledDate={controllers.handleDueDatePickerDisable}
                />
              )
            },
            {
              title: i18nLocal.index(`table.rows.amount`),
              width: 200,
              align: "right",
              render: (row: TableRowType) => (
                <Input
                  disabled={state.table.selectedPartners?.get().includes(row.partner?.get()._id)}
                  style={{ width: "100%", textAlign: "right", backgroundColor: "var(--body)" }}
                  value={getFormattedPrice(row.partner?.amount, true)}
                  onChange={setUnformattedPrice(row.partner?.amount)}
                />
              )
            },
          ]}
        />
        <br />
        <Row gutter={24} justify={"space-between"}>
          <Col>
            <Button
              danger
              type={"primary"}
              onClick={() => props.open.set(false)}
            >
              {i18n_v2.Commons(`cancel`)}
            </Button>
          </Col>
          <Col>
            <Button
              type={"primary"}
              disabled={state.table.selectedPartners?.get().length === 0}
              style={{ backgroundColor: state.table.selectedPartners?.get().length > 0 && "var(--green)" }}
              onClick={controllers.handleGenerateInvoices()}
            >
              {i18nLocal.index(`footer.generateButton.label`)}
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  )
}