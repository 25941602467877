import { State, useHookstate } from '@hookstate/core'
import { Enums } from '@roberty/models'
import { Button, Col, Empty, Input, Row, Space, Table, Tag, Tooltip, Typography } from 'antd'
import moment from 'moment'
import React from 'react'
import { Helmet } from 'react-helmet'
import UserAvatar from 'src/components/avatar/user'
import { DatePicker } from 'src/components/datePickerWithMoment'
import openNewWindowIcon from 'src/components/icons/openNewWindow.svg'
import trashIcon from 'src/components/icons/trash.svg'
import Layout from 'src/components/layout'
import { i18n_v2 } from 'src/lib/i18n'
import { paginationOnChange } from 'src/lib/tableHelpers'

import { SVGIcon } from '../../components/svgIcon'
import { useControllers } from './controllers'
import { GenerateInvoicesModal } from './generateInvoicesModal'
import { i18nLocal } from './i18nLocal'
import styles from './index.module.scss'
import { LocalState } from './state'
import { StateType } from './types'

const StatusTranslate: Record<Enums.PartnerInvoiceStatus, {
  label: string
  color: string
}> = {
  [Enums.PartnerInvoiceStatus["CANCELED"]]: {
    label: i18n_v2.Single({
      "en-US": `Canceled`,
      "pt-BR": `Cancelada`
    })(),
    color: "default"
  },
  [Enums.PartnerInvoiceStatus["EXPIRED"]]: {
    label: i18n_v2.Single({
      "en-US": `Expired`,
      "pt-BR": `Expirada`
    })(),
    color: "error"
  },
  [Enums.PartnerInvoiceStatus["PAID"]]: {
    label: i18n_v2.Single({
      "en-US": `Paid`,
      "pt-BR": `Paga`
    })(),
    color: "success"
  },
  [Enums.PartnerInvoiceStatus["UNPAID"]]: {
    label: i18n_v2.Single({
      "en-US": `Unpaid`,
      "pt-BR": `Pagamento pendente`
    })(),
    color: "cyan"
  },
}

export const FinancesPage: React.FC = () => {

  const controllers = useControllers()
  const state = useHookstate(LocalState)

  const pageTitle = i18nLocal.index(`header.title`)

  return (
    <>
      <Helmet>
        <title>{`${pageTitle} - Roberty Automation`}</title>
      </Helmet>
      <Layout currentMenu="finances">
        <div className={styles.finances}>
          <Row justify="space-between">
            <Col>
              <h1 className={styles.title}>
                {pageTitle}
              </h1>
            </Col>
            <Col>
              <Button
                type="primary"
                size="large"
                onClick={() => state.generateInvoicesModal.open.set(true)}
              >
                {i18nLocal.index(`header.newButton.label`)}
              </Button>
            </Col>
          </Row>
          <section>
            <Row justify="space-between">
              <Col>
                <h2 className={styles.title}>
                  {i18nLocal.index(`header.history.title`)}
                </h2></Col>
              <Col>
                <Space.Compact>
                  <Input
                    value={i18nLocal.index(`header.referenceMonth`)}
                    disabled
                    style={{ width: 150, fontWeight: `normal`, textAlign: `right` }}
                  />
                  <DatePicker
                    picker={"month"}
                    format={i18nLocal.index(`header.yearMonthFormat`)}
                    value={state.filters.month.get()}
                    onChange={state.filters.month.set}
                  />
                </Space.Compact>
              </Col>
            </Row>
            <br />
            <Table
              scroll={{ x: true }}
              loading={state.table.loading.get()}
              dataSource={state.table.data}
              locale={{
                emptyText: <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={i18nLocal.index(`table.noData`)}
                />
              }}
              expandable={{
                showExpandColumn: false
              }}
              pagination={{
                onChange: paginationOnChange(state.table.pagination),
                showSizeChanger: true,
                total: state.table.pagination.total.get(),
                current: state.table.pagination.page.get(),
                pageSize: state.table.pagination.pageSize.get(),
              }}
              columns={[
                {
                  title: (
                    <Typography.Text style={{ whiteSpace: "nowrap" }}>
                      {i18nLocal.index(`table.partner`)}
                    </Typography.Text>
                  ),
                  render: (record: State<StateType["table"]["data"][0]>) => (
                    <Row
                      style={{ flexWrap: "nowrap", }}
                      gutter={12}
                      align={"middle"}
                      justify={"start"}
                    >
                      <Col>
                        <UserAvatar name={record.partner.displayName.get()} />
                      </Col>
                      <Col>
                        <Typography.Text ellipsis >
                          {record.partner.displayName.get()}
                        </Typography.Text>
                      </Col>
                    </Row>
                  )
                },
                {
                  title: (
                    <Typography.Text style={{ whiteSpace: "nowrap" }}>
                      {i18nLocal.index(`table.referenceMonth`)}
                    </Typography.Text>
                  ),
                  render: (record: State<StateType["table"]["data"][0]>) => (
                    moment(record.referralMonth.get()).format(i18nLocal.index(`table.referenceMonthFormat`))
                  )
                },
                {
                  title: (
                    <Typography.Text style={{ whiteSpace: "nowrap" }}>
                      {i18nLocal.index(`table.amount`)}
                    </Typography.Text>
                  ),
                  align: "right",
                  render: (record: State<StateType["table"]["data"][0]>) => (
                    record.amount.get().toLocaleString("pt-br", { style: "currency", currency: "BRL" })
                  )
                },
                {
                  title: (
                    <Typography.Text style={{ whiteSpace: "nowrap" }}>
                      {i18nLocal.index(`table.dueDate`)}
                    </Typography.Text>
                  ),
                  render: (record: State<StateType["table"]["data"][0]>) => (
                    <Typography.Text style={{ whiteSpace: "nowrap" }}>
                      {moment(record.dueDate.get()).format(i18n_v2.Commons(`dateFormat`))}
                    </Typography.Text>
                  )
                },
                {
                  title: (
                    <Typography.Text style={{ whiteSpace: "nowrap" }}>
                      {"Status"}
                    </Typography.Text>
                  ),
                  align: "center",
                  render: (record: State<StateType["table"]["data"][0]>) => (
                    <Tag
                      color={StatusTranslate[record.status.get()]["color"]}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {StatusTranslate[record.status.get()]["label"]}
                    </Tag>
                  )
                },
                {
                  title: (
                    <Typography.Text style={{ whiteSpace: "nowrap" }}>
                      {i18nLocal.index(`table.paymentDate`)}
                    </Typography.Text>
                  ),
                  render: (record: State<StateType["table"]["data"][0]>) => (
                    <Typography.Text style={{ whiteSpace: "nowrap" }}>
                      {
                        record.paidAt.get()
                          ? moment(record.paidAt.get()).format(i18n_v2.Commons(`dateFormat`))
                          : ""
                      }
                    </Typography.Text>
                  )
                },
                {
                  title: "",
                  render: (record: State<StateType["table"]["data"][0]>) => (
                    <Row
                      style={{ flexWrap: "nowrap" }}
                      gutter={20}
                      align={"middle"}
                      justify={"space-between"}
                    >
                      {
                        record.iuguFields.invoiceLink.get()
                          ? <Tooltip title={i18nLocal.index(`table.actions.openInvoice`)}>
                            <a target='_blank' href={record.iuguFields.invoiceLink.get()}>
                              <Button
                                type="link"
                                size="small"
                              >
                                <SVGIcon
                                  style={{ fill: "var(--blue)" }}
                                  src={openNewWindowIcon}
                                />
                              </Button>
                            </a>
                          </Tooltip>
                          : undefined
                      }
                      {
                        record.status?.get() === Enums.PartnerInvoiceStatus["UNPAID"]
                          ? <>
                            <Tooltip title={i18nLocal.index(`table.actions.cancelInvoice`)}>
                              <Button
                                type="link"
                                size="small"
                                onClick={controllers.handleCancelInvoice(record)}
                              >
                                <SVGIcon
                                  style={{ fill: "var(--pink)" }}
                                  src={trashIcon}
                                />
                              </Button>
                            </Tooltip>
                          </>
                          : undefined
                      }
                    </Row>
                  )
                },
              ]}
            />
          </section>
        </div>
      </Layout>
      <GenerateInvoicesModal
        loadInvoice={controllers.loadData}
        open={state.generateInvoicesModal.open}
      />
    </>
  )
}