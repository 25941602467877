import { i18n_v2 } from 'src/lib/i18n'

export const i18nLocal = {
	controllers: i18n_v2.Collection({
		"removeModal.alert.message": {
			"en-US": `Attention`,
			"pt-BR": `Atenção`
		},
		"removeModal.alert.description": {
			"en-US": `You will have to move each single workspace of this partner to a new one`,
			"pt-BR": `Você precisará mover individualmente cada workspace deste parceiro para um novo`
		},
		"removeModal.question": {
			"en-US": v => `Remove the partner "${v}"?`,
			"pt-BR": v => `Remover o parceiro "${v}"?`,
		},
		"removeModal.removing": {
			"en-US": `Removing partner...`,
			"pt-BR": `Removendo parceiro...`
		},
		"removeModal.success": {
			"en-US": `Partner removed with success!`,
			"pt-BR": `Parceiro removido com sucesso!`
		},
		"removeModal.error": {
			"en-US": `Error when removing partner...`,
			"pt-BR": `Erro ao remover parceiro...`
		},
	}),
	index: i18n_v2.Collection({
		"header.page.title": {
			"en-US": `Partners`,
			"pt-BR": `Parceiros`
		},
		"card.partnersCount.title": {
			"en-US": `Partners total`,
			"pt-BR": `Total de parceiros`
		},
		"header.newPartnerButton.label": {
			"en-US": `Create a new partner`,
			"pt-BR": `Criar um novo parceiro`
		},
		"table.searchInput.placeholder": {
			"en-US": `Filter partners...`,
			"pt-BR": `Filtrar parceiros...`
		},
		"table.partner.title": {
			"en-US": `Partner`,
			"pt-BR": `Parceiro`
		},
		"table.removeButton.tooltip": {
			"en-US": `Remove this partner`,
			"pt-BR": `Remover este parceiro`
		},
		"table.memberSince.title": {
			"en-US": `Member since`,
			"pt-BR": `Membro desde`
		},
		"table.noData": {
			"en-US": `No partners found`,
			"pt-BR": `Nenhum parceiro encontrado`
		},
	})
}