import { Button, Divider } from "antd"
import React from "react"
import { Helmet } from "react-helmet"
import { useNavigate } from "react-router-dom"

import Logo from "../../components/logo/logo"
import styles from "./index.module.scss"

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate()

  return (
    <>
      <Helmet>
        <title>Page not found - Roberty Automation</title>
      </Helmet>
      <div className={styles.notFound}>
        <Logo />
        <h1 className={styles.title}>
          Page not found 😢
        </h1>
        <small>
          The page you are looking for does not exist.
        </small>
        <Divider />
        <Button
          size="large"
          type="primary"
          onClick={() => navigate(-1)}
          block={true}
        >
          Back to previous page
        </Button>
        <small className={styles.footnote}>
          If you went to this page by clicking a link, please contact our support team at&nbsp;
          <a href="mailto:support@roberty.app">
            support@roberty.app
          </a>
        </small>
      </div>
    </>
  )
}

export default NotFoundPage