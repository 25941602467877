import { i18n_v2 } from 'src/lib/i18n'

export const i18nLocal = {
	controllers: i18n_v2.Collection({
		"cancelModal.question": {
			"en-US": v => `Cancel the invoice "${v}"?`,
			"pt-BR": v => `Cancelar a fatura "${v}"?`
		},
		"cancelModal.canceling": {
			"en-US": `Canceling invoice...`,
			"pt-BR": `Cancelando fatura...`
		},
		"cancelModal.success": {
			"en-US": `Invoice canceled!`,
			"pt-BR": `Fatura cancelada!`
		},
		"cancelModal.error": {
			"en-US": `Error when cancel the invoice...`,
			"pt-BR": `Erro ao cancelar a fatura...`
		},
		"invoices.loading": {
			"en-US": `Loading invoices...`,
			"pt-BR": `Carregando faturas...`
		},
		"invoices.loading.error": {
			"en-US": `Error when loading invoices...`,
			"pt-BR": `Erro ao carregar faturas...`
		},
	}),
	index: i18n_v2.Collection({
		"header.title": {
			"en-US": `Finances`,
			"pt-BR": `Financeiro`
		},
		"header.history.title": {
			"en-US": `Invoices history`,
			"pt-BR": `Histórico de faturas`
		},
		"header.newButton.label": {
			"en-US": `Generate invoices`,
			"pt-BR": `Gerar faturas`
		},
		"header.referenceMonth": {
			"en-US": `Reference month`,
			"pt-BR": `Mês de referência`
		},
		"header.yearMonthFormat": {
			"en-US": `YYYY[-]MM`,
			"pt-BR": `MM[/]YYYY`
		},
		"table.noData": {
			"en-US": `No invoices to list`,
			"pt-BR": `Nenhuma fatura para listar`
		},
		"table.partner": {
			"en-US": `Partner`,
			"pt-BR": `Parceiro`
		},
		"table.referenceMonth": {
			"en-US": `Reference month`,
			"pt-BR": `Mês de referência`
		},
		"table.referenceMonthFormat": {
			"en-US": `MMMM[ of ]YYYY`,
			"pt-BR": `MMMM[ de ]YYYY`
		},
		"table.amount": {
			"en-US": `Amount`,
			"pt-BR": `Valor`
		},
		"table.dueDate": {
			"en-US": `Due date`,
			"pt-BR": `Vencimento`
		},
		"table.paymentDate": {
			"en-US": `Payment date`,
			"pt-BR": `Data de pagamento`
		},
		"table.actions.openInvoice": {
			"en-US": `Open invoice`,
			"pt-BR": `Abrir fatura`
		},
		"table.actions.cancelInvoice": {
			"en-US": `Cancel invoice`,
			"pt-BR": `Cancelar fatura`
		},
	})
}