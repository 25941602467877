import { i18n_v2 } from 'src/lib/i18n'

export const i18nLocal = {
	controllers: i18n_v2.Collection({
		"workspaces.loading.error": {
			"en-US": `Error when loading workspaces...`,
			"pt-BR": `Erro ao carregar workspaces...`
		},
		"consumableFeatures.removeModal.question": {
			"en-US": `Remove consumable feature?`,
			"pt-BR": `Remover recurso consumível?`
		},
		"planToCopy.loading": {
			"en-US": `Loading plan...`,
			"pt-BR": `Carregando plano...`
		},
		"planToCopy.loading.error": {
			"en-US": `Error when loading plan...`,
			"pt-BR": `Erro ao carregar plano...`
		},
		"saveModal.question": {
			"en-US": `Save changes?`,
			"pt-BR": `Salvar alterações?`
		},
		"saveModal.saving": {
			"en-US": `Saving plan...`,
			"pt-BR": `Salvando plano...`
		},
		"saveModal.success": {
			"en-US": `Plan saved with success!`,
			"pt-BR": `Plano salvo com sucesso!`
		},
		"saveModal.error": {
			"en-US": `Error during plan saving...`,
			"pt-BR": `Erro ao salvar plano...`
		},
	}),
	index: i18n_v2.Collection({
		"header.title.new": {
			"en-US": `Creating new plan`,
			"pt-BR": `Criando um plano novo`
		},
		"header.title.editing": {
			"en-US": `Editing plan`,
			"pt-BR": `Editando um plano`
		},
		"header.title.duplicating": {
			"en-US": `Duplication plan`,
			"pt-BR": `Duplicando um plano`
		},
		"header.saveButton.new.label": {
			"en-US": `Save new plan`,
			"pt-BR": `Salvar novo plano`
		},
		"header.saveButton.editing.label": {
			"en-US": `Update plan`,
			"pt-BR": `Atualizar plano`
		},
		"header.saveButton.duplication.label": {
			"en-US": `Duplicate plan`,
			"pt-BR": `Duplicar plano`
		},
		"form.name.label": {
			"en-US": `Plan name`,
			"pt-BR": `Nome do plano`
		},
		"form.visibility.label": {
			"en-US": `Visibility at public listings`,
			"pt-BR": `Visibilidade em listagens públicas`
		},
		"form.visibility.option.visible": {
			"en-US": `Visible`,
			"pt-BR": `Visível`
		},
		"form.visibility.option.hidden": {
			"en-US": `Hidden`,
			"pt-BR": `Oculto`
		},
		"form.order.label": {
			"en-US": `Listing order`,
			"pt-BR": `Ordem de listagem`
		},
		"form.order.periodicity": {
			"en-US": `Periodicity`,
			"pt-BR": `Periodicidade`
		},
		"form.order.periodicity.renewEvery": {
			"en-US": `Renew every`,
			"pt-BR": `Renovar a cada`
		},
		"form.order.periodicity.period": {
			"en-US": `period`,
			"pt-BR": `período`
		},
		"form.order.workspace.placeholder": {
			"en-US": `Type here to search`,
			"pt-BR": `Digite aqui para buscar`
		},
		"form.order.workspace.tip": {
			"en-US": `Starts typing to search...`,
			"pt-BR": `Comece a digitar para buscar...`
		},
		"form.order.workspace.loading": {
			"en-US": `Loading workspaces...`,
			"pt-BR": `Carregando workspaces...`
		},
		"form.order.workspace.noData": {
			"en-US": `No workspaces to list`,
			"pt-BR": `Nenhuma workspace para listar`
		},
		"form.order.workspace.extra": {
			"en-US": `If defined, this plan will be visible only for the selected workspace.`,
			"pt-BR": `Se definido, este plano será visível apenas para a workspace selecionado.`
		},
		"form.order.internalTags": {
			"en-US": `Internal tags`,
			"pt-BR": `Tags internas`
		},
		"form.order.internalTags.placeholder": {
			"en-US": `Type here and press "enter"`,
			"pt-BR": `Digite aqui e pressione "enter"`
		},
		"form.order.internalTags.noData": {
			"en-US": `No tags added`,
			"pt-BR": `Nenhuma tag adicionada`
		},
		"form.order.unavailableActions": {
			"en-US": `Unavailable actions`,
			"pt-BR": `Ações indisponíveis`
		},
		"form.order.unavailableActions.placeholder": {
			"en-US": `Click to select`,
			"pt-BR": `Clique para selecionar`
		},
		"form.order.consumableFeatures": {
			"en-US": `Consumable features`,
			"pt-BR": `Recursos consumíveis`
		},
		"form.order.consumableFeatures.feature": {
			"en-US": `Feature`,
			"pt-BR": `Recurso`
		},
		"form.order.consumableFeatures.period": {
			"en-US": `period`,
			"pt-BR": `período`
		},
		"form.order.consumableFeatures.infinityTip": {
			"en-US": `0 = infinity`,
			"pt-BR": `0 = infinito`
		},
		"form.order.consumableFeatures.addButton.label": {
			"en-US": `Add on more feature`,
			"pt-BR": `Adicionar mais um recurso`
		},
		"form.order.prices": {
			"en-US": `Prices`,
			"pt-BR": `Valores`
		},
	})
}