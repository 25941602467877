export const fetchZipCode = async (cep: string) => {
  type ViaCepResponse = {
    logradouro: string,
    bairro: string,
    localidade: string,
    uf: string,
  }

  const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`)
  const json = await response.json() as ViaCepResponse
  return {
    address: json.logradouro,
    city: json.localidade,
    district: json.bairro,
    state: json.uf
  } satisfies ZipCodeFetchedType
}

export type ZipCodeFetchedType = {
  address: string
  district: string
  city: string
  state: string
}