import { hookstate } from '@hookstate/core'
import { StateHelpers } from 'src/lib/stateHelpers'

import { StateType } from './types'

export const LocalState = hookstate<StateType>({
  errors: {},
  screenFields: {
    loadingZipCode: false,
    loadingPlans: false,
    plansFound: [],
    planSearchKeyword: "",
  },
  partner: {
    displayName: "",
    legalName: "",
    federalDocument: "",
    email: "",
    address: {
      zipCode: "",
      state: "",
      city: "",
      district: "",
      address: "",
      number: "",
      complement: "",
    },
    billingOptions: {
      amountForFixedBilling: undefined,
      billingType: undefined,
      minimalCost: undefined,
      workspacesPlan: undefined
    },
    createWorkspaceToo: false,
    adminUsers: [],
    developerUsers: [],
    billingUsers: [],
    workspaceOtherUsers: [],
  },
  newPlan: {
    create: false,
    planPeriodicity: undefined,
    planPeriodicityQuantity: undefined,
    internalTags: [],
    unavailableActions: [],
    consumableFeatures: [
      {
        amount: undefined,
        name: undefined,
        periodicity: undefined
      }
    ],
  }
})

const InitialState = {
  [`newPlan.create`]: false,
  [`newPlan.planPeriodicity`]: undefined,
  [`newPlan.planPeriodicityQuantity`]: undefined,
  [`newPlan.internalTags`]: [],
  [`newPlan.unavailableActions`]: [],
  [`newPlan.consumableFeatures`]: [
    {
      amount: undefined,
      name: undefined,
      periodicity: undefined
    }
  ],

  [`partner.createWorkspaceToo`]: false,
  [`partner.displayName`]: ``,
  [`partner.email`]: ``,
  [`partner.federalDocument`]: ``,
  [`partner.legalName`]: ``,
  [`partner.adminUsers`]: [],
  [`partner.developerUsers`]: [],
  [`partner.billingUsers`]: [],
  [`partner.workspaceOtherUsers`]: [],
  [`partner.address.zipCode`]: ``,
  [`partner.address.state`]: ``,
  [`partner.address.city`]: ``,
  [`partner.address.district`]: ``,
  [`partner.address.address`]: ``,
  [`partner.address.number`]: ``,
  [`partner.address.complement`]: ``,
  [`partner.billingOptions`]: {
    amountForFixedBilling: undefined,
    billingType: undefined,
    minimalCost: undefined,
    workspacesPlan: undefined
  },
  [`partner.billingType`]: undefined,
  [`partner.minimalCost`]: undefined,
  [`partner.workspacesPlan`]: undefined,
}

export const ClearState = () => StateHelpers.reset(InitialState, LocalState)