import { i18n_v2 } from 'src/lib/i18n'

export const i18nLocal = {
	controllers: i18n_v2.Collection({
		"removeModal.alert.message": {
			"en-US": `Attention`,
			"pt-BR": `Atenção`
		},
		"removeModal.alert.description": {
			"en-US": v => `This plan is being used in ${v} workspace(s)!`,
			"pt-BR": v => `Este plano está sendo utilizado por ${v} workspace(s)!`
		},
		"removeModal.question": {
			"en-US": v => `Remove the plan "${v}"?`,
			"pt-BR": v => `Remover o plano "${v}"`
		},
		"removeModal.removing": {
			"en-US": `Removing plan...`,
			"pt-BR": `Removendo plano...`
		},
		"removeModal.success": {
			"en-US": `Plan removed with success!`,
			"pt-BR": `Plano removido com sucesso!`
		},
		"removeModal.error": {
			"en-US": `Error when removing plan...`,
			"pt-BR": `Erro ao remover plano...`
		},
	}),
	index: i18n_v2.Collection({
		"header.title": {
			"en-US": `Plans`,
			"pt-BR": `Planos`
		},
		"header.newButton.label": {
			"en-US": `Create a new plan`,
			"pt-BR": `Criar um novo plano`
		},
		"header.cards.count.title": {
			"en-US": `Plans total`,
			"pt-BR": `Total de planos`
		},
		"table.search.label": {
			"en-US": `Filter plans`,
			"pt-BR": `Filtrar planos`
		},
		"table.noData": {
			"en-US": `No plans to list`,
			"pt-BR": `Nenhum plano para listar`
		},
		"table.rows.name.label": {
			"en-US": `Name`,
			"pt-BR": `Nome`
		},
		"table.rows.usage.label": {
			"en-US": `Usage`,
			"pt-BR": `Uso`
		},
		"table.rows.usage.data": {
			"en-US": v => `Used by ${v} subscripton(s)`,
			"pt-BR": v => `Usado por ${v} assinatura(s)`,
		},
		"table.rows.actions.duplicateButton.tip": {
			"en-US": `Duplicate this plan`,
			"pt-BR": `Duplicar este plano`,
		},
		"table.rows.actions.removeButton.tip": {
			"en-US": `Remove this plan`,
			"pt-BR": `Remover este plano`,
		},
	})
}