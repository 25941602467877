import { State } from '@hookstate/core'
import { BaseSyntheticEvent } from 'react'

export const getFormattedPrice = (state: State<string>, startsWithZero?: boolean) => {
  let value = state?.get() || (startsWithZero ? "0" : "")

  value = `${parseFloat(`${value}`.replace(/\D/g, "") || "0")}`
  const numericValueLength = value.length
  if (numericValueLength < 3) value = `${"0".repeat(3 - numericValueLength)}${value}`
  return value.replace(/\D/g, "").replace(/(\d)(\d{2})$/, "$1,$2").replace(/(\d)(\d{3},\d{2})$/, "$1.$2").replace(/(\d)(\d{3}\.\d{3},\d{2})$/, "$1.$2")
}

export const setUnformattedPrice = (state: State<string>) => ({ target: { value } }: BaseSyntheticEvent) => {
  state?.set(value.replace(/[^0-9]/g, "").replace(/([0-9]*)([0-9]{2})/, "$1.$2"))
}