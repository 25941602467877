import React from "react"
import { Helmet } from "react-helmet"
import Layout from "src/components/layout"

import styles from "./index.module.scss"
import HighlightCard from "src/components/highlightCard"
import { Link } from "react-router-dom"
import Card from "src/components/card"
import { Col, Row } from "antd"

const HomePage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Home - Roberty Automation</title>
      </Helmet>
      <Layout currentMenu="home">
        <div className={styles.home}>
          <h1 className={styles.title}>Home</h1>
          <Row gutter={16}>
            <Col xs={24} lg={8}>
              <Link to="/partners">
                <Card
                  className={styles.link}
                  color="blue"
                >
                  <b>Partners</b>
                </Card>
              </Link>
            </Col>
            <Col xs={24} lg={8}>
              <Link to="/workspaces">
                <Card
                  className={styles.link}
                  color="pink"
                >
                  <b>Workspaces</b>
                </Card>
              </Link>
            </Col>
            <Col xs={24} lg={8}>
              <Link to="/plans">
                <Card
                  className={styles.link}
                  color="red"
                >
                  <b>Plans</b>
                </Card>
              </Link>
            </Col>
            <Col xs={24} lg={8}>
              <Link to="/users">
                <Card
                  className={styles.link}
                  color="orange"
                >
                  <b>Users</b>
                </Card>
              </Link>
            </Col>
            <Col xs={24} lg={8}>
              <Link to="/finances">
                <Card
                  className={styles.link}
                  color="green"
                >
                  <b>Finances</b>
                </Card>
              </Link>
            </Col>
            <Col xs={24} lg={8}>
              <Link to="/settings">
                <Card
                  className={styles.link}
                  color="yellow"
                >
                  <b>Settings</b>
                </Card>
              </Link>
            </Col>
          </Row>
        </div>
      </Layout>
    </>
  )
}

export default HomePage