import { none, useHookstate } from '@hookstate/core'
import { Enums } from '@roberty/models'
import { Button, Empty, Select, Space, Tooltip } from 'antd'
import { i18n_v2 } from 'src/lib/i18n'

import { i18nLocal } from './i18nLocal'
import { LocalState } from './state'

const PartnerRoles: {
  label: string,
  value: Enums.PartnerUserAuthorizationRole
}[] = [
    {
      label: i18n_v2.Single({
        "en-US": `Administrator`,
        "pt-BR": `Administrador`
      })(),
      value: Enums.PartnerUserAuthorizationRole["ADMIN"]
    },
    {
      label: i18n_v2.Single({
        "en-US": `Developer`,
        "pt-BR": `Desenvolvedor`
      })(),
      value: Enums.PartnerUserAuthorizationRole["DEVELOPER"]
    },
    {
      label: i18n_v2.Single({
        "en-US": `Billing`,
        "pt-BR": `Financeiro`
      })(),
      value: Enums.PartnerUserAuthorizationRole["BILLING"]
    },
  ]

export const PartnersTab = () => {
  const state = useHookstate(LocalState)

  return (
    <>
      <Button
        type={"primary"}
        block
        onClick={() => state.user.partners.set(a => {
          a.unshift({ partner: undefined, role: undefined })
          return a
        })}
      >
        {i18nLocal.partnersTab(`addNew.button.label`)}
      </Button>
      <br />
      <br />
      {
        state.user.partners.map(({ partner, role }, index) => (
          <>
            <Space.Compact
              key={partner.get() ? partner.get().toString() : ""}
              style={{
                display: "flex",
                flexDirection: "row"
              }}
            >
              <Select
                notFoundContent={<Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={
                    state.screenFields.partners.keyword.get().trim().length > 0
                      ? (
                        state.screenFields.partners.loading.get()
                          ? i18nLocal.partnersTab(`form.partner.loading`)
                          : i18nLocal.partnersTab(`form.partner.noData`)
                      )
                      : i18nLocal.partnersTab(`form.partner.tip`)
                  }
                />}
                showSearch
                loading={state.screenFields.partners.loading.get()}
                value={partner.get()}
                onSelect={partner.set}
                filterOption={false}
                style={{ flex: 2, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                onSearch={value => state.screenFields.partners.keyword.set(value)}
                options={state.screenFields.partners.found.get().map(w => ({ value: w._id, label: w.displayName }))}
                placeholder={i18nLocal.partnersTab(`form.partner.placeholder`)}
              />
              <Select
                options={PartnerRoles}
                showSearch
                value={role.get()}
                onChange={role.set}
                placeholder={i18nLocal.partnersTab(`form.role.placeholder`)}
                style={{ flex: 1 }}
              />
              <Tooltip
                title={i18nLocal.partnersTab(`form.removeButton.tip`)}
              >
                <Button
                  danger
                  onClick={() => state.user.partners.merge({ [index]: none })}
                >
                  x
                </Button>
              </Tooltip>
            </Space.Compact>
            <br />
          </>
        ))
      }
    </>
  )
}