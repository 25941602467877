import moment from 'moment'

import I18nMessages from '../messages'

const enUS: I18nMessages = {
  "finances.title": "Finances",
  "finances.history.title": "Invoices",
  "finances.history.generateInvoices": "Generat invoices",
  "finances.history.referenceMonth": "Reference month",
  "finances.history.amount": "Amount",
  "finances.history.cancel": "Cancel invoice",
  "finances.history.open": "Open invoice",
  "finances.history.dueDate": "Due date",
  "finances.history.dueDate.placeholder": "Select due date",
  "finances.history.dueDate.missing": "Missing due date",
  "finances.history.status.cancelled": "Cancelled",
  "finances.history.status.overdue": "Overdue payment",
  "finances.history.status.paid": "Paid",
  "finances.history.status.unpaid": "Waiting payment",
  "finances.history.upload": "Upload bank slip",
  "finances.history.paymentDate": "Payment date",
  "finances.history.generatedAt": "Invoice generated at",
  "finances.history.lessThanMinimum": "Amount lower than $10,00",
  "finances.history.alreadyGenerated": "Invoice already generated",
  "finances.history.availableToGenerate": "Available to generate invoice",
}

export default enUS