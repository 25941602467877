import { Models } from '@roberty/models'
import { Alert, message, Modal, notification } from 'antd'
import { i18n_v2 } from 'src/lib/i18n'
import RestClient from 'src/services/rest'

import { i18nLocal } from './i18n'

type Props = {
  setTableForceReload: (payload: number) => void
}

export const useControllers = (props: Props) => {

  const handleRemove = (partner: Models.Partner, confirmed?: boolean) => async () => {
    if (!confirmed) {
      Modal.confirm({
        content: <>
          <Alert
            type={"warning"}
            message={i18nLocal.controllers(`removeModal.alert.message`)}
            description={i18nLocal.controllers(`removeModal.alert.description`)}
          />
          <br />
          {i18nLocal.controllers(`removeModal.question`, partner.displayName)}
        </>,
        cancelText: i18n_v2.Commons(`no`),
        cancelButtonProps: { type: "dashed" },
        okText: i18n_v2.Commons(`yes`),
        onOk: handleRemove(partner, true)
      })
      return
    }
    // TODO: @theinvensi - add a question about move current partner workspace to another partner
    const destroy = message.loading({
      content: i18nLocal.controllers(`removeModal.removing`),
      duration: 0
    })
    try {
      await RestClient.Partners["-partner-"].delete({
        partnerId: partner._id.toString()
      })
      notification.success({ message: i18nLocal.controllers(`removeModal.success`) })
      props.setTableForceReload(Date.now())
    } catch (e) {
      console.error(e)
      notification.error({
        message: i18nLocal.controllers(`removeModal.error`),
        description: i18n_v2.Commons(`tryAgain`)
      })
    } finally {
      destroy()
    }
  }

  return {
    handleRemove,
  }
}