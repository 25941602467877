import { Alert, message, Modal, notification } from 'antd'
import { i18n_v2 } from 'src/lib/i18n'
import RestClient from 'src/services/rest'

import { i18nLocal } from './i18nLocal'

type RecordType = Awaited<ReturnType<typeof RestClient.Plans.get>>["list"][0]
type Props = {
  setTableForceReload: (payload: number) => void
}

export const useControllers = (props: Props) => {
  const handleRemovePlan = (plan: RecordType, confirmed?: boolean) => async () => {
    if (!confirmed) {
      Modal.confirm({
        content: <>
          {
            plan.usage > 0
              ? <>
                <Alert
                  type={"warning"}
                  message={i18nLocal.controllers(`removeModal.alert.message`)}
                  description={i18nLocal.controllers(`removeModal.alert.description`, plan.usage)}
                />
                <br />
              </>
              : undefined
          }
          {i18nLocal.controllers(`removeModal.question`, plan.name)}
        </>,
        cancelText: i18n_v2.Commons(`no`),
        cancelButtonProps: { type: "dashed" },
        okText: i18n_v2.Commons(`yes`),
        onOk: handleRemovePlan(plan, true)
      })
      return
    }
    const destroy = message.loading({
      content: i18nLocal.controllers(`removeModal.removing`),
      duration: 0
    })
    try {
      await RestClient.Plans["-plan-"].delete({
        planId: plan._id
      })
      notification.success({ message: i18nLocal.controllers(`removeModal.success`) })
      props.setTableForceReload(Date.now())
    } catch (e) {
      console.error(e)
      notification.error({
        message: i18nLocal.controllers(`removeModal.error`),
        description: i18n_v2.Commons(`tryAgain`)
      })
    } finally {
      destroy()
    }
  }

  return {
    handleRemovePlan,
  }
}