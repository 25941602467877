import { i18n_v2 } from 'src/lib/i18n'

export const i18nLocal = {
	controllers: i18n_v2.Collection({
		"finances.loading": {
			"en-US": `Loading partners finances...`,
			"pt-BR": `Carregando finanças dos parceiros...`
		},
		"finances.loading.error": {
			"en-US": `Error when load partners finances...`,
			"pt-BR": `Erro ao carregar finanças dos parceiros...`
		},
		"generationModal.alert.message": {
			"en-US": `Remember to double check the values before confirm!`,
			"pt-BR": `Lembre-se de verificar os valores antes de confirmar!`
		},
		"generationModal.question": {
			"en-US": `Confirm the generation of the invoice(s)?`,
			"pt-BR": `Confirma a geração da(s) fatura(s)?`
		},
		"generationModal.generating": {
			"en-US": `Generating invoices...`,
			"pt-BR": `Gerando fatura(s)...`
		},
		"generationModal.error": {
			"en-US": `Error when invoice generation...`,
			"pt-BR": `Erro ao gerar fatura(s)...`
		},
	}),
	index: i18n_v2.Collection({
		"header.title": {
			"en-US": `Finances > Generate invoices`,
			"pt-BR": `Financeiro > Gerar faturas`
		},
		"header.referenceMonth": {
			"en-US": `Reference month`,
			"pt-BR": `Mês de referência`
		},
		"header.referenceMonth.format": {
			"en-US": `YYYY[-]MM`,
			"pt-BR": `MM[/]YYYY`
		},
		"table.rows.partner": {
			"en-US": `Partner`,
			"pt-BR": `Parceiro`
		},
		"table.rows.partner.alreadyGenerated": {
			"en-US": `Invoice already generated`,
			"pt-BR": `Fatura já gerada`
		},
		"table.rows.partner.missingDueDate": {
			"en-US": `Select the due date`,
			"pt-BR": `Selecione a data de vencimento`
		},
		"table.rows.partner.lessThanMinimum": {
			"en-US": `Amount lower than minimum`,
			"pt-BR": `Valor abaixo do mínimo`
		},
		"table.rows.partner.availableToGenerate": {
			"en-US": `Invoice available to be generated`,
			"pt-BR": `Fatura disponível para gerar`
		},
		"table.rows.dueDate": {
			"en-US": `Due date`,
			"pt-BR": `Data de vencimento`
		},
		"table.rows.dueDate.placeholder": {
			"en-US": `Select a date`,
			"pt-BR": `Selecione uma data`
		},
		"table.rows.amount": {
			"en-US": `Amount`,
			"pt-BR": `Valor`
		},
		"footer.generateButton.label": {
			"en-US": `Generate invoices`,
			"pt-BR": `Gerar faturas`
		}
	})
}