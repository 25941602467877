import { State } from '@hookstate/core'

const reset = (initialState: Record<string, any>, state: State<any>) => {
	for (const [path, value] of Object.entries(initialState)) {
		let aux: State<any> = state
		for (const key of path.split(`.`)) {
			aux = aux.nested(key as any)
		}
		if (aux) aux.set(value)
	}
}

export const StateHelpers = {
	reset,
}