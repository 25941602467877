import getCurrentLocale from "../lib/getCurrentLocale"

const availableLanguages = ["pt-BR", "en-US"]
const defaultLanguage = "en-US"

const LocaleService = new class {

  start() {
    this.apply()
  }

  apply(locale?: string) {
    if (!locale) locale = this.get()
    document.documentElement.setAttribute("lang", locale)
  }

  get() {
    let locale = localStorage.getItem("locale") as string
    if (!locale) locale = getCurrentLocale()
    if (!this.isLanguageAvailable(locale)) locale = defaultLanguage
    return locale
  }

  set(locale: string) {
    if (!this.isLanguageAvailable(locale)) locale = defaultLanguage
    localStorage.setItem("locale", locale)
    this.apply(locale)
  }

  private isLanguageAvailable(locale: string) {
    return availableLanguages.includes(locale)
  }

}

export default LocaleService