import React, { PropsWithChildren, useEffect } from "react"

import styles from "./layout.module.scss"
import MainMenu from "../mainMenu"
import { LayoutProvider } from "./layout.context"

const Layout: React.FC<PropsWithChildren<LayoutProps>> = ({
  children,
  ...props
}) => {
  useEffect(() => {
    if (!props.title?.trim()) return
    document.title = props.title
  }, [props.title])

  return (
    <LayoutProvider>
      <div className={styles.layout}>
        <MainMenu currentMenu={props.currentMenu} />
        <div className={styles.content}>
          {children}
        </div>
      </div>
    </LayoutProvider>
  )
}

type LayoutProps = {
  currentMenu: string
  title?: string
}

export default Layout