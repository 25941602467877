import { Button, Col, Row, Tooltip } from 'antd'
import moment from 'moment'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import PartnerAvatar from 'src/components/avatar/partner'
import trashIcon from 'src/components/icons/trash.svg'
import Layout from 'src/components/layout'
import PaginatedTable from 'src/components/paginatedTable'
import StatisticsCard from 'src/components/statisticsCard'
import { SVGIcon } from 'src/components/svgIcon'
import { i18n_v2 } from 'src/lib/i18n'
import objectIdToDate from 'src/lib/objectIdToDate'
import { i18nLocal } from 'src/pages/partners/i18n'
import RestClient from 'src/services/rest'

import { useControllers } from './controllers'
import styles from './index.module.scss'

import type { Models } from "@roberty/models"
const PartnersPage: React.FC = () => {

  const [loading, setLoading] = useState(true)
  const [total, setTotal] = useState(0)
  const [tableForceReload, setTableForceReload] = useState(Date.now())

  const controllers = useControllers({ setTableForceReload })

  return (
    <>
      <Helmet>
        <title>{`${i18nLocal.index(`header.page.title`)} - Roberty Automation`}</title>
      </Helmet>
      <Layout currentMenu="partners">
        <div className={styles.partners}>
          <section>
            <Row justify="space-between">
              <Col>
                <h1 className={styles.title}>{i18nLocal.index(`header.page.title`)}</h1>
              </Col>
              <Col>
                <Link to={"./new"}>
                  <Button
                    type="primary"
                    size="large"
                  >
                    {i18nLocal.index(`header.newPartnerButton.label`)}
                  </Button>
                </Link>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} lg={8}>
                <StatisticsCard
                  title={i18nLocal.index(`card.partnersCount.title`)}
                  data={total}
                  loading={loading}
                />
              </Col>
            </Row>
          </section>
          <section>
            <PaginatedTable
              forceReload={tableForceReload}
              rowKey={"_id"}
              locale={{
                searchPlaceholder: i18nLocal.index(`table.searchInput.placeholder`),
                noData: i18nLocal.index(`table.noData`)
              }}
              request={RestClient.Partners.get}
              columns={[
                {
                  title: i18nLocal.index(`table.partner.title`),
                  render: (record: Models.Partner) => (
                    <Row
                      gutter={16}
                      align="middle"
                    >
                      <Col>
                        <PartnerAvatar
                          name={record.displayName}
                        />
                      </Col>
                      <Col>
                        <Link to={`./${record._id}`}>
                          <b>{record.displayName}</b>
                        </Link>
                      </Col>
                    </Row>
                  )
                },
                {
                  title: i18nLocal.index(`table.memberSince.title`),
                  render: (record: Models.Partner) => (
                    moment(objectIdToDate(record._id as string)).format(i18n_v2.Commons(`dateFormat`))
                  )
                },
                // TODO: @theinvensi - add each partner workspaces list
                {
                  title: "",
                  render: (record: Models.Partner) => (
                    <Row gutter={20} justify={"space-around"}>
                      <Col>
                        <Tooltip title={i18nLocal.index(`table.removeButton.tooltip`)}>
                          <Button
                            type={"link"}
                            onClick={controllers.handleRemove(record)}
                          >
                            <SVGIcon
                              src={trashIcon}
                              style={{ fill: "var(--pink)" }}
                            />
                          </Button>
                        </Tooltip>
                      </Col>
                    </Row>
                  )
                },
              ]}
              onLoad={response => {
                setTotal(response.total)
                setLoading(false)
              }}
            />
          </section>
        </div>
      </Layout>
    </>
  )
}

export default PartnersPage