import { Button, Col, Row, Tooltip } from 'antd'
import moment from 'moment'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import UserAvatar from 'src/components/avatar/user'
import trashIcon from 'src/components/icons/trash.svg'
import Layout from 'src/components/layout'
import PaginatedTable from 'src/components/paginatedTable'
import StatisticsCard from 'src/components/statisticsCard'
import objectIdToDate from 'src/lib/objectIdToDate'
import RestClient from 'src/services/rest'

import { SVGIcon } from '../../components/svgIcon'
import { useControllers } from './controllers'
import { i18nLocal } from './i18nLocal'
import styles from './index.module.scss'

import type { Models } from "@roberty/models"
const UsersPage: React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [total, setTotal] = useState(0)
  const [tableForceReload, setTableForceReload] = useState(Date.now())

  const controllers = useControllers({ setTableForceReload })

  return (
    <>
      <Helmet>
        <title>{`${i18nLocal.index(`header.title`)} - Roberty Automation`}</title>
      </Helmet>
      <Layout currentMenu="users">
        <div className={styles.users}>
          <section>
            <Row justify="space-between">
              <Col>
                <h1 className={styles.title}>{i18nLocal.index(`header.title`)}</h1>
              </Col>
              <Col>
                <Link to={"./new"}>
                  <Button
                    type="primary"
                    size="large"
                  >
                    {i18nLocal.index(`header.newButton.label`)}
                  </Button>
                </Link>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} lg={8}>
                <StatisticsCard
                  title={i18nLocal.index(`header.cards.count.title`)}
                  data={total}
                  loading={loading}
                />
              </Col>
            </Row>
          </section>
          <section>
            <PaginatedTable
              locale={{
                searchPlaceholder: i18nLocal.index(`table.search.placeholder`),
                noData: i18nLocal.index(`table.noData`)
              }}
              forceReload={tableForceReload}
              request={RestClient.Users.get}
              columns={[
                {
                  title: i18nLocal.index(`table.rows.info`),
                  render: (record: Models.User) => (
                    <Row
                      gutter={16}
                      align="middle"
                    >
                      <Col>
                        <UserAvatar
                          name={record.name || record.email}
                        />
                      </Col>
                      <Col>
                        {
                          record.name
                            ? <Link to={`./${record._id}`}>
                              <b>{record.name}</b>
                            </Link>
                            : i18nLocal.index(`table.rows.info.invitedUser`)
                        }
                        <br />
                        <small>
                          {record.email}
                        </small>
                      </Col>
                    </Row>
                  )
                },
                {
                  title: i18nLocal.index(`table.rows.createdAt`),
                  render: (record: Models.User) => (
                    <>
                      <span>
                        {objectIdToDate(record._id as string).toLocaleDateString()}
                      </span>
                      <br />
                      <small>
                        {moment(objectIdToDate(record._id as string)).fromNow()}
                      </small>
                    </>
                  )
                },
                {
                  title: "",
                  render: (_, plan) => (
                    <div className={styles.tableActions}>
                      <Tooltip title={"Remove user"}>
                        <Button
                          type="link"
                          size="small"
                          onClick={controllers.handleRemoveUser(plan)}
                        >
                          <SVGIcon
                            style={{ fill: "var(--pink)" }}
                            src={trashIcon}
                          />
                        </Button>
                      </Tooltip>
                    </div>
                  )
                },
              ]}
              onLoad={response => {
                setTotal(response.total)
                setLoading(false)
              }}
            />
          </section>
        </div>
      </Layout>
    </>
  )
}

export default UsersPage