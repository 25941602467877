import React from "react"
import clsx from "clsx"

import styles from "./card.module.scss"

const Card: React.FC<React.PropsWithChildren<CardProps>> = (props) => {
  return (
    <div
      className={clsx([
        styles.card,
        styles[props.color],
        props.className
      ])}
    >
      {props.children}
    </div>
  )
}

type CardProps = React.HTMLAttributes<HTMLDivElement> & {
  color?: "blue" | "green" | "red" | "pink" | "yellow" | "orange" | "default"
}

Card.defaultProps = {
  color: "blue"
}

export default Card