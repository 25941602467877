import { i18n_v2 } from 'src/lib/i18n'

export const i18nLocal = i18n_v2.Collection({
	"header.title": {
		"en-US": `Settings`,
		"pt-BR": `Configurações`
	},
	"appearance.title": {
		"en-US": `Appearance`,
		"pt-BR": `Aparência`
	},
	"appearance.theme": {
		"en-US": `Theme`,
		"pt-BR": `Tema`
	},
	"appearance.theme.auto": {
		"en-US": `Auto`,
		"pt-BR": `Automático`
	},
	"appearance.theme.light": {
		"en-US": `Light`,
		"pt-BR": `Claro`
	},
	"appearance.theme.dark": {
		"en-US": `Dark`,
		"pt-BR": `Escuro`
	},
	"language.title": {
		"en-US": `Language`,
		"pt-BR": `Idioma`
	},
	"language.confirmation": {
		"en-US": `Confirm the language change?`,
		"pt-BR": `Deseja alterar o idioma?`
	},
})