import * as cnpj from '@fnando/cnpj'
import { none, useHookstate } from '@hookstate/core'
import { Enums, Schemas } from '@roberty/models'
import { message, Modal, notification } from 'antd'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { i18n_v2 } from 'src/lib/i18n'
import { fetchZipCode } from 'src/lib/viaCep'
import { FeatureOptions } from 'src/pages/plans/[planId]/utils'
import RestClient from 'src/services/rest'
import { useDebounce } from 'use-debounce'
import { z } from 'zod'
import { generateErrorMessage } from 'zod-error'

import { i18nLocal } from './i18n'
import { ClearState, LocalState } from './state'
import { ParamsType, PropsType } from './types'

const NewPlanSaveValidation = Schemas.SubscriptionPlanSchema
  .pick({
    planPeriodicity: true,
    planPeriodicityQuantity: true,
    internalTags: true,
    unavailableActions: true,
    consumableFeatures: true,
  })
  .extend({
    create: z.boolean().optional()
  })

const SaveValidation = Schemas.PartnerSchema
  .pick({
    displayName: true,
    legalName: true,
    federalDocument: true,
    email: true,
    address: true,
  })
  .extend({
    createWorkspaceToo: z.boolean().optional(),
    adminUsers: Schemas.UserSchema.shape.email.array(),
    developerUsers: Schemas.UserSchema.shape.email.array(),
    billingUsers: Schemas.UserSchema.shape.email.array(),
    workspaceOtherUsers: Schemas.UserSchema.shape.email.array().optional(),
    federalDocument: Schemas.PartnerSchema.shape.federalDocument
      .refine(v => cnpj.isValid(v)),
    address: Schemas.PartnerSchema.shape.address
      .omit({ country: true })
      .extend({
        zipCode: Schemas.PartnerSchema.shape.address.shape.zipCode.refine(v => v ? v.length === 9 : true)
      })
      .deepPartial(),
    billingOptions: Schemas.PartnerSchema.shape.billingOptions.unwrap().extend({
      workspacesPlan: Schemas.PartnerSchema.shape.billingOptions.unwrap().shape.workspacesPlan.optional(),
      minimalCost: z.string()
        .default("0")
        .transform(v => parseFloat(v))
        .refine(v => v > 0),
      amountForFixedBilling: z.string()
        .default("0")
        .transform(v => parseFloat(v))
        .optional()
        .nullable()
    })
      .refine(({ billingType, amountForFixedBilling }) => {
        if (billingType === Enums.PartnerBillingType["FIXED"]) {
          if (amountForFixedBilling === 0) return false
        }
        return true
      })
  })

export const useControllers = (props?: PropsType) => {
  const state = useHookstate(LocalState)
  const params = useParams<ParamsType>()
  const navigate = useNavigate()
  const [debouncedPlanSearchKeyword] = useDebounce(state.screenFields.planSearchKeyword.get(), 100)

  const handleSearchPlans = async () => {
    if (debouncedPlanSearchKeyword.trim().length === 0) return
    try {
      state.screenFields.loadingPlans.set(true)
      const { plans } = await RestClient.Plans.byKeyword.post({
        keyword: debouncedPlanSearchKeyword
      })
      state.screenFields.plansFound.set(plans as any)
    } catch (e) {
      notification.error({
        message: i18nLocal.controllers(`plans.loading.error`),
        description: i18n_v2.Commons(`tryAgain`)
      })
    } finally {
      state.screenFields.loadingPlans.set(false)
    }
  }
  useEffect(() => { handleSearchPlans() }, [debouncedPlanSearchKeyword])

  const users = {
    handleSearchUsers: async (keyword: string) => {
      if (keyword.trim().length === 0) return
      const { users } = await RestClient.Users.byKeyword.post({ keyword })
      return users
    }
  }

  const newPlan = {
    handleAddNewFeature: () => state.newPlan.consumableFeatures.merge([{
      name: undefined,
      amount: undefined,
      periodicity: undefined
    }]),
    canAddMoreConsumableFeatures: () => {
      const addedFeatures = state.newPlan.consumableFeatures.map(f => f.name.get())
      if (addedFeatures.some(i => i === undefined)) return false
      const unavailableFeatures = state.newPlan.unavailableActions.get()
      return FeatureOptions.filter(f => (
        !addedFeatures.includes(f.value as any)
        && !unavailableFeatures.includes(f.value as any)
      )).length > 0
    },
    handleRemoveFeatureByIndex: (index: number, confirmed?: boolean) => () => {
      if (!confirmed) {
        Modal.confirm({
          content: i18nLocal.controllers(`consumableFeature.remove`),
          cancelText: i18n_v2.Commons(`no`),
          okText: i18n_v2.Commons(`yes`),
          cancelButtonProps: { type: "dashed" },
          onOk: newPlan.handleRemoveFeatureByIndex(index, true)
        })
        return
      }
      state.newPlan.consumableFeatures.merge({ [index]: none })
    },
    getMissingConsumableFeatures: () => {
      const addedFeatures = state.newPlan.consumableFeatures.map(f => f.name.get())
      const unavailableFeatures = state.newPlan.unavailableActions.get()
      return FeatureOptions.map(f => ({
        ...f,
        disabled: (
          addedFeatures.includes(f.value as any)
          || unavailableFeatures.includes(f.value as any)
        )
      }))
    }
  }

  const getFormattedFederalDocument = () => {
    const { groups } = state.partner.federalDocument.get()
      .replace(/[^0-9]/gi, "")
      .match(/(?<p1>[0-9]{0,2})(?<p2>[0-9]{0,3})(?<p3>[0-9]{0,3})(?<p4>[0-9]{0,4})(?<p5>[0-9]{0,2}).*/i)

    let output = `${groups["p1"]}`

    if (groups["p2"].length > 0) output += `.${groups["p2"]}`
    if (groups["p3"].length > 0) output += `.${groups["p3"]}`
    if (groups["p4"].length > 0) output += `/${groups["p4"]}`
    if (groups["p5"].length > 0) output += `-${groups["p5"]}`

    return output
  }

  const getFormattedZipCode = () => {
    const { groups } = state.partner.address.zipCode.get()
      .replace(/[^0-9]/gi, "")
      .match(/(?<p1>[0-9]{0,5})(?<p2>[0-9]{0,3}).*/i)

    let output = `${groups["p1"]}`

    if (groups["p2"].length > 0) output += `-${groups["p2"]}`

    return output
  }

  const loadPartnerToEdit = async () => {
    if (!props?.editing) return

    if (!params.partnerId) {
      navigate("..")
      return
    }

    const destroy = message.loading({
      content: i18nLocal.controllers(`partner.loading`),
      duration: 0
    })
    try {
      const { partner, workspacesPlan } = await RestClient.Partners["-partner-"].get({
        partnerId: params.partnerId
      })
      if (!partner.billingOptions) partner.billingOptions = {
        billingType: undefined,
        minimalCost: undefined,
        workspacesPlan: undefined,
      }
      // @ts-ignore
      if (workspacesPlan) state.screenFields.plansFound.set([workspacesPlan])
      state.partner.set(partner as any)
    } catch (e) {
      notification.error({
        message: i18nLocal.controllers(`partner.loading.error`),
        description: i18n_v2.Commons(`tryAgain`)
      })
      navigate("..")
    } finally {
      destroy()
    }
  }
  useEffect(() => { loadPartnerToEdit() }, [])

  const handleSave = (confirmed?: boolean) => async () => {
    if (!confirmed) {
      Modal.confirm({
        content: i18nLocal.controllers(`partner.save.prompt`),
        cancelText: i18n_v2.Commons(`no`),
        cancelButtonProps: { type: "dashed" },
        okText: i18n_v2.Commons(`yes`),
        onOk: handleSave(true)
      })
      return
    }
    const destroy = message.loading({
      content: i18nLocal.controllers(`partner.saving`),
      duration: 0
    })
    try {
      const partner = SaveValidation.parse(state.partner.get())
      if (props?.editing) {
        await RestClient.Partners["-partner-"].put({ partnerId: params.partnerId, partner: partner as any })
        notification.success({
          message: i18nLocal.controllers(`partner.save.success`)
        })
      } else {
        const newPlan = (
          state.newPlan.create.get()
            ? NewPlanSaveValidation.parse(state.newPlan.get())
            : undefined
        )
        console.log({ newPlan })
        await RestClient.Partners.post({
          partner: partner as any,
          newPlan: newPlan as any
        })
        notification.success({
          message: i18nLocal.controllers(`partner.save.success`)
        })
      }
      navigate("..")
    } catch (e) {
      console.log(e)
      notification.error({
        message: i18nLocal.controllers(`partner.save.error`),
        description: i18n_v2.Commons(`tryAgain`)
      })
    } finally {
      destroy()
    }
  }

  const getUsersCount = () => (
    state.partner.adminUsers.length
    + state.partner.developerUsers.length
    + state.partner.billingUsers.length
  )

  const canSave = () => {
    const result = SaveValidation.safeParse(state.partner.get())
    state.errors.set({})
    if ((result as any)?.error?.issues) {
      console.error((result as any)?.error?.issues)
      state.errors.set(Object.fromEntries(generateErrorMessage(
        (result as any).error.issues,
        {
          delimiter: { component: ` - ` },
          code: { enabled: false },
          path: { label: ``, enabled: true, type: `objectNotation` },
          message: { label: ``, enabled: true }
        }
      ).split(` | `).map(b => b.split(` - `))))
    }
    const newPlanResult = (
      state.newPlan.create.get()
        ? NewPlanSaveValidation.safeParse(state.newPlan.get())
        : { success: true }
    )
    if ((newPlanResult as any)?.error?.issues) {
      console.error((newPlanResult as any)?.error?.issues)
      state.errors.set(Object.fromEntries(generateErrorMessage(
        (newPlanResult as any).error.issues,
        {
          delimiter: { component: ` - ` },
          code: { enabled: false },
          path: { label: ``, enabled: true, type: `objectNotation` },
          message: { label: ``, enabled: true }
        }
      ).split(` | `).map(b => b.split(` - `))))
    }
    return result.success && newPlanResult.success
  }

  useEffect(() => { return () => { ClearState() } }, [])

  const handleZipCodeLookup = async () => {
    if (state.partner.address.zipCode.get().length !== 9) return

    const destroy = message.loading({
      content: i18nLocal.controllers(`zipCode.fetching.loading`),
      duration: 0
    })
    try {
      state.screenFields.loadingZipCode.set(true)
      const zipCodeFetched = await fetchZipCode(state.partner.address.zipCode.get())

      if (zipCodeFetched.state) state.partner.address.state.set(zipCodeFetched.state)
      if (zipCodeFetched.city) state.partner.address.city.set(zipCodeFetched.city)
      if (zipCodeFetched.address) state.partner.address.address.set(zipCodeFetched.address)
      if (zipCodeFetched.district) state.partner.address.district.set(zipCodeFetched.district)
    } catch (e) {
      notification.error({
        message: i18nLocal.controllers(`zipCode.fetching.error`),
        description: i18n_v2.Commons(`tryAgain`)
      })
    } finally {
      state.screenFields.loadingZipCode.set(false)
      destroy()
    }
  }
  useEffect(() => { handleZipCodeLookup() }, [state.partner.address.zipCode])

  return {
    canSave,
    handleSave,
    getFormattedFederalDocument,
    getFormattedZipCode,
    users,
    getUsersCount,
    newPlan,
  }
}