import { hookstate, State } from '@hookstate/core'
import { StateHelpers } from 'src/lib/stateHelpers'

import { StateType } from './types'

export const LocalState = hookstate<StateType>({
  errors: {},
  planAndSubscriptionTab: {
    planFinderModal: {
      open: false,
      keyword: ``,
      loadingPlanSuggestions: false,
      loadingSourcePlan: false,
      plansFound: [],
      selectedPlan: undefined
    },
    subscription: {
      autoRenew: false,
      cannotChangeAutoRenew: false,
      cannotChangePlan: false,
      renewWithoutPayment: false,
    },
    plan: {
      name: undefined,
      planPeriodicity: undefined,
      planPeriodicityQuantity: undefined,
      unavailableActions: [],
      consumableFeatures: [
        {
          amount: undefined,
          name: undefined,
          periodicity: undefined
        }
      ],
      currencyBasedPrices: {},
    },
  },
  addressTab: {
    zipCode: {
      loading: false,
    },
  },
  basicTab: {
    partners: {
      loading: false,
      found: [],
      keyword: "",
    },
  },
  workspace: {
    active: true,
    type: undefined,
    displayName: ``,
    legalName: ``,
    federalDocument: ``,
    email: ``,
    address: {
      zipCode: ``,
      state: ``,
      city: ``,
      district: ``,
      address: ``,
      number: ``,
      complement: ``,
    },
    partner_: undefined,
    admins: [],
    developers: [],
    otherUsers: []
  }
})

export const InitialState = {
  [`workspace.type`]: undefined,
  [`workspace.displayName`]: ``,
  [`workspace.email`]: ``,
  [`workspace.legalName`]: ``,
  [`workspace.federalDocument`]: ``,
  [`workspace.partner_`]: undefined,
  [`workspace.address.zipCode`]: ``,
  [`workspace.address.address`]: ``,
  [`workspace.address.number`]: ``,
  [`workspace.address.complement`]: ``,
  [`workspace.address.district`]: ``,
  [`workspace.address.city`]: ``,
  [`workspace.address.state`]: ``,
  [`workspace.admins`]: [],
  [`workspace.developers`]: [],
  [`workspace.otherUsers`]: [],
}

export const ClearState = () => StateHelpers.reset(InitialState, LocalState)