import { AnimationControls } from "framer-motion"
import DarkModeService from "../../services/darkMode"

interface Animations {
  head: AnimationControls
  ear: AnimationControls
  helmet: AnimationControls
  face: AnimationControls
  leftEye: AnimationControls
  rightEye: AnimationControls
}

const animate = async (animations: Animations) => {
  strokeAnimation(animations.head)
  await Promise.all([
    strokeAnimation(animations.ear),
    strokeAnimation(animations.helmet),
    strokeAnimation(animations.face),
  ])

  colorsAnimation(animations.head)
  await Promise.all([
    colorsAnimation(animations.ear),
    colorsAnimation(animations.helmet),
    colorsAnimation(animations.face),
  ])

  await Promise.all([
    eyesAnimation(animations.leftEye),
    eyesAnimation(animations.rightEye),
  ])
  animate(animations)
}

const strokeAnimation = async (animation: AnimationControls) => {
  const isDarkMode = DarkModeService.get(true) === "dark"
  const stroke = isDarkMode ? "white" : "black"
  await animation.start({
    pathLength: 0,
    strokeWidth: 1,
    strokeOpacity: 1,
    stroke,
    fillOpacity: 0,
  })
  await animation.start({
    pathLength: 1,
    transition: {
      ease: "easeInOut",
      duration: 0.5
    },
  })
}

const colorsAnimation = async (animation: AnimationControls) => {
  await animation.start({
    fillOpacity: 1,
    transition: {
      ease: "easeInOut",
      duration: 0.5
    },
  })
}

const eyesAnimation = async (animation: AnimationControls) => {
  const eyesAnimation = [
    0, 0, 0, 0.1, 0, 0, 0, 0, 0, 0.4, 0, 0, 0.5, 0, 0, 0, 0, 0, 0, 0.1, 0.2, 0.3, 0.5, 0.7, 1,
  ]
  await animation.start({
    fillOpacity: eyesAnimation,
    transition: {
      ease: "linear",
      duration: 1
    },
  })
}

export default animate