import { i18n_v2 } from '../../lib/i18n'

export const i18nLocal = {
	controllers: i18n_v2.Collection({
		"removeModal.question": {
			"en-US": v => `Remove the workspace "${v}"?`,
			"pt-BR": v => `Remover a workspace "${v}"?`
		},
		"removeModal.removing": {
			"en-US": `Removing workspace...`,
			"pt-BR": `Removendo workspace...`
		},
		"removeModal.success": {
			"en-US": `Workspace removed with success!`,
			"pt-BR": `Workspace removida com sucesso!`
		},
		"removeModal.error": {
			"en-US": `Error when removing workspace...`,
			"pt-BR": `Erro ao remover workspace...`
		},
	}),
	index: i18n_v2.Collection({
		"headers.count.title": {
			"en-US": `Workspaces total`,
			"pt-BR": `Total de workspaces`
		},
		"headers.newButton.label": {
			"en-US": `Create a new workspace`,
			"pt-BR": `Criar um novo workspace`
		},
		"table.search.placeholder": {
			"en-US": `Filter workspaces...`,
			"pt-BR": `Filtrar workspaces...`
		},
		"table.memberSince.title": {
			"en-US": `Member since`,
			"pt-BR": `Membro desde`
		},
		"table.actions.removeButton.tip": {
			"en-US": `Remove this workspace`,
			"pt-BR": `Remover este workspace`
		},
		"table.noData": {
			"en-US": `No workspaces found`,
			"pt-BR": `Nenhum workspace encontrado`
		},
	})
}