import { hookstate } from '@hookstate/core'
import { StateHelpers } from 'src/lib/stateHelpers'

import { StateType } from './types'

export const LocalState = hookstate<StateType>({
  errors: {},
  screenFields: {
    workspaces: {
      keyword: "",
      loading: false,
      found: [],
    },
    partners: {
      keyword: "",
      loading: false,
      found: [],
    },
  },
  user: {
    name: "",
    email: "",
    phone: "",
    workspaces: [],
    partners: [],
  }
})

const InitialState = {
  [`user.name`]: ``,
  [`user.email`]: ``,
  [`user.phone`]: ``,
  [`user.workspaces`]: [],
  [`user.partners`]: [],
}

export const ClearState = () => StateHelpers.reset(InitialState, LocalState)