type Themes = "auto" | "light" | "dark"

type Callback = (theme: Themes) => void

const DarkModeService = new class {
  private listeners: Callback[] = []

  start() {
    this.apply()
    this.watch()
  }

  apply(theme?: Themes) {
    if (!theme) theme = this.get()
    if (!theme) theme = "auto"
    if (theme === "auto") theme = this.getBrowserDefault()
    document.documentElement.setAttribute("data-theme", theme)
    this.listeners.forEach(listener => listener(theme))
  }

  get(changeAutoToBrowserDefault = false): Themes {
    // return "light"
    let theme = localStorage.getItem("theme") as Themes
    if (!theme) theme = "auto"
    if (changeAutoToBrowserDefault && theme === "auto") theme = this.getBrowserDefault()
    return theme
  }

  getBrowserDefault(): Themes {
    if (!window.matchMedia) return "light"
    const isDark = window.matchMedia("(prefers-color-scheme: dark)").matches
    return isDark ? "dark" : "light"
  }

  set(theme: Themes) {
    localStorage.setItem("theme", theme)
    this.apply(theme)
  }

  onChange(callback: Callback) {
    if (typeof callback === "function") this.listeners.push(callback)
  }

  private watch() {
    if (!window.matchMedia) return
    window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", () => {
      if (this.get() === "auto") this.apply()
    })
  }

}

export default DarkModeService