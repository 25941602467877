import clsx from 'clsx'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { ReactSVG } from 'react-svg'
import AuthenticationService from 'src/services/authentication'

import UserAvatar from '../avatar/user'
import { LayoutContext } from '../layout/layout.context'
import styles from './accountSwap.module.scss'
import swapIcon from './icons/swap.svg'

const AccountSwap: React.FC = () => {
  const layoutContext = useContext(LayoutContext)
  const user = AuthenticationService.getUser()
  return (
    <Link to="/">
      <div className={clsx({
        [styles.accountSwap]: true,
        [styles.compact]: layoutContext.compactMenu,
      })}>
        <UserAvatar
          name={user?.name}
        />
        <div className={styles.workspaceInfo}>
          <strong>{user?.name}</strong>
        </div>
        <ReactSVG
          className={styles.swapIcon}
          src={swapIcon}
          wrapper="span"
        />
      </div>
    </Link>
  )
}

export default AccountSwap