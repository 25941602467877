import { DocumentType } from '@roberty/models'
import { Col, Empty, Input, Row, Table, TableProps } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { i18n_v2 } from 'src/lib/i18n'

const DEFAULT_PAGE_SIZE = 5
const PAGE_SIZE_OPTIONS = [5, 10, 20, 50, 100]

const PaginatedTable: React.FC<PaginatedTableProps> = ({
  request,
  onLoad,
  locale,
  hideSearch,
  forceReload,
  ...props
}) => {
  type DataSourceType = Awaited<ReturnType<typeof request>>

  const [searchParams, setSearchParams] = useSearchParams()
  const [loading, setLoading] = useState(true)
  const [total, setTotal] = useState(1)
  const [dataSource, setDataSource] = useState<DataSourceType["list"]>([])

  const onPageChange = useCallback((page: number, size: number) => {
    const clone = Object.fromEntries(searchParams)
    clone.page = String(page)
    clone.pageSize = String(size)
    setSearchParams(clone)
  }, [searchParams])

  const onFilterChange = useCallback((filter: string) => {
    const clone = Object.fromEntries(searchParams)
    clone.filter = filter
    setSearchParams(clone)
  }, [searchParams])

  useEffect(() => {
    const page = Number(searchParams.get("page") || 1)
    const pageSize = Number(searchParams.get("pageSize") || DEFAULT_PAGE_SIZE)
    const filter = searchParams.get("filter")
    setLoading(true)
    request({ page, pageSize, filter })
      .then((response) => {
        if (onLoad) onLoad(response)
        setTotal(response.total)
        setDataSource(response.list)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [searchParams, forceReload])

  return (
    <>
      {
        !hideSearch
          ? <>
            <Row justify="end">
              <Col xs={24} lg={6} xxl={4}>
                <Input.Search
                  size="large"
                  placeholder={locale?.searchPlaceholder || `Search`}
                  allowClear={true}
                  loading={loading}
                  defaultValue={searchParams.get("filter")}
                  onSearch={filter => onFilterChange(filter)}
                />
              </Col>
            </Row>
            <br />
          </>
          : undefined
      }
      <Table
        dataSource={dataSource}
        loading={loading}
        locale={{
          emptyText: <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={locale?.noData || `No data`}
          />
        }}
        rowKey={(record: DocumentType<any>) => record._id as string}
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
          total,
          locale: {
            items_per_page: i18n_v2.Commons(`perPage`)
          },
          current: Number(searchParams.get("page")) || 1,
          pageSize: Number(searchParams.get("pageSize") || DEFAULT_PAGE_SIZE),
          onChange: (page, size) => onPageChange(page, size),
        }}
        {...props}
      />
    </>
  )
}

type AntdTableProps<T = any> = Omit<TableProps<T>,
  "dataSource" |
  "loading" |
  "pagination"
>

type RequestResponse<T = any> = {
  total: number,
  list: T[]
}

type PaginatedTableProps<T = any> = AntdTableProps<T> & {
  hideSearch?: boolean
  locale?: {
    searchPlaceholder?: string
    noData?: string
  }
  request: (params: object) => Promise<RequestResponse<T>>
  onLoad?: (response: RequestResponse<T>) => void
  forceReload?: number
}

export default PaginatedTable