import { useHookstate } from '@hookstate/core'
import { Alert, Col, Form, Row } from 'antd'
import { SelectUsers } from 'src/components/selectUsers'
import { i18nLocal } from 'src/pages/partners/[partnerId]/i18n'
import AuthenticationService from 'src/services/authentication'

import { useControllers } from './controllers'
import { LocalState } from './state'
import { PropsType } from './types'

export const UsersTab = (props: PropsType) => {
  const state = useHookstate(LocalState)
  const controllers = useControllers()

  return (
    <Form layout={"vertical"}>
      <Row gutter={20}>
        <Col flex={"1"}>
          {
            !props.editing
              ? <Alert
                showIcon
                message={i18nLocal.usersTab(`header.autoAdmin.tip`)}
              />
              : undefined
          }
          <br />
          <Form.Item
            label={i18nLocal.usersTab(`form.adminUsers.label`, state.partner.adminUsers.length)}
            help={state.errors.nested(`admins`).get()}
            validateStatus={state.errors.nested(`admins`).get() ? `error` : ``}
          >
            <SelectUsers
              emailsToHide={[
                props.editing ? undefined : AuthenticationService.getUser().email,
                ...state.partner.adminUsers.get({ noproxy: true }),
                ...state.partner.developerUsers.get({ noproxy: true }),
                ...state.partner.billingUsers.get({ noproxy: true }),
                ...state.partner.workspaceOtherUsers?.get({ noproxy: true }) || [],
              ]}
              multiple
              value={state.partner.adminUsers}
              fetchUsers={controllers.users.handleSearchUsers as any}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col flex={"1"}>
          <Form.Item
            label={i18nLocal.usersTab(`form.developerUsers.label`, state.partner.developerUsers.length)}
          >
            <SelectUsers
              emailsToHide={[
                props.editing ? undefined : AuthenticationService.getUser().email,
                ...state.partner.adminUsers.get({ noproxy: true }),
                ...state.partner.developerUsers.get({ noproxy: true }),
                ...state.partner.billingUsers.get({ noproxy: true }),
                ...state.partner.workspaceOtherUsers?.get({ noproxy: true }) || [],
              ]}
              multiple
              value={state.partner.developerUsers}
              fetchUsers={controllers.users.handleSearchUsers as any}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col flex={"1"}>
          <Form.Item
            label={
              state.partner.createWorkspaceToo.get()
                ? i18nLocal.usersTab(`form.billingUsers.withWorkspace.label`, state.partner.billingUsers.length)
                : i18nLocal.usersTab(`form.billingUsers.normal.label`, state.partner.billingUsers.length)
            }
          >
            <SelectUsers
              emailsToHide={[
                props.editing ? undefined : AuthenticationService.getUser().email,
                ...state.partner.adminUsers.get({ noproxy: true }),
                ...state.partner.developerUsers.get({ noproxy: true }),
                ...state.partner.billingUsers.get({ noproxy: true }),
              ]}
              multiple
              value={state.partner.billingUsers}
              fetchUsers={controllers.users.handleSearchUsers as any}
            />
          </Form.Item>
        </Col>
      </Row>
      {
        state.partner.createWorkspaceToo.get()
          ? <Row gutter={20}>
            <Col flex={"1"}>
              <Form.Item
                label={i18nLocal.usersTab(`form.workspaceOtherUsers.label`, state.partner.workspaceOtherUsers.length)}
              >
                <SelectUsers
                  emailsToHide={[
                    props.editing ? undefined : AuthenticationService.getUser().email,
                    ...state.partner.adminUsers.get({ noproxy: true }),
                    ...state.partner.developerUsers.get({ noproxy: true }),
                  ]}
                  multiple
                  value={state.partner.workspaceOtherUsers}
                  fetchUsers={controllers.users.handleSearchUsers as any}
                />
              </Form.Item>
            </Col>
          </Row>
          : undefined
      }
    </Form>
  )
}