import { useHookstate } from '@hookstate/core'
import { Button, Col, Divider, Empty, Form, Input, InputNumber, Radio, Row, Select, Space, Tooltip, Typography } from 'antd'
import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from 'src/components/layout'
import { i18n_v2 } from 'src/lib/i18n'

import { getFormattedPrice, setUnformattedPrice } from '../../../lib/currencyInputFormatter'
import { useControllers } from './controllers'
import { i18nLocal } from './i18nLocal'
import styles from './index.module.scss'
import { LocalState } from './state'
import { PropsType } from './types'
import {
  CurrencyOptions,
  FeatureConsumptionPeriodicityOptions,
  FeatureOptions,
  SubscriptionPeriodicityOptions,
} from './utils'

export const PlanPage: React.FC<PropsType> = props => {

  const state = useHookstate(LocalState)
  const controllers = useControllers(props)

  const pageTitle = props.editing
    ? i18nLocal.index(`header.title.editing`)
    : (
      props.duplicate
        ? i18nLocal.index(`header.title.duplicating`)
        : i18nLocal.index(`header.title.new`)
    )

  return (
    <>
      <Helmet>
        <title>{`${pageTitle} - Roberty Automation`}</title>
      </Helmet>
      <Layout currentMenu="plans">
        <div className={styles.plan}>
          <Row gutter={20}>
            <Col flex={1}>
              <h1 className={styles.title}>
                {pageTitle}
              </h1>
            </Col>
            <Col >
              <Button
                type={"primary"}
                disabled={!controllers.canSave()}
                onClick={controllers.handleSave()}
              >
                {
                  props.editing
                    ? i18nLocal.index(`header.saveButton.editing.label`)
                    : (
                      props.duplicate
                        ? i18nLocal.index(`header.saveButton.duplication.label`)
                        : i18nLocal.index(`header.saveButton.new.label`)
                    )
                }
              </Button>
            </Col>
          </Row>
          <Divider />
          <Form layout={"vertical"}>
            <Row gutter={20}>
              <Col flex={1}>
                <Form.Item
                  label={i18nLocal.index(`form.name.label`)}
                  required
                  help={state.errors.nested(`name`).get()}
                  validateStatus={state.errors.nested(`name`).get() ? `error` : ``}
                >
                  <Input
                    placeholder={i18n_v2.Commons(`required`)}
                    onChange={({ target: { value } }) => state.plan.name.set(value)}
                    value={state.plan.name.get()}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label={i18nLocal.index(`form.visibility.label`)}
                  required
                  help={state.errors.nested(`listed`).get()}
                  validateStatus={state.errors.nested(`listed`).get() ? `error` : ``}
                >
                  <Radio.Group
                    buttonStyle={"solid"}
                    value={state.plan.listed.get()}
                    onChange={({ target: { value } }) => state.plan.listed.set(value)}
                  >
                    <Radio.Button value={true}>
                      {i18nLocal.index(`form.visibility.option.visible`)}
                    </Radio.Button>
                    <Radio.Button value={false}>
                      {i18nLocal.index(`form.visibility.option.hidden`)}
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label={i18nLocal.index(`form.order.label`)}
                  required
                  help={state.errors.nested(`order`).get()}
                  validateStatus={state.errors.nested(`order`).get() ? `error` : ``}
                >
                  <InputNumber
                    placeholder={i18n_v2.Commons(`required`)}
                    value={state.plan.order.get()}
                    onChange={value => state.plan.order.set(value)}
                    min={0}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col flex={"1"}>
                <Form.Item
                  label={i18nLocal.index(`form.order.periodicity`)}
                  required
                  help={
                    state.errors.nested(`planPeriodicityQuantity`).get()
                    || state.errors.nested(`planPeriodicity`).get()
                  }
                  validateStatus={
                    state.errors.nested(`planPeriodicityQuantity`).get() ? `error` : (
                      state.errors.nested(`planPeriodicity`).get() ? `error` : ``
                    )
                  }
                >
                  <InputNumber
                    min={1}
                    style={{ width: "100%" }}
                    placeholder={"x"}
                    value={state.plan.planPeriodicityQuantity.get()}
                    onChange={value => state.plan.planPeriodicityQuantity.set(value)}
                    addonBefore={i18nLocal.index(`form.order.periodicity.renewEvery`)}
                    addonAfter={
                      <Select
                        placeholder={i18nLocal.index(`form.order.periodicity.period`)}
                        value={state.plan.planPeriodicity.get()}
                        onSelect={state.plan.planPeriodicity.set}
                        style={{ minWidth: 100 }}
                        options={SubscriptionPeriodicityOptions}
                      />
                    }
                  />
                </Form.Item>
              </Col>
              <Col flex={"1"}>
                <Form.Item
                  label={"Workspace"}
                  extra={
                    <small>
                      <Typography.Text>
                        {i18nLocal.index(`form.order.workspace.extra`)}
                      </Typography.Text>
                    </small>
                  }
                >
                  <Select
                    notFoundContent={<Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={
                        state.screenFields.workspaceSearchKeyword.get().trim().length > 0
                          ? (
                            state.screenFields.loadingWorkspaces.get()
                              ? i18nLocal.index(`form.order.workspace.loading`)
                              : i18nLocal.index(`form.order.workspace.noData`)
                          )
                          : i18nLocal.index(`form.order.workspace.tip`)
                      }
                    />}
                    showSearch
                    allowClear
                    loading={state.screenFields.loadingWorkspaces.get()}
                    onClear={() => state.plan.customer.set(undefined)}
                    value={state.plan.customer.get()}
                    onSelect={state.plan.customer.set}
                    filterOption={false}
                    onSearch={value => state.screenFields.workspaceSearchKeyword.set(value)}
                    searchValue={state.screenFields.workspaceSearchKeyword.get()}
                    options={state.screenFields.workspacesFound.get().map(w => ({ value: w._id, label: w.displayName }))}
                    placeholder={i18nLocal.index(`form.order.workspace.placeholder`)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col flex={"1"}>
                <Form.Item
                  label={i18nLocal.index(`form.order.internalTags`)}
                >
                  <Select
                    mode={"tags"}
                    notFoundContent={<Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={i18nLocal.index(`form.order.internalTags.noData`)}
                    />}
                    allowClear
                    value={state.plan.internalTags.get()}
                    onChange={state.plan.internalTags.set}
                    placeholder={i18nLocal.index(`form.order.internalTags.placeholder`)}
                  />
                </Form.Item>
              </Col>
              <Col flex={"1"}>
                <Form.Item
                  label={i18nLocal.index(`form.order.unavailableActions`)}
                >
                  <Select
                    allowClear
                    options={
                      [
                        {
                          label: FeatureOptions.find(v => v.value === `TEXTRACT_CALL`)?.label,
                          value: `IMAGE_RECOGNITION_ADVANCED_OCR`,
                        },
                      ]
                    }
                    value={state.plan.unavailableActions.get()}
                    onChange={state.plan.unavailableActions.set}
                    mode={"tags"}
                    placeholder={i18nLocal.index(`form.order.unavailableActions.placeholder`)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col flex={"1"}>
                <Form.Item
                  label={i18nLocal.index(`form.order.consumableFeatures`)}
                  required
                  help={
                    state.errors.nested(`consumableFeatures[0].name`).get()
                    || state.errors.nested(`consumableFeatures[0].amount`).get()
                    || state.errors.nested(`consumableFeatures[0].periodicity`).get()
                  }
                  validateStatus={
                    state.errors.nested(`consumableFeatures[0].name`).get() ? `error` : (
                      state.errors.nested(`consumableFeatures[0].amount`).get() ? `error` : (
                        state.errors.nested(`consumableFeatures[0].periodicity`).get() ? `error` : ``
                      )
                    )
                  }
                >
                  <Row gutter={20} >
                    {
                      state.plan.consumableFeatures.map((feature, index) => (
                        <Col flex={1}>
                          <Space.Compact style={{ display: "flex" }}>
                            <Select
                              style={{ flex: 1 }}
                              value={feature.name.get()}
                              onChange={feature.name.set}
                              placeholder={i18nLocal.index(`form.order.consumableFeatures.feature`)}
                              options={controllers.getMissingConsumableFeatures()}
                            />
                            <Tooltip title={i18nLocal.index(`form.order.consumableFeatures.infinityTip`)}>
                              <InputNumber
                                style={{}}
                                value={feature.amount.get()}
                                onChange={value => feature.amount.set(value)}
                                placeholder={"x"}
                                min={0}
                              />
                            </Tooltip>
                            <Select
                              style={{ flex: 1 }}
                              value={feature.periodicity.get()}
                              onSelect={feature.periodicity.set}
                              placeholder={i18nLocal.index(`form.order.consumableFeatures.period`)}
                              options={FeatureConsumptionPeriodicityOptions}
                            />
                            {
                              index > 0
                                ? <Tooltip title={"Remove this feature"}>
                                  <Button
                                    danger
                                    onClick={controllers.handleRemoveFeatureByIndex(index)}
                                  >
                                    X
                                  </Button>
                                </Tooltip>
                                : null
                            }
                          </Space.Compact>
                          <br />
                        </Col>
                      ))
                    }
                  </Row>
                  <Row gutter={20}>
                    <Col flex={1}>
                      <Button
                        block
                        disabled={!controllers.canAddMoreConsumableFeatures()}
                        type={"primary"}
                        onClick={controllers.handleAddNewFeature}
                      >
                        {i18nLocal.index(`form.order.consumableFeatures.addButton.label`)}
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
              <Col flex={"1"} >
                <Form.Item
                  label={i18nLocal.index(`form.order.prices`)}
                >
                  {
                    CurrencyOptions.map(currency => (
                      <>
                        <Input
                          addonBefore={currency}
                          value={getFormattedPrice(state.plan.currencyBasedPrices[currency])}
                          onChange={setUnformattedPrice(state.plan.currencyBasedPrices[currency])}
                          placeholder={i18n_v2.Commons(`required`)}
                        />
                        <br />
                        <br />
                      </>
                    ))
                  }
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Layout>
    </>
  )
}