import { Button, Divider } from "antd"
import React from "react"
import { Helmet } from "react-helmet"
import { Link, useRouteError } from "react-router-dom"

import Logo from "../../components/logo/logo"
import styles from "./index.module.scss"

const ErrorPage: React.FC = () => {
  const error = useRouteError() as Error

  return (
    <>
      <Helmet>
        <title>Error - Roberty Automation</title>
      </Helmet>
      <div className={styles.error}>
        <Logo />
        <h1 className={styles.title}>
          An unexpected error has occurred 😢
        </h1>
        <small>
          See the error details below:
        </small>
        {error && (
          <pre>
            {error.stack}
          </pre>
        )}
        <Divider />
        <Link to="/">
          <Button
            size="large"
            type="primary"
            block={true}
          >
            Back to the main page
          </Button>
        </Link>
        <small className={styles.footnote}>
          If the error persists, please contact our support team at&nbsp;
          <a href="mailto:support@roberty.app">
            support@roberty.app
          </a>
        </small>
      </div>
    </>
  )
}

export default ErrorPage