import { State } from "@hookstate/core"
import { PaginationProps } from "antd"

export type PaginationState = {
  page: number
  pageSize: number
  total: number
}

export const paginationOnChange: (state: State<PaginationState>) => PaginationProps["onChange"] = (state) => (page, pageSize) => {
  state.page.set(page)
  state.pageSize.set(pageSize)
}