import { useHookstate } from '@hookstate/core'
import { Alert, Col, Form, Row } from 'antd'
import { SelectUsers } from 'src/components/selectUsers'
import AuthenticationService from 'src/services/authentication'

import { PropsType } from '../../plans/[planId]/types'
import { useControllers } from './controllers'
import { i18nLocal } from './i18nLocal'
import { LocalState } from './state'

export const UsersTab = (props: PropsType) => {
  const state = useHookstate(LocalState)
  const controllers = useControllers()

  return (
    <Form layout={"vertical"}>
      <Row gutter={20}>
        <Col flex={"1"}>
          {
            !props.editing
              ? <Alert
                showIcon
                message={i18nLocal.usersTab(`autoAdmin.tip`)}
              />
              : undefined
          }
          <br />
          <Form.Item
            label={i18nLocal.usersTab(`adminUsers`, state.workspace.admins.length)}
            help={state.errors.nested(`admins`).get()}
            validateStatus={state.errors.nested(`admins`).get() ? `error` : ``}
          >
            <SelectUsers
              emailsToHide={[
                props.editing ? undefined : AuthenticationService.getUser().email,
                ...state.workspace.admins.get({ noproxy: true }),
                ...state.workspace.developers.get({ noproxy: true }),
                ...state.workspace.otherUsers.get({ noproxy: true }),
              ]}
              multiple
              value={state.workspace.admins}
              fetchUsers={controllers.users.handleSearchUsers as any}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col flex={"1"}>
          <Form.Item
            label={i18nLocal.usersTab(`developerUsers`, state.workspace.developers.length)}
          >
            <SelectUsers
              emailsToHide={[
                props.editing ? undefined : AuthenticationService.getUser().email,
                ...state.workspace.admins.get({ noproxy: true }),
                ...state.workspace.developers.get({ noproxy: true }),
                ...state.workspace.otherUsers.get({ noproxy: true }),
              ]}
              multiple
              value={state.workspace.developers}
              fetchUsers={controllers.users.handleSearchUsers as any}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col flex={"1"}>
          <Form.Item
            label={i18nLocal.usersTab(`otherUsers`, state.workspace.otherUsers.length)}
          >
            <SelectUsers
              emailsToHide={[
                props.editing ? undefined : AuthenticationService.getUser().email,
                ...state.workspace.admins.get({ noproxy: true }),
                ...state.workspace.developers.get({ noproxy: true }),
                ...state.workspace.otherUsers.get({ noproxy: true }),
              ]}
              multiple
              value={state.workspace.otherUsers}
              fetchUsers={controllers.users.handleSearchUsers as any}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}