import { useHookstate } from '@hookstate/core'
import { Enums } from '@roberty/models'
import { Button, Col, Divider, Empty, Form, Input, InputNumber, Radio, Row, Select, Space, Tooltip } from 'antd'
import { i18n_v2 } from 'src/lib/i18n'
import { FeatureConsumptionPeriodicityOptions } from 'src/pages/plans/[planId]/utils'

import { getFormattedPrice, setUnformattedPrice } from '../../../lib/currencyInputFormatter'
import { SubscriptionPeriodicityOptions } from '../../plans/[planId]/utils'
import { useControllers } from './controllers'
import { i18nLocal } from './i18n'
import { LocalState } from './state'
import { PropsType } from './types'

const BillingTypeOptions: {
  label: string,
  value: Enums.PartnerBillingType
}[] = [
    {
      label: i18n_v2.Single({
        "en-US": `Fixed amount`,
        "pt-BR": `Valor fixo`
      })(),
      value: Enums.PartnerBillingType["FIXED"]
    },
    {
      label: i18n_v2.Single({
        "en-US": `By workspace`,
        "pt-BR": `Por workspace`
      })(),
      value: Enums.PartnerBillingType["WORKSPACE"]
    }
  ]

export const BillingOptionsTab = (props: PropsType) => {
  const state = useHookstate(LocalState)
  const controllers = useControllers()

  return (
    <Form layout={"vertical"}>
      <Row gutter={20}>
        <Col flex={"1"}>
          <Form.Item
            label={i18nLocal.billingOptionsTab(`form.billingType.label`)}
            required
            help={state.errors.nested(`billingOptions.billingType`).get()}
            validateStatus={state.errors.nested(`billingOptions.billingType`).get() ? `error` : ``}
          >
            <Select
              options={BillingTypeOptions}
              placeholder={i18n_v2.Commons(`required`)}
              value={state.partner.billingOptions?.billingType?.get()}
              onChange={state.partner.billingOptions?.billingType?.set}
            />
          </Form.Item>
        </Col>
        <Col flex={"1"}>
          <Form.Item
            label={i18nLocal.billingOptionsTab(`form.minimalCost.label`)}
            required
            help={state.errors.nested(`billingOptions.minimalCost`).get()}
            validateStatus={state.errors.nested(`billingOptions.minimalCost`).get() ? `error` : ``}
          >
            <Input
              inputMode={"numeric"}
              addonBefore={"R$"}
              placeholder={i18n_v2.Commons(`required`)}
              value={getFormattedPrice(state.partner.billingOptions?.minimalCost)}
              onChange={setUnformattedPrice(state.partner.billingOptions?.minimalCost)}
            />
          </Form.Item>
        </Col>
        <Col flex={"1"}>
          <Form.Item
            label={i18nLocal.billingOptionsTab(`form.fixedAmount.label`)}
            required={state.partner.billingOptions?.billingType?.get() === Enums.PartnerBillingType["FIXED"]}
            help={
              state.partner.billingOptions?.billingType?.get() === Enums.PartnerBillingType["FIXED"]
                ? state.errors.nested(`billingOptions.amountForFixedBilling`).get()
                : ``
            }
            validateStatus={state.errors.nested(`billingOptions.amountForFixedBilling`).get() ? `error` : ``}
          >
            <Input
              disabled={state.partner.billingOptions?.billingType?.get() !== Enums.PartnerBillingType["FIXED"]}
              inputMode={"numeric"}
              addonBefore={"R$"}
              placeholder={
                state.partner.billingOptions?.billingType?.get() === Enums.PartnerBillingType["FIXED"]
                  ? i18n_v2.Commons(`required`)
                  : ``
              }
              value={getFormattedPrice(state.partner.billingOptions?.amountForFixedBilling)}
              onChange={setUnformattedPrice(state.partner.billingOptions?.amountForFixedBilling)}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        {
          !props.editing
            ? <Col flex={"1"}>
              <Form.Item
                label={i18nLocal.billingOptionsTab(`form.workspacesPlan.label`)}
              >
                <Radio.Group
                  buttonStyle={"solid"}
                  style={{ width: `100%` }}
                  value={state.newPlan.create.get()}
                  onChange={({ target: { value } }) => state.newPlan.create.set(value)}
                >
                  <Radio.Button
                    value={false}
                    style={{ textAlign: `center`, width: `50%` }}
                  >
                    {i18nLocal.billingOptionsTab(`form.workspacesPlan.options.select.label`)}
                  </Radio.Button>
                  <Radio.Button
                    value={true}
                    style={{ textAlign: `center`, width: `50%` }}
                  >
                    {i18nLocal.billingOptionsTab(`form.workspacesPlan.options.new.label`)}
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            : undefined
        }
        <Col flex={"1"}>
          <Form.Item
            label={
              props.editing
                ? i18nLocal.billingOptionsTab(`form.workspacesPlan.label`)
                : i18nLocal.billingOptionsTab(`form.pickPlan.label`)
            }
            required={!state.newPlan.create.get()}
            help={(
              !state.newPlan.create.get()
                ? state.errors.nested(`billingOptions.workspacesPlan`).get()
                : ``
            )}
            validateStatus={state.errors.nested(`billingOptions.workspacesPlan`).get() ? `error` : ``}
          >
            <Select
              disabled={state.newPlan.create.get()}
              notFoundContent={<Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  state.screenFields.planSearchKeyword.get().trim().length > 0
                    ? (
                      state.screenFields.loadingPlans.get()
                        ? i18nLocal.billingOptionsTab(`form.pickPlan.loading`)
                        : i18nLocal.billingOptionsTab(`form.pickPlan.notFound`)
                    )
                    : i18nLocal.billingOptionsTab(`form.pickPlan.startTyping`)
                }
              />}
              showSearch
              allowClear
              loading={state.screenFields.loadingPlans.get()}
              onClear={() => state.partner.billingOptions?.workspacesPlan?.set(undefined)}
              value={state.partner.billingOptions?.workspacesPlan?.get()}
              onSelect={state.partner.billingOptions?.workspacesPlan?.set}
              filterOption={false}
              onSearch={value => state.screenFields.planSearchKeyword.set(value)}
              searchValue={state.screenFields.planSearchKeyword.get()}
              options={state.screenFields.plansFound.get().map(w => ({ value: w._id, label: w.name }))}
              placeholder={i18nLocal.billingOptionsTab(`form.pickPlan.placeholder`)}
            />
          </Form.Item>
        </Col>
      </Row>
      {
        state.newPlan.create.get()
          ? <>
            <h2 style={{ marginBottom: 0 }}>{i18nLocal.billingOptionsTab(`newPlan.header.title`)}</h2>
            <Divider />
            <Form layout={"vertical"}>
              <Row gutter={20}>
                <Col flex={"1"}>
                  <Form.Item
                    label={i18nLocal.billingOptionsTab(`newPlan.form.periodicity.label`)}
                    required
                    help={
                      state.errors.nested(`planPeriodicityQuantity`).get()
                      || state.errors.nested(`planPeriodicity`).get()
                    }
                    validateStatus={
                      state.errors.nested(`planPeriodicityQuantity`).get() ? `error` : (
                        state.errors.nested(`planPeriodicity`).get() ? `error` : ``
                      )
                    }
                  >
                    <InputNumber
                      min={1}
                      style={{ width: "100%" }}
                      placeholder={`x`}
                      value={state.newPlan.planPeriodicityQuantity.get()}
                      onChange={value => state.newPlan.planPeriodicityQuantity.set(value)}
                      addonBefore={i18nLocal.billingOptionsTab(`newPlan.form.periodicity.renewEvery.label`)}
                      addonAfter={
                        <Select
                          placeholder={i18nLocal.billingOptionsTab(`newPlan.form.periodicity.period.placeholder`)}
                          value={state.newPlan.planPeriodicity.get()}
                          onSelect={state.newPlan.planPeriodicity.set}
                          style={{ minWidth: 100 }}
                          options={SubscriptionPeriodicityOptions}
                        />
                      }
                    />
                  </Form.Item>
                </Col>
                <Col flex={"1"}>
                  <Form.Item label={i18nLocal.billingOptionsTab(`newPlan.form.internalTags.label`)}>
                    <Select
                      mode={"tags"}
                      allowClear
                      value={state.newPlan.internalTags.get()}
                      onChange={state.newPlan.internalTags.set}
                      placeholder={i18nLocal.billingOptionsTab(`newPlan.form.internalTags.placeholder`)}
                    />
                  </Form.Item>
                </Col>
                <Col flex={"1"}>
                  <Form.Item
                    label={i18nLocal.billingOptionsTab(`newPlan.form.unavailableActions.label`)}
                  >
                    <Select
                      allowClear
                      options={[
                        {
                          label: `Advanced OCR`,
                          value: `IMAGE_RECOGNITION_ADVANCED_OCR`,
                        },
                      ]}
                      value={state.newPlan.unavailableActions.get()}
                      onChange={state.newPlan.unavailableActions.set}
                      mode={"tags"}
                      placeholder={i18nLocal.billingOptionsTab(`newPlan.form.unavailableActions.placeholder`)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={20}>
                <Col flex={"1"}>
                  <Form.Item
                    label={i18nLocal.billingOptionsTab(`newPlan.form.consumableFeatures.label`)}
                    required
                    help={
                      state.errors.nested(`consumableFeatures[0].name`).get()
                      || state.errors.nested(`consumableFeatures[0].amount`).get()
                      || state.errors.nested(`consumableFeatures[0].periodicity`).get()
                    }
                    validateStatus={
                      state.errors.nested(`consumableFeatures[0].name`).get() ? `error` : (
                        state.errors.nested(`consumableFeatures[0].amount`).get() ? `error` : (
                          state.errors.nested(`consumableFeatures[0].periodicity`).get() ? `error` : ``
                        )
                      )
                    }
                  >
                    <Row gutter={20} wrap>
                      {
                        state.newPlan.consumableFeatures.map((feature, index) => (
                          <Col flex={`50%`}>
                            <Space.Compact style={{ display: "flex" }}>
                              <Select
                                style={{ flex: 1 }}
                                value={feature.name.get()}
                                onChange={feature.name.set}
                                placeholder={i18nLocal.billingOptionsTab(`newPlan.form.consumableFeatures.feature`)}
                                options={controllers.newPlan.getMissingConsumableFeatures()}
                              />
                              <Tooltip title={i18nLocal.billingOptionsTab(`newPlan.form.consumableFeatures.infinityTip`)}>
                                <InputNumber
                                  style={{}}
                                  value={feature.amount.get()}
                                  onChange={value => feature.amount.set(value)}
                                  placeholder={"x"}
                                  min={0}
                                />
                              </Tooltip>
                              <Select
                                style={{ flex: 1 }}
                                value={feature.periodicity.get()}
                                onSelect={feature.periodicity.set}
                                placeholder={i18nLocal.billingOptionsTab(`newPlan.form.consumableFeatures.periodicity`)}
                                options={FeatureConsumptionPeriodicityOptions}
                              />
                              {
                                index > 0
                                  ? <Tooltip title={i18nLocal.billingOptionsTab(`newPlan.form.consumableFeatures.removeButton.label`)}>
                                    <Button
                                      danger
                                      onClick={controllers.newPlan.handleRemoveFeatureByIndex(index)}
                                    >
                                      X
                                    </Button>
                                  </Tooltip>
                                  : null
                              }
                            </Space.Compact>
                            <br />
                          </Col>
                        ))
                      }
                    </Row>
                    <Row gutter={20}>
                      <Col flex={1}>
                        <Button
                          block
                          disabled={!controllers.newPlan.canAddMoreConsumableFeatures()}
                          onClick={controllers.newPlan.handleAddNewFeature}
                          type={"primary"}
                        >
                          {i18nLocal.billingOptionsTab(`newPlan.form.consumableFeatures.addButton.label`)}
                        </Button>
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </>
          : undefined
      }
    </Form>
  )
}