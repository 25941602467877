import { i18n_v2 } from 'src/lib/i18n'

export const i18nLocal = {
	controllers: i18n_v2.Collection({
		"removeModal.question": {
			"en-US": v => `Removing the user "${v}"?`,
			"pt-BR": v => `Remover o usuário "${v}"?`
		},
		"removeModal.removing": {
			"en-US": `Removing user...`,
			"pt-BR": `Removendo usuário...`
		},
		"removeModal.success": {
			"en-US": `User removed with success!`,
			"pt-BR": `Usuário removido com sucesso!`
		},
		"removeModal.error": {
			"en-US": `Error when removing user...`,
			"pt-BR": `Erro ao remover usuário...`
		},
	}),
	index: i18n_v2.Collection({
		"header.title": {
			"en-US": `Users`,
			"pt-BR": `Usuários`
		},
		"header.newButton.label": {
			"en-US": `Create a new user`,
			"pt-BR": `Criar um novo usuário`
		},
		"header.cards.count.title": {
			"en-US": `Users total`,
			"pt-BR": `Total de usuários`
		},
		"table.search.placeholder": {
			"en-US": `Filter users`,
			"pt-BR": `Filtrar usuários`
		},
		"table.noData": {
			"en-US": `No users to list`,
			"pt-BR": `Nenhum usuário para listar`
		},
		"table.rows.info": {
			"en-US": `Name / E-mail`,
			"pt-BR": `Nome / E-mail`
		},
		"table.rows.info.invitedUser": {
			"en-US": `Pending invite`,
			"pt-BR": `Convite pendente`
		},
		"table.rows.createdAt": {
			"en-US": `Created at`,
			"pt-BR": `Criado em`
		}
	})
}