import moment from 'moment'

import I18nMessages from '../messages'

const ptBR: I18nMessages = {
  "finances.title": "Faturamento",
  "finances.history.title": "Histórico de faturas",
  "finances.history.generateInvoices": "Gerar faturas",
  "finances.history.referenceMonth": "Mês de referência",
  "finances.history.amount": "Valor (R$)",
  "finances.history.cancel": "Cancelar fatura",
  "finances.history.open": "Abrir fatura",
  "finances.history.dueDate": "Data de vencimento",
  "finances.history.dueDate.placeholder": "Selecionar data de vencimento",
  "finances.history.dueDate.missing": "Selecione a data de vencimento",
  "finances.history.status.cancelled": "Cancelado",
  "finances.history.status.unpaid": "Aguardando pagamento",
  "finances.history.status.paid": "Paga",
  "finances.history.status.overdue": "Vencida",
  "finances.history.upload": "Upload do boleto",
  "finances.history.paymentDate": "Data do pagamento",
  "finances.history.generatedAt": "Fatura gerada em",
  "finances.history.lessThanMinimum": "Valor menor que o R$10,00",
  "finances.history.alreadyGenerated": "Fatura já gerada",
  "finances.history.availableToGenerate": "Fatura disponível para gerar",
}

export default ptBR