import { State, useHookstate } from '@hookstate/core'
import { message, Modal, notification } from 'antd'
import { useEffect } from 'react'
import { i18n_v2 } from 'src/lib/i18n'
import RestClient from 'src/services/rest'

import { i18nLocal } from './i18nLocal'
import { LocalState } from './state'
import { StateType } from './types'

export const useControllers = () => {
  const state = useHookstate(LocalState)

  const handleCancelInvoice = (invoice: State<StateType["table"]["data"][0]>, confirmed?: boolean) => async () => {
    if (!confirmed) {
      Modal.confirm({
        content: i18nLocal.controllers(`cancelModal.question`, invoice.partner?.displayName.get()),
        cancelText: i18n_v2.Commons(`no`),
        cancelButtonProps: { type: "dashed" },
        okText: i18n_v2.Commons(`yes`),
        onOk: handleCancelInvoice(invoice, true)
      })
      return
    }
    const destroy = message.loading({
      content: i18nLocal.controllers(`cancelModal.canceling`),
      duration: 0
    })
    try {
      await RestClient.Finances.invoices._invoice_.cancel.delete({
        invoice: invoice?.get()._id
      })
      notification.success({ message: i18nLocal.controllers(`cancelModal.success`) })
      loadData()
    } catch (e) {
      console.error(e)
      notification.error({
        message: i18nLocal.controllers(`cancelModal.error`),
        description: i18n_v2.Commons(`tryAgain`)
      })
    } finally {
      destroy()
    }
  }

  const loadData = async () => {
    const destroy = message.loading({
      content: i18nLocal.controllers(`invoices.loading`),
      duration: 0
    })
    try {
      state.table.loading.set(true)
      const { records, total } = await RestClient.Finances.invoices.byMonth.get({
        month: state.filters.month.get().toDate(),
        page: state.table.pagination.page.get(),
        pageSize: state.table.pagination.pageSize.get(),
      })
      state.table.data.set(records)
      state.table.pagination.total.set(total)
    } catch (e) {
      console.error(e)
      notification.error({
        message: i18nLocal.controllers(`invoices.loading.error`),
        description: i18n_v2.Commons(`tryAgain`)
      })
    } finally {
      state.table.loading.set(false)
      destroy()
    }
  }
  useEffect(() => { loadData() }, [state.table.pagination, state.filters.month])

  return {
    loadData,
    handleCancelInvoice,
  }
}