import React, { PropsWithChildren, useEffect, useState } from "react"
import useMobile from "../../hooks/useMobile"

export const LayoutContext = React.createContext({} as LayoutContextProps)

export const LayoutProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [currentMenu, setCurrentMenu] = useState("/")
  const [notifications, setNotifications] = useState(0)
  const [supportTickets, setSupportTickets] = useState(0)
  const [compactMenu, setCompactMenu] = useState(loadCompactMenu())
  const mobile = useMobile()

  useEffect(() => {
    if (mobile) setCompactMenu(false)
    else setCompactMenu(loadCompactMenu())
  }, [mobile])

  const setCompactMenuAndSave = (value: boolean) => {
    localStorage.setItem("compactMenu", value.toString())
    setCompactMenu(value)
  }

  return (
    <LayoutContext.Provider value={{
      currentMenu,
      setCurrentMenu,
      compactMenu,
      setCompactMenu: setCompactMenuAndSave,
      notifications,
      setNotifications,
      supportTickets,
      setSupportTickets
    }}>
      {children}
    </LayoutContext.Provider>
  )
}

type LayoutContextProps = {
  currentMenu: string
  setCurrentMenu: React.Dispatch<React.SetStateAction<string>>
  compactMenu: boolean
  setCompactMenu: React.Dispatch<React.SetStateAction<boolean>>
  notifications: number
  setNotifications: React.Dispatch<React.SetStateAction<number>>
  supportTickets: number
  setSupportTickets: React.Dispatch<React.SetStateAction<number>>
}

function loadCompactMenu() {
  const compactMenu = localStorage.getItem("compactMenu")
  if (compactMenu === "true") return true
  return false
}