import { Models } from '@roberty/models'
import { message, Modal, notification } from 'antd'
import { i18n_v2 } from 'src/lib/i18n'
import RestClient from 'src/services/rest'

import { i18nLocal } from './i18nLocal'

type Props = {
  setTableForceReload: (payload: number) => void
}

export const useControllers = (props: Props) => {

  const handleRemove = (workspace: Models.Customer, confirmed?: boolean) => async () => {
    if (!confirmed) {
      Modal.confirm({
        content: i18nLocal.controllers(`removeModal.question`, workspace.displayName),
        cancelText: i18n_v2.Commons(`no`),
        cancelButtonProps: { type: "dashed" },
        okText: i18n_v2.Commons(`yes`),
        onOk: handleRemove(workspace, true)
      })
      return
    }
    const destroy = message.loading({
      content: i18nLocal.controllers(`removeModal.removing`),
      duration: 0
    })
    try {
      await RestClient.Workspaces["-workspace-"].delete({
        workspaceId: workspace._id.toString()
      })
      notification.success({ message: i18nLocal.controllers(`removeModal.success`) })
      props.setTableForceReload(Date.now())
    } catch (e) {
      console.error(e)
      notification.error({
        message: i18nLocal.controllers(`removeModal.error`),
        description: i18n_v2.Commons(`tryAgain`)
      })
    } finally {
      destroy()
    }
  }

  return {
    handleRemove,
  }
}