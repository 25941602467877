import React, { useContext } from "react"
import { Link, useParams } from "react-router-dom"
import clsx from "clsx"
import { ReactSVG } from "react-svg"

import styles from "./menuItems.module.scss"
import { LayoutContext } from "../layout/layout.context"
import i18n from "../../i18n"

const MenuItems: React.FC<MenuItemsProps> = ({ items }) => {
  const layoutContext = useContext(LayoutContext)
  const { partnerId } = useParams()
  return (
    <>
      {items.map(item => (
        <Link
          className={clsx({
            [styles.menuItem]: true,
            [styles.menuItemSoon]: item.soon,
            [styles.menuItemActive]: item.id === layoutContext.currentMenu,
          })}
          key={item.path.replace(":partnerId", partnerId)}
          to={item.path.replace(":partnerId", partnerId)}
          title={item.name}
        >
          <ReactSVG
            className={styles.menuItemIcon}
            src={item.icon}
            wrapper="span"
          />
          {!layoutContext.compactMenu && (
            <>
              <span className={styles.menuItemName}>
                {item.name}
              </span>
              {item.soon && (
                <span className={styles.menuItemBadge}>
                  {i18n["common.soon"]}
                </span>
              )}
              {!item.soon && item.id === "notifications" && layoutContext.notifications && (
                <span className={styles.menuItemBadge}>{layoutContext.notifications}</span>
              )}
              {!item.soon && item.id === "support" && !!layoutContext.supportTickets && (
                <span className={styles.menuItemBadge}>{layoutContext.supportTickets}</span>
              )}
            </>
          )}
        </Link>
      ))}
    </>
  )
}

export type MenuItemsProps = {
  items: {
    id: string
    name: string
    path: string
    icon: string
    soon?: boolean
  }[]
}

export default MenuItems