import { Form } from 'antd'

import { PlanSettings } from './planSettings'
import { SubscriptionSettings } from './subscriptionSettings'

export const CurrentPlanTab = () => (
  <Form layout={"vertical"}>
    <SubscriptionSettings />
    <PlanSettings />
  </Form>
)