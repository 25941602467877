import { i18n_v2 } from '../../../lib/i18n'

export const i18nLocal = {
	index: i18n_v2.Collection({
		"page.title.creating": {
			"en-US": `Creating a workspace`,
			"pt-BR": `Criando um workspace`,
		},
		"page.title.updating": {
			"en-US": `Editing a workspace`,
			"pt-BR": `Editando um workspace`,
		},
		"headers.saveButton.new.label": {
			"en-US": `Save new workspace`,
			"pt-BR": `Salvar novo workspace`
		},
		"headers.saveButton.update.label": {
			"en-US": `Update workspace`,
			"pt-BR": `Atualizar workspace`
		},
		"tabs.basicInfo.label": {
			"en-US": `Basic info`,
			"pt-BR": `Informações básicas`
		},
		"tabs.address.label": {
			"en-US": `Address`,
			"pt-BR": `Endereço`
		},
		"tabs.users.label": {
			"en-US": `Users`,
			"pt-BR": `Usuários`
		},
		"tabs.plan.label": {
			"en-US": `Plan and subscription`,
			"pt-BR": `Plano e assinatura`
		},
	}),
	basicInfoTab: i18n_v2.Collection({
		"form.type.label": {
			"en-US": `Type`,
			"pt-BR": `Tipo`
		},
		"form.name.label": {
			"en-US": `Title`,
			"pt-BR": `Título`
		},
		"form.legalName.forBusiness.label": {
			"en-US": `Legal name`,
			"pt-BR": `Razão social`
		},
		"form.legalName.forPerson.label": {
			"en-US": `Complete name`,
			"pt-BR": `Nome completo`
		},
		"form.federalDocument.forBusiness.label": {
			"en-US": `Federal document`,
			"pt-BR": `CNPJ`
		},
		"form.federalDocument.forPerson.label": {
			"en-US": `Federal document`,
			"pt-BR": `CPF`
		},
		"form.responsiblePartner.label": {
			"en-US": `Responsible partner`,
			"pt-BR": `Parceiro responsável`
		},
		"form.responsiblePartner.placeholder": {
			"en-US": `Type here to search`,
			"pt-BR": `Digite aqui para buscar`
		},
		"form.responsiblePartner.loading": {
			"en-US": `Loading partners...`,
			"pt-BR": `Carregando parceiros...`
		},
		"form.responsiblePartner.noData": {
			"en-US": `No partners found`,
			"pt-BR": `Nenhum parceiro encontrado`
		},
		"form.responsiblePartner.startTyping": {
			"en-US": `Start typing to search...`,
			"pt-BR": `Comece a digitar para procurar...`
		},
	}),
	addressTab: i18n_v2.Collection({
		"form.zipCode.label": {
			"en-US": `Zip code`,
			"pt-BR": `CEP`
		},
		"form.address.label": {
			"en-US": `Address`,
			"pt-BR": `Endereço`
		},
		"form.number.label": {
			"en-US": `Number`,
			"pt-BR": `Número`
		},
		"form.complement.label": {
			"en-US": `Complement`,
			"pt-BR": `Complemento`
		},
		"form.district.label": {
			"en-US": `District`,
			"pt-BR": `Bairro`
		},
		"form.city.label": {
			"en-US": `City`,
			"pt-BR": `Cidade`
		},
		"form.state.label": {
			"en-US": `State`,
			"pt-BR": `Estado`
		}
	}),
	usersTab: i18n_v2.Collection({
		"autoAdmin.tip": {
			"en-US": `You will be automatically added as a admin user.`,
			"pt-BR": `Você será automaticamente adicionado como um administrador.`
		},
		"adminUsers": {
			"en-US": v => `Admin users (${v})`,
			"pt-BR": v => `Administradores (${v})`
		},
		"developerUsers": {
			"en-US": v => `Developer users (${v})`,
			"pt-BR": v => `Desenvolvedores (${v})`
		},
		"otherUsers": {
			"en-US": v => `Other users (${v})`,
			"pt-BR": v => `Outros usuários (${v})`
		}
	}),
	planSubscriptionTab: i18n_v2.Collection({
		"headers.subscription": {
			"en-US": `Subscription settings`,
			"pt-BR": `Configurações da assinatura`
		},
		"subscription.form.autoRenew.label": {
			"en-US": `Auto renew`,
			"pt-BR": `Renovar automaticamente`
		},
		"subscription.form.autoRenew.description": {
			"en-US": `Defines if this current plan will automatically renew`,
			"pt-BR": `Define se o plano atual será renovado automaticamente`
		},
		"subscription.form.withoutPayment.label": {
			"en-US": ` without payment`,
			"pt-BR": `Renovar sem pagamento`
		},
		"subscription.form.withoutPayment.description": {
			"en-US": `Defines if will require a valid credit card to renew`,
			"pt-BR": `Define se o workspace precisará de um cartão de crédito válido para renovar`
		},
		"subscription.form.preventChangePlan.label": {
			"en-US": `Prevent change plan`,
			"pt-BR": `Evitar mudar de plano`
		},
		"subscription.form.preventChangePlan.description": {
			"en-US": `Prevent workspace user's to change from current plan`,
			"pt-BR": `Previne usuários do workspace de mudar o plano atual`
		},
		"subscription.form.preventChangeAutoRenew.label": {
			"en-US": `Prevent change auto renew`,
			"pt-BR": `Evitar mudar de renovação automática`
		},
		"subscription.form.preventChangeAutoRenew.description": {
			"en-US": `Prevent workspace user's to change "Auto renew" setting`,
			"pt-BR": `Previne usuários do workspace de mudar a "Auto renovação"`
		},
		"subscription.unableWhenPartner": {
			"en-US": `Unavailable when has a partner`,
			"pt-BR": `Desabilitado quando possui um parceiro`
		},
		"copyPlanModal.title": {
			"en-US": `Copy settings from plan...`,
			"pt-BR": `Copiar configurações de outro plano...`
		},
		"copyPlanModal.copyButton.label": {
			"en-US": `Copy settings`,
			"pt-BR": `Copiar configurações`
		},
		"copyPlanModal.form.source.label": {
			"en-US": `Source plan`,
			"pt-BR": `Plano de origem`
		},
		"copyPlanModal.form.source.placeholder": {
			"en-US": `Type here to list plans...`,
			"pt-BR": `Digite aqui para listar os planos...`
		},
		"copyPlanModal.form.source.tip": {
			"en-US": `Start typing to list plans...`,
			"pt-BR": `Comece a digitar para listar os planos...`
		},
		"copyPlanModal.form.source.loading": {
			"en-US": `Loading plans...`,
			"pt-BR": `Carregando planos...`
		},
		"copyPlanModal.form.source.noData": {
			"en-US": `No plans to list`,
			"pt-BR": `Nenhum plano para listar`
		},
		"plan.copyButton.label": {
			"en-US": `Copy settings from plan...`,
			"pt-BR": `Copiar configurações de outro plano...`
		},
		"plan.form.name.label": {
			"en-US": `Plan name`,
			"pt-BR": `Nome do plano`
		},
		"plan.form.periodicity.label": {
			"en-US": `Renew period`,
			"pt-BR": `Período de renovação`
		},
		"plan.form.periodicity.renewEvery.label": {
			"en-US": `Renew every`,
			"pt-BR": `Renovar a cada`
		},
		"plan.form.periodicity.period.placeholder": {
			"en-US": `period`,
			"pt-BR": `período`
		},
		"plan.form.unavailableActions.label": {
			"en-US": `Unavailable actions`,
			"pt-BR": `Ações indisponíveis`
		},
		"plan.form.unavailableActions.placeholder": {
			"en-US": `Click to select`,
			"pt-BR": `Clique para selecionar`
		},
		"plan.form.consumableFeatures.label": {
			"en-US": `Consumable features`,
			"pt-BR": `Recursos consumíveis`
		},
		"plan.form.consumableFeatures.feature": {
			"en-US": `Feature`,
			"pt-BR": `Recurso`
		},
		"plan.form.consumableFeatures.infinityTip": {
			"en-US": `0 = infinity`,
			"pt-BR": `0 = infinito`
		},
		"plan.form.consumableFeatures.periodicity": {
			"en-US": `periodicity`,
			"pt-BR": `periodicidade`
		},
		"plan.form.consumableFeatures.addButton.label": {
			"en-US": `Add more feature`,
			"pt-BR": `Adicionar mais recurso`
		},
		"plan.form.consumableFeatures.removeButton.label": {
			"en-US": `Remove this feature`,
			"pt-BR": `Remover este recurso`
		},
		"plan.form.prices.label": {
			"en-US": `Prices`,
			"pt-BR": `Valores`
		},
		"headers.plan": {
			"en-US": `Plan settings`,
			"pt-BR": `Configurações do plano`
		},
	}),
	controllers: i18n_v2.Collection({
		"workspace.save.prompt": {
			"en-US": `Save changes?`,
			"pt-BR": `Salvar mudanças?`
		},
		"workspace.saving": {
			"en-US": `Saving workspace...`,
			"pt-BR": `Salvando workspace...`
		},
		"workspace.loading": {
			"en-US": `Loading workspace...`,
			"pt-BR": `Carregando workspace...`
		},
		"workspace.loading.error": {
			"en-US": `Error when loading workspace...`,
			"pt-BR": `Erro ao carregar workspace...`
		},
		"workspace.save.error": {
			"en-US": `Error during workspace saving...`,
			"pt-BR": `Erro ao salvar workspace...`
		},
		"workspace.save.success": {
			"en-US": `Workspace saved with success!`,
			"pt-BR": `Workspace salvo com sucesso!`
		},
		"copyPlan.loading.error": {
			"en-US": `Error when loading source plan...`,
			"pt-BR": `Erro ao carregar plano para copiar...`
		},
		"copyPlan.loading": {
			"en-US": `Obtaining plan to copy...`,
			"pt-BR": `Obtendo plano para copiar...`
		},
		"plans.loading.error": {
			"en-US": `Error when loading plans...`,
			"pt-BR": `Erro ao carregar planos...`
		},
		"partners.loading.error": {
			"en-US": `Error when loading partners...`,
			"pt-BR": `Erro ao carregar parceiros...`
		},
		"zipCode.fetching.error": {
			"en-US": `Error when fetching zip code...`,
			"pt-BR": `Erro ao buscar CEP...`
		},
		"zipCode.fetching": {
			"en-US": `Fetching zip code...`,
			"pt-BR": `Buscando CEP...`
		},
		"consumableFeature.remove.prompt": {
			"en-US": `Remove consumable feature?`,
			"pt-BR": `Remover recurso consumível?`
		}
	})
}