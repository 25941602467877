import type { Models } from "@roberty/models"
import RestClient from "./rest"

const AuthenticationService = new class {
  private user: Models.User

  async check() {
    try {
      const response = await RestClient.Authorized.get()
      if (!response?.root) throw new Error("NOT_ROOT")
      this.user = response
      return true
    } catch (error) {
      console.error(error)
      this.redirectToAuthentication()
      return false
    }
  }

  getUser() {
    return this.user
  }

  private redirectToAuthentication() {
    const currentURL = location.href
    const authURL = new URL(`/?next=${currentURL}`, process.env.REACT_APP_AUTH_URL)
    location.href = authURL.href
  }
}

export default AuthenticationService