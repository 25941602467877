import { useHookstate } from '@hookstate/core'
import { Button, Col, Divider, Empty, Form, Modal, Row, Select } from 'antd'
import { i18n_v2 } from 'src/lib/i18n'

import { useControllers } from '../controllers'
import { i18nLocal } from '../i18nLocal'
import { LocalState } from '../state'
import { PlanFinderModalProps } from '../types'

export const PlanFinderModal = (props: PlanFinderModalProps) => {
	const state = useHookstate(LocalState.planAndSubscriptionTab.planFinderModal)
	const controllers = useControllers()

	return (
		<Modal
			open={props.open.get()}
			closable={false}
			centered
			title={<>
				{i18nLocal.planSubscriptionTab(`copyPlanModal.title`)}
				<Divider />
			</>}
			footer={<Row gutter={20} justify={`space-between`}>
				<Col>
					<Button onClick={controllers.planSettings.planFinderModal.handleCancel}>
						{i18n_v2.Commons(`cancel`)}
					</Button>
				</Col>
				<Col>
					<Button
						type={`primary`}
						disabled={!state.selectedPlan.get() || state.loadingSourcePlan.get()}
						loading={state.loadingSourcePlan.get()}
						onClick={controllers.planSettings.planFinderModal.handleLoadPlanToCopy}
					>
						{i18nLocal.planSubscriptionTab(`copyPlanModal.copyButton.label`)}
					</Button>
				</Col>
			</Row>}
		>
			<Form layout={`vertical`}>
				<Form.Item
					label={i18nLocal.planSubscriptionTab(`copyPlanModal.form.source.label`)}
				>
					<Select
						autoFocus
						filterOption={false}
						showSearch
						value={state.selectedPlan.get()}
						onSelect={state.selectedPlan.set}
						loading={state.loadingPlanSuggestions.get()}
						options={controllers.planSettings.planFinderModal.getPlansFoundList()}
						onSearch={state.keyword.set}
						notFoundContent={<Empty
							image={Empty.PRESENTED_IMAGE_SIMPLE}
							description={
								state.loadingPlanSuggestions.get()
									? i18nLocal.planSubscriptionTab(`copyPlanModal.form.source.loading`)
									: (
										state.keyword.get()
											? i18nLocal.planSubscriptionTab(`copyPlanModal.form.source.noData`)
											: i18nLocal.planSubscriptionTab(`copyPlanModal.form.source.tip`)
									)
							}
						/>}
						placeholder={i18nLocal.planSubscriptionTab(`copyPlanModal.form.source.placeholder`)}
					/>
				</Form.Item>
			</Form>
		</Modal>
	)
}