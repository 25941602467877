import { useHookstate } from '@hookstate/core'
import { Col, Divider, Form, Row, Switch, Tooltip, Typography } from 'antd'

import { i18nLocal } from '../i18nLocal'
import { LocalState } from '../state'

export const SubscriptionSettings = () => {
  const state = useHookstate(LocalState)

  return (
    <>
      <Typography.Title level={3} type={`secondary`}>
        {i18nLocal.planSubscriptionTab(`headers.subscription`)}
      </Typography.Title>
      <Divider style={{ marginTop: 0 }} />
      <Row gutter={20}>
        <Col flex={"1"}>
          <Form.Item
            className={`m-0`}
            label={i18nLocal.planSubscriptionTab(`subscription.form.autoRenew.label`)}
            extra={<Typography.Text type={`secondary`}>
              {i18nLocal.planSubscriptionTab(`subscription.form.autoRenew.description`)}
            </Typography.Text>}
          >
            <Tooltip
              title={
                !!state.workspace.get().partner_
                  ? i18nLocal.planSubscriptionTab(`subscription.unableWhenPartner`)
                  : undefined
              }
            >
              <Switch
                checked={state.planAndSubscriptionTab.subscription.autoRenew.get()}
                onChange={state.planAndSubscriptionTab.subscription.autoRenew.set}
                disabled={!!state.workspace.get().partner_}
              />
            </Tooltip>
          </Form.Item>
        </Col>
        <Col flex={"1"}>
          <Form.Item
            label={i18nLocal.planSubscriptionTab(`subscription.form.withoutPayment.label`)}
            extra={<Typography.Text type={`secondary`}>
              {i18nLocal.planSubscriptionTab(`subscription.form.withoutPayment.description`)}
            </Typography.Text>}
          >
            <Tooltip
              title={
                !!state.workspace.get().partner_
                  ? i18nLocal.planSubscriptionTab(`subscription.unableWhenPartner`)
                  : undefined
              }
            >
              <Switch
                checked={state.planAndSubscriptionTab.subscription.renewWithoutPayment.get()}
                onChange={state.planAndSubscriptionTab.subscription.renewWithoutPayment.set}
                disabled={!!state.workspace.get().partner_}
              />
            </Tooltip>
          </Form.Item>
        </Col>
        <Col flex={"1"}>
          <Form.Item
            label={i18nLocal.planSubscriptionTab(`subscription.form.preventChangePlan.label`)}
            extra={<Typography.Text type={`secondary`}>
              {i18nLocal.planSubscriptionTab(`subscription.form.preventChangePlan.description`)}
            </Typography.Text>}
          >
            <Tooltip
              title={
                !!state.workspace.get().partner_
                  ? i18nLocal.planSubscriptionTab(`subscription.unableWhenPartner`)
                  : undefined
              }
            >
              <Switch
                checked={state.planAndSubscriptionTab.subscription.cannotChangePlan.get()}
                onChange={state.planAndSubscriptionTab.subscription.cannotChangePlan.set}
                disabled={!!state.workspace.get().partner_}
              />
            </Tooltip>
          </Form.Item>
        </Col>
        <Col flex={"1"}>
          <Form.Item
            label={i18nLocal.planSubscriptionTab(`subscription.form.preventChangeAutoRenew.label`)}
            extra={<Typography.Text type={`secondary`}>
              {i18nLocal.planSubscriptionTab(`subscription.form.preventChangeAutoRenew.description`)}
            </Typography.Text>}
          >
            <Tooltip
              title={
                !!state.workspace.get().partner_
                  ? i18nLocal.planSubscriptionTab(`subscription.unableWhenPartner`)
                  : undefined
              }
            >
              <Switch
                checked={state.planAndSubscriptionTab.subscription.cannotChangeAutoRenew.get()}
                onChange={state.planAndSubscriptionTab.subscription.cannotChangeAutoRenew.set}
                disabled={!!state.workspace.get().partner_}
              />
            </Tooltip>
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}