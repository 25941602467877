import React, { useEffect, useState } from "react"
import { Avatar, AvatarProps } from "antd"

const WorkspaceAvatar: React.FC<WorkspaceAvatarProps> = (props) => {
  const [url, setUrl] = useState(props.src)
  const { workspaceId, name, src, ...rest } = props

  useEffect(() => {
    if (!props.src) {
      setUrl(`https://ui-avatars.com/api/?name=${props.name}&background=random`)
    }
  }, [props])

  return (
    <Avatar
      src={url}
      {...rest}
    />
  )
}

type WorkspaceAvatarProps = AvatarProps & {
  workspaceId?: string
  src?: string
  name: string
}

export default WorkspaceAvatar