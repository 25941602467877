import { useHookstate } from '@hookstate/core'
import { Button, Col, Divider, Form, Input, InputNumber, Row, Select, Space, Tooltip, Typography } from 'antd'

import { getFormattedPrice, setUnformattedPrice } from '../../../../lib/currencyInputFormatter'
import {
  CurrencyOptions,
  FeatureConsumptionPeriodicityOptions,
  SubscriptionPeriodicityOptions,
} from '../../../plans/[planId]/utils'
import { useControllers } from '../controllers'
import { i18nLocal } from '../i18nLocal'
import { LocalState } from '../state'
import { PlanFinderModal } from './planFinderModal'

export const PlanSettings = () => {
  const state = useHookstate(LocalState)
  const controllers = useControllers()

  return (
    <>
      <Row gutter={20} justify={`space-between`} align={`middle`}>
        <Col>
          <Typography.Title level={3} type={`secondary`}>
            {i18nLocal.planSubscriptionTab(`headers.plan`)}
          </Typography.Title>
        </Col>
        <Col>
          <Button
            onClick={() => state.planAndSubscriptionTab.planFinderModal.open.set(true)}
          >
            {i18nLocal.planSubscriptionTab(`plan.copyButton.label`)}
          </Button>
        </Col>
      </Row>
      <Divider style={{ marginTop: 0 }} />
      <Row gutter={20}>
        <Col flex={"1"}>
          <Form.Item
            label={i18nLocal.planSubscriptionTab(`plan.form.name.label`)}
            required
          >
            <Input
              placeholder={`Required`}
              value={state.planAndSubscriptionTab.plan.name.get()}
              onChange={({ target: { value } }) => state.planAndSubscriptionTab.plan.name.set(value)}
            />
          </Form.Item>
          <Form.Item
            label={i18nLocal.planSubscriptionTab(`plan.form.periodicity.label`)}
            required
          >
            <InputNumber
              min={1}
              style={{ width: "100%" }}
              placeholder={"x"}
              value={state.planAndSubscriptionTab.plan.planPeriodicityQuantity.get()}
              onChange={value => state.planAndSubscriptionTab.plan.planPeriodicityQuantity.set(value)}
              addonBefore={i18nLocal.planSubscriptionTab(`plan.form.periodicity.renewEvery.label`)}
              addonAfter={
                <Select
                  placeholder={i18nLocal.planSubscriptionTab(`plan.form.periodicity.period.placeholder`)}
                  value={state.planAndSubscriptionTab.plan.planPeriodicity.get()}
                  onSelect={state.planAndSubscriptionTab.plan.planPeriodicity.set}
                  style={{ minWidth: 100 }}
                  options={SubscriptionPeriodicityOptions}
                />
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col flex={"1"}>
          <Form.Item
            label={i18nLocal.planSubscriptionTab(`plan.form.unavailableActions.label`)}
          >
            <Select
              allowClear
              options={[
                {
                  label: `Advanced OCR`,
                  value: `IMAGE_RECOGNITION_ADVANCED_OCR`,
                },
              ]}
              value={state.planAndSubscriptionTab.plan.unavailableActions.get()}
              onChange={state.planAndSubscriptionTab.plan.unavailableActions.set}
              mode={"tags"}
              placeholder={i18nLocal.planSubscriptionTab(`plan.form.unavailableActions.placeholder`)}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col flex={"1"}>
          <Form.Item
            label={i18nLocal.planSubscriptionTab(`plan.form.consumableFeatures.label`)}
            required
          >
            <Row gutter={20} >
              {
                state.planAndSubscriptionTab.plan.consumableFeatures.map((feature, index) => (
                  <Col flex={1} key={feature.name.get()}>
                    <Space.Compact
                      style={{ display: "flex" }}
                    >
                      <Select
                        style={{ flex: 1 }}
                        value={feature.name.get()}
                        onChange={feature.name.set}
                        placeholder={i18nLocal.planSubscriptionTab(`plan.form.consumableFeatures.feature`)}
                        options={controllers.planSettings.getMissingConsumableFeatures()}
                      />
                      <Tooltip title={i18nLocal.planSubscriptionTab(`plan.form.consumableFeatures.infinityTip`)}>
                        <InputNumber
                          style={{}}
                          value={feature.amount.get()}
                          onChange={value => feature.amount.set(value)}
                          placeholder={"x"}
                          min={0}
                        />
                      </Tooltip>
                      <Select
                        style={{ flex: 1 }}
                        value={feature.periodicity.get()}
                        onSelect={feature.periodicity.set}
                        placeholder={i18nLocal.planSubscriptionTab(`plan.form.consumableFeatures.periodicity`)}
                        options={FeatureConsumptionPeriodicityOptions}
                      />
                      {
                        index > 0
                          ? <Tooltip title={i18nLocal.planSubscriptionTab(`plan.form.consumableFeatures.removeButton.label`)}>
                            <Button
                              danger
                              onClick={controllers.planSettings.handleRemoveFeatureByIndex(index)}
                            >
                              X
                            </Button>
                          </Tooltip>
                          : null
                      }
                    </Space.Compact>
                    <br />
                  </Col>
                ))
              }
            </Row>
            <Row gutter={20}>
              <Col flex={1}>
                <Button
                  block
                  type={"primary"}
                  disabled={!controllers.planSettings.canAddMoreConsumableFeatures()}
                  onClick={controllers.planSettings.handleAddNewFeature}
                >
                  {i18nLocal.planSubscriptionTab(`plan.form.consumableFeatures.addButton.label`)}
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Col>
        <Col flex={"1"} >
          <Form.Item
            label={i18nLocal.planSubscriptionTab(`plan.form.prices.label`)}
          >
            {
              CurrencyOptions.map(currency => (
                <div key={currency}>
                  <Input
                    addonBefore={currency}
                    value={getFormattedPrice(state.planAndSubscriptionTab.plan.currencyBasedPrices[currency])}
                    onChange={setUnformattedPrice(state.planAndSubscriptionTab.plan.currencyBasedPrices[currency])}
                    placeholder={"Required"}
                  />
                  <br />
                  <br />
                </div>
              ))
            }
          </Form.Item>
        </Col>
      </Row>
      <PlanFinderModal
        open={state.planAndSubscriptionTab.planFinderModal.open}
      />
    </>
  )
}