import { useHookstate } from '@hookstate/core'
import { Col, Form, Input, Row } from 'antd'

import { useControllers } from './controllers'
import { i18nLocal } from './i18n'
import { LocalState } from './state'

export const AddressTab = () => {
  const state = useHookstate(LocalState)
  const controllers = useControllers()

  return (
    <Form layout={"vertical"}>
      <Row gutter={20}>
        <Col flex={"1"}>
          <Form.Item
            label={i18nLocal.addressTab(`form.zipCode.label`)}
          >
            <Input
              disabled={state.screenFields.loadingZipCode.get()}
              maxLength={9}
              inputMode={"numeric"}
              onChange={({ target: { value } }) => state.partner.address.zipCode.set(value)}
              value={controllers.getFormattedZipCode()}
            />
          </Form.Item>
        </Col>
        <Col flex={"2"}>
          <Form.Item
            label={i18nLocal.addressTab(`form.address.label`)}
          >
            <Input
              disabled={state.screenFields.loadingZipCode.get()}
              inputMode={"text"}
              onChange={({ target: { value } }) => state.partner.address.address.set(value)}
              value={state.partner.address.address.get()}
            />
          </Form.Item>
        </Col>
        <Col flex={"1"}>
          <Form.Item
            label={i18nLocal.addressTab(`form.number.label`)}
          >
            <Input
              inputMode={"text"}
              onChange={({ target: { value } }) => state.partner.address.number.set(value)}
              value={state.partner.address.number.get()}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col flex={"1"}>
          <Form.Item
            label={i18nLocal.addressTab(`form.complement.label`)}
          >
            <Input
              inputMode={"text"}
              onChange={({ target: { value } }) => state.partner.address.complement.set(value)}
              value={state.partner.address.complement.get()}
            />
          </Form.Item>
        </Col>
        <Col flex={"1"}>
          <Form.Item
            label={i18nLocal.addressTab(`form.district.label`)}
          >
            <Input
              disabled={state.screenFields.loadingZipCode.get()}
              inputMode={"text"}
              onChange={({ target: { value } }) => state.partner.address.district.set(value)}
              value={state.partner.address.district.get()}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col flex={"1"}>
          <Form.Item
            label={i18nLocal.addressTab(`form.city.label`)}
          >
            <Input
              disabled={state.screenFields.loadingZipCode.get()}
              inputMode={"text"}
              onChange={({ target: { value } }) => state.partner.address.city.set(value)}
              value={state.partner.address.city.get()}
            />
          </Form.Item>
        </Col>
        <Col flex={"1"}>
          <Form.Item
            label={i18nLocal.addressTab(`form.state.label`)}
          >
            <Input
              disabled={state.screenFields.loadingZipCode.get()}
              inputMode={"text"}
              onChange={({ target: { value } }) => state.partner.address.state.set(value)}
              value={state.partner.address.state.get()}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}