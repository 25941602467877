import { Enums } from '@roberty/models'

import { i18n_v2 } from '../../../lib/i18n'

type SubscriptionPeriodicityOptionsType = { label: string, value: keyof typeof Enums.SubscriptionPlanPeriods }
export const SubscriptionPeriodicityOptions: SubscriptionPeriodicityOptionsType[] = [
  {
    value: Enums.SubscriptionPlanPeriods.DAILY,
    label: i18n_v2.Single({
      "en-US": `day`,
      "pt-BR": `dia`
    })()
  },
  {
    value: Enums.SubscriptionPlanPeriods.MONTHLY,
    label: i18n_v2.Single({
      "en-US": `month`,
      "pt-BR": `mês`
    })()
  },
  {
    value: Enums.SubscriptionPlanPeriods.WEEKLY,
    label: i18n_v2.Single({
      "en-US": `week`,
      "pt-BR": `semana`
    })()
  },
  {
    value: Enums.SubscriptionPlanPeriods.YEARLY,
    label: i18n_v2.Single({
      "en-US": `year`,
      "pt-BR": `ano`
    })()
  },
]

type FeatureConsumptionPeriodicityOptionsType = { label: string, value: keyof typeof Enums.SubscriptionConsumablePeriods }
export const FeatureConsumptionPeriodicityOptions: FeatureConsumptionPeriodicityOptionsType[] = [
  {
    label: i18n_v2.Single({
      "en-US": `per day`,
      "pt-BR": `por dia`
    })(),
    value: Enums.SubscriptionConsumablePeriods.DAILY
  },
  {
    label: i18n_v2.Single({
      "en-US": `per month`,
      "pt-BR": `por mês`
    })(),
    value: Enums.SubscriptionConsumablePeriods.MONTHLY
  },
  {
    label: i18n_v2.Single({
      "en-US": `lifetime`,
      "pt-BR": `vitalício`
    })(),
    value: Enums.SubscriptionConsumablePeriods.SUBSCRIPTION
  },
]

type FeatureOptionsType = { label: string, value: keyof typeof Enums.SubscriptionConsumableFeatures | string }
export const FeatureOptions: FeatureOptionsType[] = [
  {
    label: i18n_v2.Single({
      "en-US": `Captcha solve`,
      "pt-BR": `Resolução captcha`
    })(),
    value: "CAPTCHA_RESOLVE"
  },
  {
    label: i18n_v2.Single({
      "en-US": `E-mail sending`,
      "pt-BR": `Envio de e-mail`
    })(),
    value: "EMAIL_SENDING"
  },
  {
    label: i18n_v2.Single({
      "en-US": `Devices`,
      "pt-BR": `Dispositivos`
    })(),
    value: "LINKED_DEVICES"
  },
  {
    label: i18n_v2.Single({
      "en-US": `Run seconds`,
      "pt-BR": `Segundos de execução`
    })(),
    value: "RUN_SECONDS"
  },
  {
    label: i18n_v2.Single({
      "en-US": `SMS sending`,
      "pt-BR": `Envio de SMS`
    })(),
    value: "SMS_SENDING"
  },
  {
    label: i18n_v2.Single({
      "en-US": `Advanced OCR`,
      "pt-BR": `OCR avançado`
    })(),
    value: "TEXTRACT_CALL"
  },
]

type CurrencyOptionsType = keyof typeof Enums.CurrenciesIsoCodes
export const CurrencyOptions: CurrencyOptionsType[] = [
  "BRL",
  "USD",
]