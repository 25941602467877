import { useHookstate } from '@hookstate/core'
import { Enums } from '@roberty/models'
import { Col, Empty, Form, Input, Row, Select, Switch } from 'antd'
import { useState } from 'react'

import { i18n_v2 } from '../../../lib/i18n'
import { useControllers } from './controllers'
import { i18nLocal } from './i18nLocal'
import { LocalState } from './state'
import { PropsType } from './types'

const TypeOptions: {
  label: string,
  value: Enums.CustomerType
}[] = [
    {
      label: i18n_v2.Single({
        "en-US": `Business`,
        "pt-BR": `Pessoa jurídica`
      })(),
      value: Enums.CustomerType["BUSINESS"]
    },
    {
      label: i18n_v2.Single({
        "en-US": `Person`,
        "pt-BR": `Pessoa física`
      })(),
      value: Enums.CustomerType["PERSON"]
    }
  ]

export const BasicTab = (props: PropsType) => {
  const state = useHookstate(LocalState)
  const controllers = useControllers()

  return (
    <Form layout={"vertical"}>
      <Row gutter={20}>
        {
          props.editing
            ? <Col flex={"1"}>
              <Form.Item
                label={"Status"}
                required
              >
                <Row gutter={10}>
                  <Col>
                    <label>{i18n_v2.Commons(`inactive`)}</label>
                  </Col>
                  <Col>
                    <Switch
                      checked={state.workspace.active.get()}
                      onChange={state.workspace.active.set}
                    />
                  </Col>
                  <Col>
                    <label>{i18n_v2.Commons(`active`)}</label>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
            : undefined
        }
        <Col flex={"1"}>
          <Form.Item
            label={i18nLocal.basicInfoTab(`form.type.label`)}
            required
            help={state.errors.nested(`type`).get()}
            validateStatus={state.errors.nested(`type`).get() ? `error` : ``}
          >
            <Select
              options={TypeOptions}
              placeholder={i18n_v2.Commons(`required`)}
              value={state.workspace.type.get()}
              onChange={state.workspace.type.set}
            />
          </Form.Item>
        </Col>
        <Col flex={"2"}>
          <Form.Item
            label={i18nLocal.basicInfoTab(`form.name.label`)}
            required
            help={state.errors.nested(`displayName`).get()}
            validateStatus={state.errors.nested(`displayName`).get() ? `error` : ``}
          >
            <Input
              inputMode={"text"}
              placeholder={i18n_v2.Commons(`required`)}
              onChange={({ target: { value } }) => state.workspace.displayName.set(value)}
              value={state.workspace.displayName.get()}
            />
          </Form.Item>
        </Col>
        <Col flex={"2"}>
          <Form.Item
            label={"E-mail"}
            help={state.errors.nested(`email`).get()}
            validateStatus={state.errors.nested(`email`).get() ? `error` : ``}
          >
            <Input
              inputMode={"email"}
              onChange={({ target: { value } }) => state.workspace.email.set(value)}
              value={state.workspace.email.get()}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col flex={"1"}>
          <Form.Item
            label={
              state.workspace.type.get() === `BUSINESS`
                ? i18nLocal.basicInfoTab(`form.legalName.forBusiness.label`)
                : i18nLocal.basicInfoTab(`form.legalName.forPerson.label`)
            }
            required
            help={state.errors.nested(`legalName`).get()}
            validateStatus={state.errors.nested(`legalName`).get() ? `error` : ``}
          >
            <Input
              inputMode={"text"}
              placeholder={i18n_v2.Commons(`required`)}
              onChange={({ target: { value } }) => state.workspace.legalName.set(value)}
              value={state.workspace.legalName.get()}
            />
          </Form.Item>
        </Col>
        <Col flex={"1"}>
          <Form.Item
            label={
              state.workspace.type.get() === `BUSINESS`
                ? i18nLocal.basicInfoTab(`form.federalDocument.forBusiness.label`)
                : i18nLocal.basicInfoTab(`form.federalDocument.forPerson.label`)
            }
            required
            help={state.errors.nested(`federalDocument`).get()}
            validateStatus={state.errors.nested(`federalDocument`).get() ? `error` : ``}
          >
            <Input
              inputMode={"numeric"}
              placeholder={i18n_v2.Commons(`required`)}
              onChange={({ target: { value } }) => state.workspace.federalDocument.set(value)}
              maxLength={18}
              value={controllers.basicInfo.getFormattedFederalDocument()}
            />
          </Form.Item>
        </Col>
        <Col flex={"1"}>
          <Form.Item
            label={i18nLocal.basicInfoTab(`form.responsiblePartner.label`)}
          >
            <Select
              notFoundContent={<Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  state.basicTab.partners.keyword.get().trim().length > 0
                    ? (
                      state.basicTab.partners.loading.get()
                        ? i18nLocal.basicInfoTab(`form.responsiblePartner.loading`)
                        : i18nLocal.basicInfoTab(`form.responsiblePartner.noData`)
                    )
                    : i18nLocal.basicInfoTab(`form.responsiblePartner.startTyping`)
                }
              />}
              showSearch
              allowClear
              loading={state.basicTab.partners.loading.get()}
              onClear={() => state.workspace.nested("partner_").set(undefined)}
              value={state.workspace.nested("partner_")?.get()}
              onSelect={state.workspace.nested("partner_")?.set}
              filterOption={false}
              onSearch={value => state.basicTab.partners.keyword.set(value)}
              searchValue={state.basicTab.partners.keyword.get()}
              options={state.basicTab.partners.found.get().map(w => ({ value: w._id, label: w.displayName }))}
              placeholder={i18nLocal.basicInfoTab(`form.responsiblePartner.placeholder`)}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}