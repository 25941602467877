import React, { useContext, useEffect } from 'react'

import useMobile from '../../hooks/useMobile'
import { LayoutContext } from '../layout/layout.context'
import AccountSwap from './accountSwap'
import DesktopMainMenu from './desktop'
import { i18nLocal } from './i18nLocal'
import billingIcon from './icons/billing.svg'
import customersIcon from './icons/customers.svg'
import dashboardIcon from './icons/dashboard.svg'
import homeIcon from './icons/home.svg'
import settingIcon from './icons/setting.svg'
import styles from './mainMenu.module.scss'
import MenuItems, { MenuItemsProps } from './menuItems'
import MobileMainMenu from './mobile'

const MainMenu: React.FC<MainMenuProps> = (props) => {
  const layoutContext = useContext(LayoutContext)
  const mobile = useMobile()

  useEffect(() => {
    layoutContext.setCurrentMenu(props.currentMenu)
  }, [props.currentMenu])

  if (mobile) return (
    <MobileMainMenu>
      <MenuItems items={topItems} />
      <MenuItems items={bottomItems} />
      <AccountSwap />
    </MobileMainMenu>
  )

  return (
    <DesktopMainMenu>
      <MenuItems items={topItems} />
      <div className={styles.spacer}></div>
      <MenuItems items={bottomItems} />
      <AccountSwap />
    </DesktopMainMenu>
  )
}

type MainMenuProps = {
  currentMenu: string
}

const topItems: MenuItemsProps["items"] = [
  {
    id: "home",
    name: `Dashboard`,
    path: "/",
    icon: homeIcon
  },
  {
    id: "partners",
    name: i18nLocal(`partners.label`),
    path: "/partners",
    icon: customersIcon
  },
  {
    id: "workspaces",
    name: "Workspaces",
    path: "/workspaces",
    icon: customersIcon
  },
  {
    id: "plans",
    name: i18nLocal(`plans.label`),
    path: "/plans",
    icon: dashboardIcon
  },
  {
    id: "users",
    name: i18nLocal(`users.label`),
    path: "/users",
    icon: dashboardIcon
  },
]

const bottomItems: MenuItemsProps["items"] = [
  {
    id: "finances",
    name: i18nLocal(`invoicing.label`),
    path: "/finances",
    icon: billingIcon
  },
  {
    id: "settings",
    name: i18nLocal(`settings.label`),
    path: "/settings",
    icon: settingIcon
  },
]

export default MainMenu