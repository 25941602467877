import { none, State as StateType, useHookstate } from '@hookstate/core'
import { Schemas } from '@roberty/models'
import { message, Modal, notification } from 'antd'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { i18n_v2 } from 'src/lib/i18n'
import RestClient from 'src/services/rest'
import { useDebounce } from 'use-debounce'
import { z } from 'zod'
import { generateErrorMessage } from 'zod-error'

import { i18nLocal } from './i18nLocal'
import { ClearState, LocalState } from './state'
import { ParamsType, PropsType } from './types'
import { FeatureOptions } from './utils'

const SaveValidation = Schemas.SubscriptionPlanSchema
  .pick({
    name: true,
    listed: true,
    order: true,
    planPeriodicity: true,
    planPeriodicityQuantity: true,
    customer: true,
    internalTags: true,
    unavailableActions: true,
    consumableFeatures: true,
    currencyBasedPrices: true
  })
  .extend({
    currencyBasedPrices: z.record(z.union([z.number(), z.string().transform(v => parseFloat(v))])).optional()
  })

export const useControllers = (props?: PropsType) => {
  const state = useHookstate(LocalState)
  const params = useParams<ParamsType>()
  const navigate = useNavigate()
  const [debouncedWorkspaceSearchKeyword] = useDebounce(state.screenFields.workspaceSearchKeyword.get(), 100)

  const handleSearchWorkspaces = async () => {
    if (debouncedWorkspaceSearchKeyword.trim().length === 0) return
    try {
      state.screenFields.loadingWorkspaces.set(true)
      const { workspaces } = await RestClient.Workspaces.byKeyword.post({
        keyword: debouncedWorkspaceSearchKeyword
      })
      state.screenFields.workspacesFound.set(workspaces as any)
    } catch (e) {
      console.error(e)
      notification.error({
        message: i18nLocal.controllers(`workspaces.loading.error`),
        description: i18n_v2.Commons(`tryAgain`)
      })
    } finally {
      state.screenFields.loadingWorkspaces.set(false)
    }
  }
  useEffect(() => {
    handleSearchWorkspaces()
  }, [debouncedWorkspaceSearchKeyword])

  const getMissingConsumableFeatures = () => {
    const addedFeatures = state.plan.consumableFeatures.map(f => f.name.get())
    const unavailableFeatures = state.plan.unavailableActions.get()
    return FeatureOptions.map(f => ({
      ...f,
      disabled: (
        addedFeatures.includes(f.value as any)
        || unavailableFeatures.includes(f.value as any)
      )
    }))
  }

  const canAddMoreConsumableFeatures = () => {
    const addedFeatures = state.plan.consumableFeatures.map(f => f.name.get())
    if (addedFeatures.some(i => i === undefined)) return false
    const unavailableFeatures = state.plan.unavailableActions.get()
    return FeatureOptions.filter(f => (
      !addedFeatures.includes(f.value as any)
      && !unavailableFeatures.includes(f.value as any)
    )).length > 0
  }

  const handleRemoveFeatureByIndex = (index: number, confirmed?: boolean) => () => {
    if (!confirmed) {
      Modal.confirm({
        content: i18nLocal.controllers(`consumableFeatures.removeModal.question`),
        cancelText: i18n_v2.Commons(`no`),
        okText: i18n_v2.Commons(`yes`),
        cancelButtonProps: { type: "dashed" },
        onOk: handleRemoveFeatureByIndex(index, true)
      })
      return
    }
    state.plan.consumableFeatures.merge({ [index]: none })
  }

  const loadPlanToEditOrDuplicate = async () => {
    if (
      !props?.editing
      && !props?.duplicate
    ) return

    if (!params.planId) {
      navigate("..")
      return
    }

    const destroy = message.loading({
      content: i18nLocal.controllers(`planToCopy.loading`),
      duration: 0
    })
    try {
      const { plan } = await RestClient.Plans["-plan-"].get({
        planId: params.planId
      })
      state.plan.set(plan as any)
    } catch (e) {
      console.error(e)
      notification.error({
        message: i18nLocal.controllers(`planToCopy.loading.error`),
        description: i18n_v2.Commons(`tryAgain`)
      })
      navigate("..")
    } finally {
      destroy()
    }
  }
  useEffect(() => { loadPlanToEditOrDuplicate() }, [])

  const handleSave = (confirmed?: boolean) => async () => {
    if (!confirmed) {
      Modal.confirm({
        content: i18nLocal.controllers(`saveModal.question`),
        cancelText: i18n_v2.Commons(`no`),
        cancelButtonProps: { type: "dashed" },
        okText: i18n_v2.Commons(`yes`),
        onOk: handleSave(true)
      })
      return
    }
    const destroy = message.loading({
      content: i18nLocal.controllers(`saveModal.saving`),
      duration: 0
    })
    try {
      const plan = SaveValidation.parse(state.plan.get())
      if (props?.editing) {
        await RestClient.Plans["-plan-"].put({ planId: params.planId, plan: plan as any })
      } else {
        await RestClient.Plans.post({ plan: plan as any })
      }
      notification.success({
        message: i18nLocal.controllers(`saveModal.success`)
      })
      navigate("..")
    } catch (e) {
      console.error(e)
      notification.error({
        message: i18nLocal.controllers(`saveModal.error`),
        description: i18n_v2.Commons(`tryAgain`)
      })
    } finally {
      destroy()
    }
  }

  const handleAddNewFeature = () => state.plan.consumableFeatures.merge([{
    name: undefined,
    amount: undefined,
    periodicity: undefined
  }])

  const canSave = () => {
    const result = SaveValidation.safeParse(state.plan.get())
    state.errors.set({})
    if ((result as any)?.error?.issues) state.errors.set(Object.fromEntries(generateErrorMessage(
      (result as any).error.issues,
      {
        delimiter: { component: ` - ` },
        code: { enabled: false },
        path: { label: ``, enabled: true, type: `objectNotation` },
        message: { label: ``, enabled: true }
      }
    ).split(` | `).map(b => b.split(` - `))))
    console.log(state.errors.get())
    return result.success
  }

  useEffect(() => { return () => { ClearState() } }, [])

  return {
    handleAddNewFeature,
    handleRemoveFeatureByIndex,
    getMissingConsumableFeatures,
    canAddMoreConsumableFeatures,
    canSave,
    handleSave,
  }
}