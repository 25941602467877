import 'moment/locale/pt-br'

import { Button, Col, Row, Tag, Tooltip } from 'antd'
import moment from 'moment'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import WorkspaceAvatar from 'src/components/avatar/workspace'
import trashIcon from 'src/components/icons/trash.svg'
import Layout from 'src/components/layout'
import PaginatedTable from 'src/components/paginatedTable'
import StatisticsCard from 'src/components/statisticsCard'
import { i18n_v2 } from 'src/lib/i18n'
import objectIdToDate from 'src/lib/objectIdToDate'
import LocaleService from 'src/services/locale'
import RestClient from 'src/services/rest'

import { SVGIcon } from '../../components/svgIcon'
import { useControllers } from './controllers'
import { i18nLocal } from './i18nLocal'
import styles from './index.module.scss'

import type { Models } from "@roberty/models"
moment.locale(LocaleService.get())

const WorkspacesPage: React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [total, setTotal] = useState(0)
  const [tableForceReload, setTableForceReload] = useState(Date.now())

  const controllers = useControllers({ setTableForceReload })

  return (
    <>
      <Helmet>
        <title>Workspaces - Roberty Automation</title>
      </Helmet>
      <Layout currentMenu="workspaces">
        <div className={styles.workspaces}>
          <section>
            <Row justify="space-between">
              <Col>
                <h1 className={styles.title}>Workspaces</h1>
              </Col>
              <Col>
                <Link to={"./new"}>
                  <Button
                    type="primary"
                    size="large"
                  >
                    {i18nLocal.index(`headers.newButton.label`)}
                  </Button>
                </Link>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} lg={8}>
                <StatisticsCard
                  title={i18nLocal.index(`headers.count.title`)}
                  data={total}
                  loading={loading}
                />
              </Col>
            </Row>
          </section>
          <section>
            <PaginatedTable
              forceReload={tableForceReload}
              request={RestClient.Workspaces.get}
              locale={{
                noData: i18nLocal.index(`table.noData`),
                searchPlaceholder: i18nLocal.index(`table.search.placeholder`)
              }}
              columns={[
                {
                  title: "Workspace",
                  render: (record: Models.Customer) => (
                    <Row
                      gutter={16}
                      align="middle"
                    >
                      <Col>
                        <WorkspaceAvatar name={record.displayName} />
                      </Col>
                      <Col>
                        <Link to={`./${record._id}`}>
                          <b>{record.displayName}</b>
                        </Link>
                        <br />
                        <small>
                          {record.email}
                        </small>
                      </Col>
                    </Row>
                  )
                },
                {
                  title: "Status",
                  render: (record: Models.Customer) => (
                    <>
                      {
                        record.active
                          ? <Tag color={`green`}>{i18n_v2.Commons(`active`)}</Tag>
                          : <Tag color={`red`}>{i18n_v2.Commons(`inactive`)}</Tag>
                      }
                    </>
                  )
                },
                {
                  title: i18nLocal.index(`table.memberSince.title`),
                  render: (record: Models.Customer) => (
                    <>
                      <span>
                        {moment(objectIdToDate(record._id as string)).format(i18n_v2.Commons(`dateFormat`))}
                      </span>
                      <br />
                      <small>
                        {moment(objectIdToDate(record._id as string)).fromNow()}
                      </small>
                    </>
                  )
                },
                {
                  title: "",
                  render: (record: Models.Customer) => (
                    <Row gutter={20} justify={"space-around"}>
                      <Col>
                        <Tooltip title={i18nLocal.index(`table.actions.removeButton.tip`)}>
                          <Button
                            type={"link"}
                            onClick={controllers.handleRemove(record)}
                          >
                            <SVGIcon
                              src={trashIcon}
                              style={{ fill: "var(--pink)" }}
                            />
                          </Button>
                        </Tooltip>
                      </Col>
                    </Row>
                  )
                },
              ]}
              onLoad={response => {
                setTotal(response.total)
                setLoading(false)
              }}
            />
          </section>
        </div>
      </Layout>
    </>
  )
}

export default WorkspacesPage