import React, { useEffect, useState } from "react"

import compactLogo from "../../assets/roberty-helmet.svg"
import defaultLogo from "../../assets/roberty-logo.svg"
import whiteLogo from "../../assets/roberty-logo-white.svg"
import DarkModeService from "../../services/darkMode"

const Logo: React.FC<LogoProps> = (props) => {
  const { compact, ...rest } = props
  const [theme, setTheme] = useState(DarkModeService.get(true))

  const src = compact ? compactLogo
    : theme === "dark" ? whiteLogo : defaultLogo

  useEffect(() => {
    setTheme(DarkModeService.get(true))
  }, [])

  return (
    <img
      src={src}
      alt={props.alt || "Roberty"}
      {...rest}
    />
  )
}

type LogoProps = React.ImgHTMLAttributes<HTMLImageElement> & {
  compact?: boolean
}

export default Logo