import { CSSProperties } from "react"
import { ReactSVG } from "react-svg"

type Props = {
  style?: CSSProperties
  src: string
}

export const SVGIcon = ({ src, ...props }: Props) => {
  return (
    <ReactSVG
      src={src}
      className={"svgIcon"}
      wrapper="span"
      {...props}
    />)
}