import React from "react"
import PerfectScrollbar, { ScrollBarProps } from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"

import "./scroll.scss"
import getOSName from "../../lib/getOSName"

interface Props extends ScrollBarProps {
  disableOnMacOS?: boolean,
  scrollRecalculationFix?: boolean
}

const isMacOS = getOSName() === "macOS"

export const Scroll: React.FC<Props> = ({
  disableOnMacOS,
  scrollRecalculationFix,
  ...props
}) => {

  /**
   * Fix for the scrollbar keep scrolling down when exceeding
   * contents length
   * @see https://github.com/mdbootstrap/perfect-scrollbar/pull/934/files
   * @see https://github.com/mdbootstrap/perfect-scrollbar/issues/920#issuecomment-720450124
   */
  const scrollRecalculation = (ref: HTMLElement) => {
    if (ref) {
      // @ts-ignore
      ref._getBoundingClientRect = ref.getBoundingClientRect
      ref.getBoundingClientRect = () => {
        // @ts-ignore
        const original = ref._getBoundingClientRect()
        return {
          ...original,
          width: Math.floor(original.width),
          height: Math.floor(original.height),
        }
      }
    }
  }

  if (disableOnMacOS && isMacOS) return (
    <>{props.children}</>
  )

  return (
    <PerfectScrollbar
      {...props}
      containerRef={scrollRecalculationFix
        ? scrollRecalculation
        : props.containerRef
      }
    />
  )
}

Scroll.defaultProps = {
  disableOnMacOS: true
}