import React from "react"
import Loading from "../loading"

const Suspense: React.FC = () => {
  return (
    <div style={{
      width: "100vw",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }}>
      <Loading />
    </div>
  )
}

export default Suspense