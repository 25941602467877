import { Col, List, Row, Select } from 'antd'
import React, { useContext } from 'react'
import Layout from 'src/components/layout'
import { LayoutContext } from 'src/components/layout/layout.context'
import useMobile from 'src/hooks/useMobile'
import DarkModeService from 'src/services/darkMode'
import LocaleService from 'src/services/locale'

import { i18nLocal } from './i18nLocal'
import styles from './index.module.scss'

const SettingsPage: React.FC = () => {
  const layoutContext = useContext(LayoutContext)
  const isMobile = useMobile()
  const [theme, setTheme] = React.useState(DarkModeService.get())
  const [language] = React.useState(LocaleService.get())

  const onThemeChange = (value: "light" | "dark" | "auto") => {
    DarkModeService.set(value)
    setTheme(value)
  }

  const onLanguageChange = (value: "en-US" | "pt-BR") => {
    if (!confirm(i18nLocal(`language.confirmation`))) return
    LocaleService.set(value)
    location.reload()
  }

  return (
    <Layout
      currentMenu="settings"
      title={i18nLocal(`header.title`)}
    >
      <div className={styles.preferences}>
        <h1 className={styles.title}>{i18nLocal(`header.title`)}</h1>
        <section>
          <List
            bordered={true}
            header={(
              <h2 className={styles.title}>
                {i18nLocal(`appearance.title`)}
              </h2>
            )}
          >
            <List.Item>
              <Row
                style={{ width: "100%" }}
                justify="space-between"
                align="middle"
              >
                <Col>{i18nLocal(`appearance.theme`)}</Col>
                <Col>
                  <Select
                    value={theme}
                    onChange={onThemeChange}
                    style={{ width: 120 }}
                  >
                    <Select.Option value="light">
                      {i18nLocal(`appearance.theme.light`)}
                    </Select.Option>
                    <Select.Option value="dark">
                      {i18nLocal(`appearance.theme.dark`)}
                    </Select.Option>
                    <Select.Option value="auto">
                      {i18nLocal(`appearance.theme.auto`)}
                    </Select.Option>
                  </Select>
                </Col>
              </Row>
            </List.Item>
          </List>
        </section>
        <section>
          <List
            bordered={true}
            header={(
              <h2 className={styles.title}>
                {i18nLocal(`language.title`)}
              </h2>
            )}
          >
            <List.Item>
              <Row
                style={{ width: "100%" }}
                justify="space-between"
                align="middle"
              >
                <Col>
                  <span>{i18nLocal(`language.title`)}</span>
                </Col>
                <Col>
                  <Select
                    value={language}
                    onChange={onLanguageChange}
                    style={{ width: 220 }}
                  >
                    <Select.Option value="en-US">
                      English (en-US)
                    </Select.Option>
                    <Select.Option value="pt-BR">
                      Português do Brasil (pt-BR)
                    </Select.Option>
                  </Select>
                </Col>
              </Row>
            </List.Item>
          </List>
        </section>
      </div>
    </Layout>
  )
}

export default SettingsPage