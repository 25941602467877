import { State, useHookstate } from '@hookstate/core'
import { Alert, CheckboxProps, message, Modal, notification } from 'antd'
import moment from 'moment'
import { useEffect } from 'react'
import { i18n_v2 } from 'src/lib/i18n'
import { TableRowType } from 'src/pages/finances/generateInvoicesModal/types'
import RestClient from 'src/services/rest'

import { PropsType } from '../generateInvoicesModal/types'
import { i18nLocal } from './i18nLocal'
import { LocalState } from './state'

export const useControllers = (props: PropsType) => {
  const state = useHookstate(LocalState)

  const loadData = async () => {
    const destroy = message.loading({
      content: i18nLocal.controllers(`finances.loading`),
      duration: 0
    })
    state.table.loading.set(true)
    try {
      const { results } = await RestClient.Finances.partners.get({
        month: state.filters.month.get().toDate(),
      })
      state.table.data.set(results)
    } catch (e) {
      console.error(e)
      notification.error({
        message: i18nLocal.controllers(`finances.loading.error`),
        description: i18n_v2.Commons(`tryAgain`)
      })
    } finally {
      state.table.loading.set(false)
      destroy()
    }
  }
  useEffect(() => {
    if (props.open.get()) {
      loadData()
    } else {
      state.table.data.set(() => [])
      state.table.selectedPartners.set(() => [])
    }
  }, [props.open, state.filters.month])

  const handleDueDatePickerDisable = (date: moment.Moment) => (
    date.isSameOrBefore(state.filters.month.get().clone().startOf("month"))
  )

  const handleGenerateInvoices = (confirmed?: boolean) => async () => {
    if (!confirmed) {
      Modal.confirm({
        icon: null,
        content: <>
          <Alert
            type={"warning"}
            message={i18nLocal.controllers(`generationModal.alert.message`)}
          />
          <br />
          {i18nLocal.controllers(`generationModal.question`)}
        </>,
        cancelText: i18n_v2.Commons(`no`),
        cancelButtonProps: { type: "dashed" },
        okText: i18n_v2.Commons(`yes`),
        onOk: handleGenerateInvoices(true)
      })
      return
    }
    const destroy = message.loading({
      content: i18nLocal.controllers(`generationModal.generating`),
      duration: 0
    })
    try {
      const partnersToCreateInvoice = state.table.data.get({ noproxy: true })
        .filter(({ partner: { _id } }) => state.table.selectedPartners.get().includes(_id))
        .map(({ partner, workspaces }) => ({
          partner: partner._id,
          referralMonth: state.filters.month.get().format(),
          dueDate: partner.dueDate.format(),
          billingType: partner.billingType,
          workspacesCount: workspaces.length,
          amount: parseFloat(partner.amount)
        }))
      await RestClient.Finances.invoices.create.post({
        partners: partnersToCreateInvoice
      })
      props.open.set(false)
      props.loadInvoice()
    } catch (e) {
      console.error(e)
      notification.error({
        message: i18nLocal.controllers(`generationModal.error`),
        description: i18n_v2.Commons(`tryAgain`)
      })
    } finally {
      destroy()
    }
  }

  const getTableRowSelectProps: (record: TableRowType) => CheckboxProps = record => ({
    disabled: (
      parseFloat(record.partner?.amount.get()) < 10
      || record.partner?.hasPendingInvoice.get()
      || !record.partner?.dueDate.get()
    )
  })

  return {
    getTableRowSelectProps,
    handleGenerateInvoices,
    handleDueDatePickerDisable,
  }
}