import { hookstate } from "@hookstate/core"
import moment from "moment"

import { StateType } from "./types"

export const LocalState = hookstate<StateType>({
  generateInvoicesModal: {
    open: false
  },
  filters: {
    month: moment().subtract(1, "month"),
  },
  table: {
    data: [],
    loading: false,
    pagination: {
      total: 0,
      page: 1,
      pageSize: 10
    }
  }
} satisfies StateType)