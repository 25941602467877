import React, { useEffect, useState, useMemo, PropsWithChildren } from "react"
import { ReactSVG } from "react-svg"
import clsx from "clsx"

import styles from "./statisticsCard.module.scss"
import arrowUpIcon from "./icons/arrowup.svg"
import arrowDownIcon from "./icons/arrowdown.svg"
import arrowRightIcon from "./icons/arrowright.svg"
import Loading from "../loading"

const StatisticsCard: React.FC<PropsWithChildren<StatisticsCardProps>> = (props) => {
  const [variationType, setVariationType] = useState(props.variationType || "none")

  const icon = useMemo(() => {
    switch (variationType) {
    case "up":
      return arrowUpIcon
    case "down":
      return arrowDownIcon
    case "stable":
      return arrowRightIcon
    default:
      return undefined
    }
  }, [variationType])

  useEffect(() => {
    if (props.variationType === "auto") {
      if (isNaN(props.variation as number)) {
        setVariationType("none")
      } else if (props.variation as number > 0) {
        setVariationType("up")
      } else if (props.variation as number < 0) {
        setVariationType("down")
      } else {
        setVariationType("stable")
      }
    } else {
      setVariationType(props.variationType || "none")
    }
  }, [props.variationType, props.variation])

  if (props.loading) return (
    <div className={styles.statisticsCard}>
      <Loading size="small" />
    </div>
  )

  return (
    <div className={clsx({
      [styles.statisticsCard]: true,
      [styles.disabled]: props.disabled
    })}>
      <h2 className={styles.title}>
        {props.title}
      </h2>
      <div className={styles.content}>
        <strong className={styles.data}>
          {props.data}
        </strong>
        <div
          className={clsx({
            [styles.variation]: true,
            [styles.up]: variationType === "up",
            [styles.down]: variationType === "down",
            [styles.stable]: variationType === "stable",
            [styles.hide]: !variationType || variationType === "none",
          })}
          style={{
            backgroundColor: props.variationColor
          }}
        >
          {props.showVariationIcon && icon && (
            <ReactSVG
              className={styles.icon}
              src={icon}
              wrapper="span"
            />
          )}
          <span>{props.variation}</span>
        </div>
      </div>
      <div className={styles.children}>
        {props.children}
      </div>
    </div>
  )
}

type StatisticsCardProps = {
  title: React.ReactNode
  data: string | number | React.ReactNode
  variation?: string | number | React.ReactNode
  variationType?: VariationType
  variationColor?: string
  showVariationIcon?: boolean
  disabled?: boolean
  loading?: boolean
}

StatisticsCard.defaultProps = {
  showVariationIcon: true,
  variationType: "auto"
}

export type VariationType = "up" | "down" | "stable" | "none" | "auto"

export default StatisticsCard