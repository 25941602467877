import { useHookstate } from "@hookstate/core"
import { Col, Empty, Row, Select, Typography } from "antd"
import React from "react"
import UserAvatar from "src/components/avatar/user"

import { useControllers } from "./controllers"
import { LocalState } from "./state"
import { SelectUsersProps } from "./types"

export const SelectUsers: React.FC<SelectUsersProps> = props => {

  const state = useHookstate(LocalState)
  const controllers = useControllers(props)

  return (

    <Select
      notFoundContent={<Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={
          state.keyword.get().trim().length > 0
            ? (
              state.loading.get()
                ? "Loading users..."
                : "No users found"
            )
            : "Starts typing to search..."
        }
      />}
      showSearch
      size={"large"}
      mode={props.multiple ? "multiple" : undefined}
      allowClear
      loading={state.loading.get()}
      onClear={() => props.value.set(undefined)}
      value={props.value.get()}
      onChange={value => {
        props.value.set(value)
        state.keyword.set("")
      }}
      filterOption={false}
      onSearch={value => state.keyword.set(value)}
      searchValue={state.keyword.get()}
      placeholder={props.placeholder}
    >
      {
        controllers.getUserToSuggest().map(user => (
          <Select.Option
            key={user.email}
            value={user.email}
          >
            <Row
              gutter={16}
              align={"middle"}
            >
              <Col>
                <UserAvatar
                  name={user.name}
                  size={"small"}
                />
              </Col>
              <Col>
                {user.name}
                <br />
                <Typography.Text
                  style={{ fontWeight: "normal" }}
                  type={"secondary"}
                >
                  {user.email}
                </Typography.Text>
              </Col>
            </Row>
          </Select.Option>
        ))
      }
    </Select>
  )
}

SelectUsers.defaultProps = {
  emailsToHide: []
}
