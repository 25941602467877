import { useHookstate } from '@hookstate/core'
import { Button, Col, Divider, Row, Tabs } from 'antd'
import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from 'src/components/layout'

import { AddressTab } from './address.tab'
import { BasicTab } from './basic.tab'
import { BillingOptionsTab } from './billingOptions.tab'
import { useControllers } from './controllers'
import { i18nLocal } from './i18n'
import styles from './index.module.scss'
import { LocalState } from './state'
import { PropsType } from './types'
import { UsersTab } from './users.tab'

export const PartnerPage: React.FC<PropsType> = props => {

  const state = useHookstate(LocalState)
  const controllers = useControllers(props)

  const pageTitle = (
    props.editing
      ? i18nLocal.indexPage(`page.title.editing`)
      : (
        state.partner.createWorkspaceToo.get()
          ? i18nLocal.indexPage(`page.title.creatingWithWorkspace`)
          : i18nLocal.indexPage(`page.title.creating`)
      )
  )

  return (
    <>
      <Helmet>
        <title>{`${pageTitle} - Roberty Automation`}</title>
      </Helmet>
      <Layout currentMenu="partners">
        <div className={styles.plan}>
          <Row gutter={20}>
            <Col flex={1}>
              <h1 className={styles.title}>
                {pageTitle}
              </h1>
            </Col>
            <Col >
              <Button
                type={"primary"}
                disabled={!controllers.canSave()}
                onClick={controllers.handleSave()}
              >
                {
                  props.editing
                    ? i18nLocal.indexPage(`header.saveButton.label.editing`)
                    : (
                      state.partner.createWorkspaceToo.get()
                        ? i18nLocal.indexPage(`header.saveButton.label.creatingWithWorkspace`)
                        : i18nLocal.indexPage(`header.saveButton.label.creating`)
                    )
                }
              </Button>
            </Col>
          </Row>
          <Divider />
          <Tabs
            defaultActiveKey={"basic"}
            items={[
              {
                key: "basic",
                label: i18nLocal.indexPage(`tabs.basicInfo.title`),
                children: <BasicTab {...props} />
              },
              {
                key: "address",
                label: i18nLocal.indexPage(`tabs.address.title`),
                children: <AddressTab />
              },
              {
                key: "billingOptions",
                label: i18nLocal.indexPage(`tabs.billingOptions.title`),
                children: <BillingOptionsTab {...props} />
              },
              {
                key: "users",
                label: i18nLocal.indexPage(`tabs.users.title`, controllers.getUsersCount()),
                children: <UsersTab {...props} />
              },
            ]}
          />
        </div>
      </Layout>
    </>
  )
}