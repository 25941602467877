import { hookstate } from "@hookstate/core"
import moment from "moment"

import { StateType } from "./types"

const InitialState: StateType = {
  filters: {
    month: moment().subtract(1, "month").startOf("month"),
  },
  table: {
    selectedPartners: [],
    data: [],
    loading: false
  }
}

export const LocalState = hookstate<StateType>(InitialState)