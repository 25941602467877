import { none, useHookstate } from '@hookstate/core'
import { Button, Col, Divider, Row, Tabs } from 'antd'
import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from 'src/components/layout'

import { BasicTab } from './basic.tab'
import { useControllers } from './controllers'
import { i18nLocal } from './i18nLocal'
import styles from './index.module.scss'
import { PartnersTab } from './partners.tab'
import { LocalState } from './state'
import { PropsType } from './types'
import { WorkspacesTab } from './workspaces.tab'

export const UserPage: React.FC<PropsType> = props => {

  const state = useHookstate(LocalState)
  const controllers = useControllers(props)

  const pageTitle = props.editing
    ? i18nLocal.index(`header.title.editing`)
    : i18nLocal.index(`header.title.creating`)

  return (
    <>
      <Helmet>
        <title>{`${pageTitle} - Roberty Automation`}</title>
      </Helmet>
      <Layout currentMenu="users">
        <div className={styles.plan}>
          <Row gutter={20}>
            <Col flex={1}>
              <h1 className={styles.title}>
                {pageTitle}
              </h1>
            </Col>
            <Col >
              <Button
                type={"primary"}
                disabled={!controllers.canSave()}
                onClick={controllers.handleSave()}
              >
                {
                  props.editing
                    ? i18nLocal.index(`header.saveButton.editing.label`)
                    : i18nLocal.index(`header.saveButton.creating.label`)
                }
              </Button>
            </Col>
          </Row>
          <Divider />
          <Tabs
            defaultActiveKey={"basic"}
            items={[
              {
                key: "basic",
                label: i18nLocal.index(`tabs.basic.title`),
                children: <BasicTab />
              },
              {
                key: "workspaces",
                label: `Workspaces (${state.user.workspaces.length})`,
                children: <WorkspacesTab />
              },
              {
                key: "partners",
                label: i18nLocal.index(`tabs.partners.title`, state.user.partners.length),
                children: <PartnersTab />
              }
            ]}
          />
        </div>
      </Layout >
    </>
  )
}