import { i18n_v2 } from 'src/lib/i18n'

export const i18nLocal = {
	controllers: i18n_v2.Collection({
		"partners.loading.error": {
			"en-US": `Error when loading partners...`,
			"pt-BR": `Erro ao carregar parceiros...`
		},
		"workspaces.loading.error": {
			"en-US": `Error when loading workspaces...`,
			"pt-BR": `Erro ao carregar workspaces...`
		},
		"users.loading": {
			"en-US": `Loading users...`,
			"pt-BR": `Carregando usuários...`
		},
		"users.loading.error": {
			"en-US": `Error when loading users...`,
			"pt-BR": `Erro ao carregar usuários...`
		},
		"saveModal.question": {
			"en-US": `Save changes?`,
			"pt-BR": `Salvar alterações?`
		},
		"saveModal.saving": {
			"en-US": `Saving user...`,
			"pt-BR": `Salvando usuários...`
		},
		"saveModal.success": {
			"en-US": `User saved with success!`,
			"pt-BR": `Usuário salvo com sucesso!`
		},
		"saveModal.error": {
			"en-US": `Error during user saving...`,
			"pt-BR": `Erro ao salvar usuário...`
		},
	}),
	index: i18n_v2.Collection({
		"header.title.creating": {
			"en-US": `Creating user`,
			"pt-BR": `Criando usuário`
		},
		"header.title.editing": {
			"en-US": `Editing user`,
			"pt-BR": `Editando usuário`
		},
		"header.saveButton.creating.label": {
			"en-US": `Save new user`,
			"pt-BR": `Salvar novo usuário`
		},
		"header.saveButton.editing.label": {
			"en-US": `Update user`,
			"pt-BR": `Atualizar usuário`
		},
		"tabs.basic.title": {
			"en-US": `Basic info`,
			"pt-BR": `Informações básicas`
		},
		"tabs.partners.title": {
			"en-US": v => `Partners (${v})`,
			"pt-BR": v => `Parceiros (${v})`
		},
	}),
	basicTab: i18n_v2.Collection({
		"form.name.label": {
			"en-US": `Name`,
			"pt-BR": `Nome`
		},
		"form.phone.label": {
			"en-US": `Phone`,
			"pt-BR": `Telefone`
		},
	}),
	workspacesTab: i18n_v2.Collection({
		"addNew.button.label": {
			"en-US": `Add workspace`,
			"pt-BR": `Adicionar workspace`
		},
		"form.workspace.tip": {
			"en-US": `Start typing to search...`,
			"pt-BR": `Comece a digitar para buscar...`
		},
		"form.removeButton.tip": {
			"en-US": `Remove this workspace`,
			"pt-BR": `Remover esta workspace`
		},
		"form.workspace.loading": {
			"en-US": `Loading workspaces...`,
			"pt-BR": `Carregando workspaces...`
		},
		"form.workspace.noData": {
			"en-US": `No workspaces to list`,
			"pt-BR": `Nenhuma workspace para listar`
		},
		"form.role.placeholder": {
			"en-US": `Role`,
			"pt-BR": `Cargo`
		}
	}),
	partnersTab: i18n_v2.Collection({
		"addNew.button.label": {
			"en-US": `Add partner`,
			"pt-BR": `Adicionar parceiro`
		},
		"form.partner.placeholder": {
			"en-US": `Partner`,
			"pt-BR": `Parceiro`
		},
		"form.partner.tip": {
			"en-US": `Start typing to search...`,
			"pt-BR": `Comece a digitar para buscar...`
		},
		"form.removeButton.tip": {
			"en-US": `Remove this partner`,
			"pt-BR": `Remover este parceiro`
		},
		"form.partner.loading": {
			"en-US": `Loading partners...`,
			"pt-BR": `Carregando parceiros...`
		},
		"form.partner.noData": {
			"en-US": `No partners to list`,
			"pt-BR": `Nenhum parceiro para listar`
		},
		"form.role.placeholder": {
			"en-US": `Role`,
			"pt-BR": `Cargo`
		}
	})
}